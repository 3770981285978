import React, { useEffect } from 'react';
import Swipedrawer from '../../Common/Mobiletopslide/Swipedrawer'
import Calendar from './Calendar';
// import ContentCalendar from './ContentCalendar';
import './Mobilecc.scss'

const Mobilecalendar = (props) => {
  const {dealsList,dealsList1,dealsList2,dataList} = props
    useEffect(()=>{
        document.body.classList.add('MobileCC')
    })
    return (
        <div>
            {/* <ContentCalendar/> */}
            <Calendar dataList={dataList}/>
            <Swipedrawer dealsList= {dealsList} dealsList1={dealsList1} dealsList2={dealsList2} />
        </div>
    );
};

export default Mobilecalendar;