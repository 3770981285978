const urlHeader = `https://ic-dev.lightersideofrealestate.com/`
export const helpData = {
  "General FAQ": [
    {
      name: "I just signed up. Now what?",
      content:
        'First, welcome! We’re excited to have you. If you’re just starting out, you may notice there’s a lot going on. Don’t get overwhelmed. We are here to help acquaint you with everything we have to offer! Below are several resources to help you get started. <br/><br/> <strong>Make sure you’ve gone through our Getting Started Guide.</strong> <br/><br/> If you have not yet gone through the getting started guide, you can do so <a href="/getting-started" target="_blank" rel="noopener">with this link</a>. <br/><br/> Once you’ve set up your account, it may be helpful to watch a full walkthrough. That’s why we recorded a screencast of the site (and wrote a guide) to help explain how each area works. <br/><br/> <a href="/support/tour.mp4"> Click here to watch. </a> <br/><br/> <strong>Utilize Inner Circle membership features.</strong><br/> If you haven’t already, please set up your <a href="/manage-profile#personal-branding" target="_blank" rel="noopener">personal branding profile</a>, so that when you share Inner Circle content with your followers, it will display your professional information. <br/><br/> <strong>Please rely on the Inner Circle Help Center.</strong><br/>Obviously you’ve already discovered our help center since you’re reading this. We constantly update our Help Center with new articles, walk-throughs and step by step instructions. And if you’re unable to find the answer for a question you have, you can also submit a ticket and we will help you find a solution ASAP.',
    },
    {
      name: "Why am I not receiving Inner Circle emails?",
      content:
        'Remember that when you sign up for lighter side, the email you supply is the one our database will send Inner Circle specific emails to. Remember to check that specific email address for Inner Circle correspondences. <br/><br/> If you are certain that Inner Circle specific emails are not showing up in the appropriate email inbox, please read our <a href="/support/email-whitelisting" > whitelisting guide</a>  to learn how to prevent Inner Circle emails from getting stopped by your email provider’s spam filter.<br/><br/> If you are still having trouble receiving Inner Circle emails, please reach out to us at helpdesk@lightersideofrealestate.com.',
      buttom: "Read the Guide",
    },
    {
      name: "What is the Inner Circle subscription renewal policy?",
      content:
        "Your Inner Circle Subscription automatically renews each month at the originally stated price you first signed up with, until you notify us that you wish to cancel.",
    },
    {
      name: "What is the Inner Circle Engage group on Facebook?",
      content:
        "Inner Circle Engage is a private online community where you can: <br/><br/> <ul><li>Collaborate with some of the industry’s top agents.<br/><br/></li> <li>Ask questions, seek feedback, and use your peers as a sounding board, literally 24/7.<br/><br/></li> <li>See what other agents are doing on topics like lead gen, Facebook ads, marketing and branding, etc.<br/><br/></li> <li>Get a backstage pass to listen in on how the the most highly engaged real estate brand on Facebook (the Lighter Side!) operates behind the scenes. Ask me anything! <br/><br/></li><ul>",
      buttom: "Go to to the Group",
    },
    {
      name: "Why don’t I have access to the Facebook group (Inner Circle Engage)?",
      content:
        'If you recently joined the Inner Circle and have had trouble with being added to our Facebook group, it’s probably for the following reason: <br/><br/> Your name on Facebook is different than the name in our system. If the name on your Facebook profile is different than the one we have on record for you… <a href="/contact">send us a message</a>  and let us know the name on your Facebook profile. This is the quickest way we can verify you. <br/><br/> **Requests are approved 1-2 times per day. It may take up to 48hrs to be approved',
    },
    {
      name: "How can I review my subscription/membership?",
      content:
        'When you are logged in: <br/><br/> <ul><li>Select the image in the top right of your membership site <br/><br/></li> <li>Select “<a href="/manage-subscription" target="_blank">Manage Subscription</a>“ <br/><br/> </li> <li>Scroll down to the “Subscriptions” section <br/> <ul><li>This is where you can review your payment history, charge dates, view and print invoices, and more.<br/> <br/></li> <li>You can also update your credit card information in this section.<br/><br/> </li></ul> </li></ul>',
    },
    {
      name: "Do you share my email with 3rd parties?",
      content:
        "No, we never share your email with third parties. We do not sell your email or link you to anything you have not opted-in for directly. <br/><br/> We are careful to follow all rules and regulations and allow you to unsubscribe at anytime from our email lists by clicking the “unsubscribe” link at the bottom of all our emails.",
    },
  ],
  "Using Content": [
    {
      name: "Can I repost Inner Circle content on my website or outside of social networks?",
      content:
        'As an Inner Circle member, you are permitted to repost our content on your social media channels, or wherever you can post using the options we provide. <br/><br/> Aside from directly posting to your social media channels, you can also download memes and videos to upload them where you like. <br/><br/> <img srcset="https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/download-video-300x243.png 300w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/download-video-500x406.png 500w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/download-video-768x623.png 768w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/download-video.png 790w"/> <br/><br/> With articles, you can copy the public link and paste it where you like. To copy the public link for an article, go to the article preview and select “Other Options”, and then the green “Copy” button. <br/><br/> <img srcset="https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/shareviaurl-300x174.png 300w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/shareviaurl-500x290.png 500w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/shareviaurl-768x446.png 768w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/shareviaurl-1024x594.png 1024w"/>',
    },
    {
      name: "Scheduling posts to Facebook.",
      content:
        'Our members have the option to schedule their content posts to their social channels, using our built in scheduling tool. To learn how the scheduling tool works, please review the video <a href="https://www.facebook.com/groups/lightersideinnercircle/permalink/2516797471680109/">linked here</a>.',
    },
    {
      name: "Is there a way to search for content by category?",
      content:
        'Absolutely! To view our article or image meme categories: <br/><br/> <ul> <li>Hover over ‘Content’ on the top menu.<br/><br/></li> <li>Click on the type of content you want to explore in the dropdown menu. <br/><br/></li> <li>Once the next page loads, select the desired category at the top of the page. <br/> <img srcset="https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/Article-Categories-300x149.png 300w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/Article-Categories-500x248.png 500w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/Article-Categories-768x381.png 768w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/Article-Categories-1024x508.png 1024w"/> <br/><br/> </li> </ul> <br/><br/> <ul><li>Once the category page loads, feel free to narrow down your search even further by selecting available subcategories. <br/><br/> </li> <li>If you see a red “More” button, select it to activate a dropdown menu with more options.<br/></li> </ul> <strong>NOTE:</strong> Memes can also be searched by tags by selecting the yellow “Search by Tags” button at the top of the “Memes” page. The tags work the same way as the categories',
    },
    {
      name: "How do I share an Memes and Videos to Facebook?",
      content:
        'For the video tutorial, <a href="https://drive.google.com/open?id=1T7AUvJeoZtaaHfs3r8Pghh4xxcyUogLT">click this link</a>. <br/><br/> Instructions: <br/><br/> In order to share a meme or video, go to your <a href="/share-an-image">Memes page</a>  or your <a href="/share-a-video">Videos Page</a>  and select the meme or video you want to share. A popup window will come up giving you a preview of the meme or video. <br/><br/> The controls for watermarking and branding the meme or video, and the controls for posting to social media channels will be on the right.<br/><br/> When you select “Share Now” or “Schedule Later”, you’ll have an option for sharing the meme or video to a Facebook business page under your profile’s administration. Simply follow the prompts and you’ll be able to post to the business page you desire.<br/><br/> For memes, remember to activate your branding bar each time if you want it on the meme. You can do this by toggling the “Meme Branding Bar” switch to the “ON” position. Videos do not have the branding bar option. <br/><br/> NOTE: Due to recent changes by Facebook, we can no longer post memes or videos to personal profile pages (timelines). To post a meme or video to a personal profile’s timeline, you’ll have to use the “Download Meme” or “Download Video” option, and the manually upload the meme or video to a post on your profile’s timeline. Any watermark or active branding will be present on the downloaded meme or video.',
    },
    {
      name: "How do I post a personally branded article to Facebook?",
      content:
        'Check out this video on <a href="https://drive.google.com/open?id=1-Kj1QEjfUTl6C_KouX3ggshmaynRDf1D">posting articles to Facebook</a>  profiles and business pages.',
    },
    {
      name: "How to set up your branding for the articles.",
      content:
        'To set up the branding for your <a href="/share-article">articles</a> , as well as the default branding for your <a href="/branded-booklets">branded booklets</a> , do the following: <br/><br/> <ul> <li>Select the profile picture in the top right of your membership site. <br/><br/> </li> <li> Select “Manage Profile” <br/><br/></li> <li>Select “Branding Profile” <br/><br/> </li> <li>To modify your profile picture, select “ <a href="/manage-profile#headshot">Headshot</a>”</li> <ul><li>The headshot system currently crops square, and should be a minimum of 300 pixels wide by 300 pixels tall. <br/><br/> </li> <li>If you can not get your whole picture into the cropping, you may want to place it over a square background with an image editor. <br/>  <ul> <li>If you need help with this, send the file for your headshot our way with the subject title “Help with profile picture”, and we’ll get it taken care of for you as soon as possible. <br/><br/> </li> </ul></li></ul> <li>To modify your other branding details, select “ <a href="/manage-profile#personal-branding">Personal Branding</a>”<br/> <ul><li> Scroll down and fill out the necessary information within each field</li> <ul> <li>Leaving a field blank will omit it from your branding <br/><br/> </li> <li>The system autosaves each time you fill out a part of the branding</li> </ul> </ul> </li> </ul>',
    },
    {
      name: "How to set up your watermark for memes and videos.",
      content:
        'To protect our content from being stolen by non-paying agents, we now require all members to watermark their memes and their videos before posting. To set up your watermark: <br/><br/> <ul><li>Select the image in the top right of your membership site <br/><br/> </li> <li>Select “Manage Profile” <br/><br/> </li> <li>Select “Meme Settings”<br/><br/> </li> <li>Select “<a href="/manage-profile#watermark">Watermark</a>“ <br/><br/> </li> <li>You can either upload an image for your watermark, or use our watermark creation tool to create a watermark from scratch <br/><br/> <ul> <li>To learn how to create a watermark, select the green “How to watermark a meme” button <br/><br/> </li></ul> </li> <li>This watermark will also apply to all posted videos <br/> <br/> </li>  </ul>',
    },
    {
      name: "How to set up your meme branding bar for image memes.",
      content:
        'To set up the branding bar that goes on the bottom of your image memes (not available for videos), you’ll want to do the following: <br/><br/> <ul><li>Select the image in the top right of your membership site <br/><br/> </li> <li>Select “Manage Profile”<br/><br/> </li> <li> Select “Meme Settings”<br/><br/> </li> <li> Select “<a href="/manage-profile#meme-branding-bar">Meme Branding</a> “</li> <li>Use the meme branding tool to construct your meme branding <br/> <ul><li> To learn how to use the meme branding tool, select the green “How to use the meme branding tool” button <br/><br/> </li> </ul> </li> <li> Be sure to select “Save Branding Bar” after you are finished <br/><br/> </li> </ul> <br/><br/> Once you have set up your branding bar, you can turn on the branding bar in any meme preview by switching “Meme Branding Bar” to the “ON” position. <br/><br/> <img srcset="https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/act-meme-brand-300x277.png 300w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/act-meme-brand-500x462.png 500w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/04/act-meme-brand.png 713w"/>  ',
    },
    {
      name: `Where's the SAVE button for the meme branding? All I see is "Move element into the branding area.`,
      content:
        'The Save Branding Bar button on the meme branding tool disappears when an element (text, image, logo) is outside of the containment area for the meme branding. To fix this, you need to find the element that is outside of the branding area, and either resize/reposition the element accordingly. <br/><br/> For a more thorough explanation, check out this <a href="https://drive.google.com/open?id=1CkpxYpw01qO0fXlbG97lZgrokH46FwJd">quick video tutorial</a>.',
    },
    {
      name: "How does the Watermark Tool for memes work?",
      content:
        "To learn how to set up your watermark and use it to brand your memes and videos, please watch the following video tutorial. <br/> <br/> ",
      video:
        '<iframe src="https://www.youtube.com/embed/IbdrR4bhZho" frameborder="0" allowfullscreen="allowfullscreen"></iframe>',
    },
    {
      name: "How do I make a transparent watermark (PNG) image?",
      content:
        "Please watch this 6 minute overview of how to create PNGs that you can use with our new watermark tool. Also, below the video are 3 free mobile apps for creating pngs (apple and android links provided).<br/><br/> ",
      videoPlayer: "https://player.vimeo.com/video/225178902",
      links:
        '<br/> Background Eraser: <a href="https://play.google.com/store/apps/details?id=com.handycloset.android.eraser&hl=en">Android</a> | <a href="https://itunes.apple.com/us/app/magic-eraser-remove-photo-background-create-transparent/id989920057?mt=8">Itunes</a> <br/><br/> Photolayers: <a href="https://play.google.com/store/apps/details?id=com.handycloset.android.photolayers&hl=en">Android</a> | <a href="https://itunes.apple.com/us/app/photolayers-free-superimpose-photomontage-background/id501882698?mt=8">Itunes</a> <br/><br/> Adobe Photoshop Express: <a href="https://play.google.com/store/apps/details?id=com.adobe.psmobile&hl=en">Android</a>| <a href="https://itunes.apple.com/us/app/adobe-photoshop-express-photo-editor-collage-maker/id331975235?mt=8">Itunes</a>',
    },
    {
      name: "How do I connect my account to my Business Page on Facebook?",
      content:
        "Some are confused because their Social Account page will only allow them to connect to their personal Facebook profile. However, as long as you connect to the personal profile that has control over your Business Pages, you will be able to post to those pages using our system.",
    },
  ],
  "Account / Billing": [
    {
      name: "How do I update my credit card details?",
      content:
        'Once you log in, you can update your credit card information by doing the following: <br/><br/> <ul> <li>Select the picture in the top right of your membership site <br/><br/></li> <li>Select “ <a href="/manage-subscription">Manage Subscription</a>“ <br/><br/> </li> <li>Scroll down to the “Subscriptions” section, and select “Update Billing Details”</li> </ul>',
    },
    {
      name: "Can I switch to PayPal for payments?",
      content:
        "At this time we do not choose to take Paypal payments. Though we do see the advantages from a buyer’s perspective when it comes to paying through Paypal, the disadvantages we face when trying to refund money via Paypal is the biggest reason we decline to use this as a current way of receiving payments at this time. <br/><br/> Though we know this can be frustrating, we do hope you are able to purchase our items using our secure order forms that require a credit card for payment.",
    },
    {
      name: "Are my payments secure?",
      content:
        "Yes, we route all payments through Stripe. Stripe has been audited by a PCI-certified auditor and is certified to <a href='http://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe'>PCI Service Provider Level 1</a>. This is the most stringent level of certification available in the payments industry. <br/><br/> None of your payment details are shared with other parties and other than your monthly membership account, we do not charge for anything else that you do not click to purchase directly.",
    },
    {
      name: "How do I reset my password?",
      content:
        "If you are logged in, select the image in the top right of your membership site, then select “ <a href='/manage-subscription'>Manage Subscription</a>.” You will see an area for changing your password. <br/><br/> If you are logged out and forgot your password, go to the <a href='/member-login'>login page</a>  and click the link that has “Forgot your password?” under the login area. This will take you to a page where you will enter your account email address in order for us to verify your membership and send you a reset password link to your email address. <br/><br/> You can also request your password be reset by contacting our support team <a href='/contact'>here</a>.",
    },
    {
      name: "How do I cancel my Inner Circle membership subscription?",
      content:
        "If you are logged in, select the picture in the top right of your membership site, then select “ <a href='/manage-subscription'>Manage Subscription</a>.” Scroll down to the “Subscriptions” section and you will see the option for cancelling your membership there. Be sure to follow the process all of the way through. <br/><br/> We also ask you to please fill out the optional form about your reasons why. We are striving to make our service the best it can be and would highly benefit from your input. <br/><br/> <strong>Note:</strong>  You will still have access to your account for the remainder of your current billing period. <br/><br/> Alternatively if there is anything we can do to make your experience better, <a href='/contact'>please let us know</a>.",
    },
    {
      name: "Where can I get a receipt for my monthly subscription payments?",
      content:
        "To find your payment receipts, select the picture in the top right of your membership site, then select “<a href='/manage-subscription'>Manage Subscription</a>.” Scroll down to the “Order History” section of the page. <br/><br/> The last column in that section let’s you view (and print) receipts for each month you have been a member. Just look for the “eyeball” icons. <br/><br/> <img src='https://icdevcdn.lightersideofrealestate.com/uploads/2016/11/receipts.png' alt loading='lazy'/>",
    },
  ],
};

export const report = {
  name: "Ack! Did you find a bug?",
  content:
    "If so, we can’t apologize enough. Please tell us about it by clicking the button below and we will get it fixed ASAP. We are continually improving the Inner Circle. Our highest aim is to provide the best real estate content and overall membership experience for you. <br/> <br/> We appreciate all your feedback.",
};

export const usingContent = {
  "Editing Branded Booklets": [
    {
      name: "How do I edit section titles?",
      title:'Editing Branded Booklets',
      content:
        'Double click the title. Doing this will open the title as an editable text box.<br/><br/> <img src="/wp-content/uploads/2018/04/BookletSectionTitle.png"/>',
    },
    {
      name: "How do I delete a section?",
      content:
        'Hover over the title and click the (x) that appears to the left. This will delete the section, and the other sections will re-number accordingly. <br/><br/> <img src="/wp-content/uploads/2018/04/BookletDeleteSection.png"/>',
    },
    {
      name: "Can I add a section to a booklet?",
      content:
        "Not currently, but we are considering this for a future upgrade.",
    },
    {
      name: "Can I re-order the sections?",
      content:
        "Not currently, but we are considering this for a future upgrade. In the meantime, there’s a workaround. Simply do the following: <br/><br/> <ol> <li>Copy and paste the entire contents of the booklet into a Word document or similar text editor. Be sure to make a note in the doc of which text goes to which sections. <br/><br/> </li> <li>Within the booklet editor, edit the section titles so they appear in the order of your choosing. <br/><br/> </li> <li>Re-paste the text portions from your document to the appropriate sections in the booklet editor. <br/><br/> </li> </ol>",
    },
    {
      name: "What are those blue icons next to the section titles?",
      content:
        'Blue icons indicate that you have made edits to that section. <br/><br/> <img src="https://ic-dev.lightersideofrealestate.com/wp-content/uploads/profile_pic_temp_path/re_sized/7983_photo_704386.jpg"/> ',
    },
    {
      name: "What are those red icons next to the section titles?",
      content:
        'Red icons indicate that an edit is required by you within that section. Hover over the icon to read the instructions. When you scroll down to the section, the place(s) where you need to make an edit will be in red text. <br/><br/> <img src="https://ic-dev.lightersideofrealestate.com/wp-content/uploads/profile_pic_temp_path/re_sized/7951_photo_715455.jpg"/>',
    },
    {
      name: "Can I change the line or paragraph spacing?",
      content:
        "You cannot change the line or paragraph spacing in the booklet editor at this time, but we are considering this for a future upgrade.",
    },
    {
      name: "Can I change the font size or type?",
      content:
        "You cannot change the font size or type in the booklet editor at this time, but we are considering this for a future upgrade.",
    },
    {
      name: "Can I add images?",
      content:
        "We are working on an upgrade that will allow you to upload images to the booklet text body. We do not yet have a release date for this upgrade.",
    },
    {
      name: "How do I make it so that sections don't take up more than one page on the PDF?",
      content:
        "Since the PDFs are formatted for 8.5″ x 11″ paper, it’s inevitable that longer sections will take up more than one page. Unfortunately, the PDF preview doesn’t reflect this, and we are working to change that. <br/><br/> In many cases, only one or two lines spill over onto the next page. Watch the tutorial below to learn how to prevent this from happening.",
    },
    {
      name: "Why is some of the text within the booklet red?",
      content:
        "Red text indicates that you need to edit that text. When you download your PDF, the text will convert to black. <br/><br/> Red icons in the Table of Contents indicate which sections require edits.",
    },
    {
      name: "Is there a spellchecker or a find and replace function?",
      content:
        "We do not currently have a spellchecker or a find and replace function, but we are looking into implementing those in the near future. For now you can copy and paste the text into a Word doc or something similar and run the spellchecking and replacement tasks there. <br/><br/> If there are a lot of changes, you can copy the text from the document and paste it back into the booklet editor when you are finished. ",
    },
    {
      name: "Where does the author page pull its information from?",
      content:
        'The information on your booklets’ author page is pulled from your branding profile’s <a href="/manage-profile#headshot">Headshot</a> and <a href="/manage-profile#personal-branding">Personal Branding</a>  pages.',
    },
    {
      name: "Where can I view and edit my author page directly?",
      content:
        'Click on the “Edit Author Page” button on your booklet editor. Then select the text you wish to change and type away. Afterwards, make sure to click the blue “Save Changes” button at the bottom left of the Author Page editor. <br/><br/> <img src="/wp-content/uploads/2018/04/BookletsEditAuthorPage.png"/>',
    },
    {
      name: "Why is my author page bio taking up more than one page on the PDF?",
      content:
        "Our PDFs are formatted for for letter sized (8.5″ x 11″) paper. If your author page bio is taking up more than one page, then it will need to be shortened to fit on one page.",
    },
    {
      name: "How do I add a logo to my author page?",
      content:
        'We’re working on an upgrade to allow you to upload a logo to the author page. For now, you can combine your personal branding’s headshot and your logo into one image and upload it to your personal branding’s <a href="/manage-profile#headshot">Headshot</a>  page. This will sync over to your author page as your new photo. <br/><br/> If you need help with combining your images, send the largest versions of your headshot and logo to <strong>helpdesk@lightersideofrealestate.com</strong>  and do this for you as soon as we can. ',
    },
    {
      name: "I've updated my personal branding, but my author page is not showing the changes.",
      content:
        'You may need to refresh your author page. You can do this by selecting the “Edit Author Page” button, and then selecting the button on the right that looks like two circling arrows. This should update your author page with your current personal branding info. <br/><br/> <img src="/wp-content/uploads/2018/04/BookRefreshAuthorPg.png"/>',
    },
    {
      name: "Where does the page footer pull its information from, and how do I edit it?",
      content:
        'The footer pulls its information from you <a href="/manage-profile#personal-branding">Personal Branding</a>  page. Making changes to the relevant fields on your personal branding will change the information on your booklet footer.',
    },
    {
      name: "Can I add additional fields to the page footer?",
      content:
        "We do not have a way to add additional fields to the page footer at this time.",
    },
    {
      name: "Where do I edit my cover page?",
      content:
        'First, click the “Advanced Settings” button on your booklet editing page. <br/><br/> <img src="/wp-content/uploads/2018/04/BookletAdvancedSettings.png"/> <br/><br/> Then click the “Edit Cover” tab at the top of the popup. <br/><br/> <img src="/wp-content/uploads/2018/04/BookletEditCover.png"/>',
    },
    {
      name: "How do I change the cover image, and what size should the replacement image be?",
      content:
        'Click “Advanced Settings,” select the “Edit Cover Page” tab, and then click the “Upload New Cover Image” button. <br/><br/> <img src="https://ic-dev.lightersideofrealestate.com/wp-content/uploads/profile_pic_temp_path/re_sized/7962_photo_305864.jpg"/> <br/><br/> The space for the cover image is 935 pixels wide by 875 pixels tall. You will need an image at least that large, and anything larger will be cropped to fit. Once you have uploaded your image, make sure to click the “Save All Settings” button at the bottom.',
    },
    {
      name: "How do I edit the text for the titles and headings on the cover page?",
      content:
        'Click “Advanced Settings,” select the “Edit Cover Page” tab, and you’ll see editable fields for any items you might want to change. Be sure to save when you are finished. <br/><br/> <img src="/wp-content/uploads/2018/04/BookHeadingTitleText.png"/>  ',
    },
    {
      name: "How do I add a logo to the cover page?",
      content:
        "We are currently working on a way to directly upload a logo for the cover page. Until then, you can send us your logo to <strong>helpdesk@lightersideofrealestate.com</strong>  and let us know which booklet you want it on. We’ll be happy to place it on the cover image for you.",
    },
    {
      name: "What if I want to use a completely different cover page?",
      content:
        "You’ll have to print it out separately and use it in place of ours when you’re binding the booklets together.",
    },
    {
      name: "How do I edit the colors on a booklet?",
      content:
        'To edit a booklet’s primary and secondary colors, click “Advanced Settings,” select the “Edit Colors” tab, and then use the tools to the right to edit your color scheme. You can use the “Preview colors” option to see how it will look before saving your progress. <br/><br/> <img src="/wp-content/uploads/2018/04/BookEditColors.png"/>',
    },
    {
      name: "How do I find out what the exact colors are for my company?",
      content:
        'If you Google “hex codes for [company] colors,” you’ll most likely find the codes which represent the exact colors you’re looking for. Copy the code and paste it into the editor as shown below. <br/><br/> <img src="/wp-content/uploads/2018/04/BookHexCodes.png"/>',
    },
    {
      name: "Do you guys print and distribute these for me?",
      content:
        "We do not print or distribute the booklets. It’s up to you to print them out or hire a printing company to do it for you.",
    },
    {
      name: "What is the best company to have my books printed with?",
      content:
        'We can’t vouch for any specific companies, as that will depend upon your budget and specific needs. However, you can always hop into our <a href="https://www.facebook.com/groups/lightersideinnercircle/">private Facebook group</a>  and ask your fellow members who they use. Alternately, you can use the group’s “search” function and find numerous past discussions on this topic. Using the search term “print booklets” will yield several discussion threads.',
    },
    {
      name: "How can I distribute these booklets digitally?",
      content:
        "There are multiple ways you can distribute the booklets digitally. <br/><br/> <ul> <li>You can use the “lead gen” function to offer your booklets on the articles you post. (See the “Lead Gen Options” part of this FAQ for more details.) <br/><br/></li> <li>You can offer your booklets from our Landing Pages feature. (See the “Lead Gen Options” part of this FAQ for more details.)<br/><br/> </li> <li>You can download the PDF and attach it to any email you send out. <br/><br/></li> </ul>",
    },
  ],
  "Using the Booklet Lead Gen": [
    {
      name: "How do I share the Booklets through my posted Articles?",
      title:'Using the Booklet Lead Gen',
      content:
        "First, you need choose Lead Gen on your selected booklet’s menu. When that page loads, you will see a on/off toggle to turn on the lead gen function for articles you share. This must be done for any booklet you want to offer on your articles. <br/><br/> Turn the lead gen function on. This will allow you to present your booklet at the bottom of the article via an opt-in form. For a demonstration, check out <a href='https://drive.google.com/open?id=1Itp7uVgCriRDFAyh7sQbhGZFLmzZKhJD'>this video tutorial</a>.",
    },
    {
      name: "Can I select more than one booklet to be offered on an article I post?",
      content:
        "You can only select one Booklet for a specific Article at a time. <br/><br/>",
    },
    {
      name: "What do I receive when someone requests a booklet through the lead gen options?",
      content:
        "When someone fills out the form to request a booklet on your landing page, or one of your booklet opt-in ads on an article, you will receive an email with the lead’s name and email address. <br/><br/> This email will be sent to the address that you have as your contact email, in the “Update your contact information” section of your article branding page. <br/><br/> If you are not seeing any emails after while, be sure to check your spam/junk folder to make sure the lead emails are not accidentally being sent there. <br/><br/> If they are you might need to “whitelist” our emails on your email service. <a href='https://ic-dev.lightersideofrealestate.com/support/email-whitelisting'>Here’s a link</a>  to a page discussing how to do this on the most common email services.",
    },
    {
      name: "What does the person requesting the booklet receive?",
      content:
        "When someone requests a booklet from a landing page, or one of your booklet opt-in ads on an article, they will automatically be sent an email to the address they provided with the booklet’s PDF. <br/><br/> If you would like to edit this email, you can do so by doing the following. <br/><br/> <ul> <li>Go to a booklet’s editor <br/><br/></li> <li>Select the tab for Lead Gen, and then select For Articles from the drop down menu <br/><br/></li> <ul> <li>This controls the emails for both the article opt-in function, and the landing pages <br/><br/> </li> </ul> <li>Turn the switch for “Offer This Booklet On Articles You Share” to the ON position <br/><br/> </li> <li>Scroll down and edit the email template as needed and then save your changes <br/><br/></li></ul> <br/><br/> <img srcset='https://icdevcdn.lightersideofrealestate.com/uploads/2019/02/bookEditArticleEmails-300x148.png 300w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/02/bookEditArticleEmails-500x247.png 500w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/02/bookEditArticleEmails-768x380.png 768w, https://icdevcdn.lightersideofrealestate.com/uploads/2019/02/bookEditArticleEmails-1024x506.png 1024w'/> ",
    },
    {
      name: "Our recommendations for editing your landing pages.",
      content: "Coming Soon",
    },
  ],
  "Booklet Troubleshooting": [
    {
      name: "Why are some sections taking up more than one page on the PDF but not on the preview?",
      content:
        "Our booklets are formatted for letter size (8.5″ x 11″) paper. This causes the longer sections to take up more than one page on the PDF you download. We’re currently working on making the previews reflect this. <br/><br/> If you’re wondering how you can cut back on the instances where one or two lines show up on a second page, check out the video tutorial below for a workaround.",
      video: "https://player.vimeo.com/video/265687298?dnt=1&app_id=122963",
      title:'Booklet Troubleshooting',
    },
    {
      name: "I've updated my personal branding, but my author page is not showing the changes.",
      content:
        "You may need to refresh your author page. You can do this by selecting the “Edit Author Page” button, and then selecting the button on the right that looks like two circling arrows. This should update your author page with your current personal branding info. <br/><br/> <img src='/wp-content/uploads/2018/04/BookRefreshAuthorPg.png'/>",
    },
    {
      name: "How do I get my cover image to fit the cover page?",
      content:
        "The cover image needs to be 935 pixels wide by 875 pixels tall to perfectly fit the image space on the cover page. Anything larger will be cropped to that size.",
      video: "",
    },
    {
      name: "If I delete a section, will the other sections be numbered in the correct sequence?",
      content:
        "Yes. To recap, deleting a section is accomplished by clicking the (x) which appears to the left of the title when hovering over it. Clicking the (x) will delete the section, and all other sections will re-number accordingly. <br/><br/> <img src='/wp-content/uploads/2018/04/BookletDeleteSection.png'/> ",
    },
    {
      name: "I want to share a booklet on a posted article, but can't find it in the booklet list when I activate the lead gen. Where is it?",
      content:
        "Make sure to switch the lead gen function in the “LEAD GEN” area of the booklet you’d like to offer your readers. After doing this, the booklet will then show up in your booklet list when you go to post the article.",
    },
    {
      name: "How do I put my logo on a booklet?",
      content:
        'We’re currently working on an upgrade for placing a logo directly on the cover of the booklet. In the meantime, you can send the largest version of your logo to <strong>helpdesk@lightersideofrealestate.com</strong> and we’ll combine it with the cover image for you. <br/><br/> If you need your logo on your author page, send us the largest version of your <a href="/manage-profile#personal-branding">personal branding’s</a>  headshot as well, and we’ll see if we can combine them for you.',
    },
    {
      name: "Can I get a Word document of the booklet so I can edit further?",
      content:
        "At this time we don’t have a Word document format of the booklets. However, you can download the PDF and use a file converter to change the booklet to a document and edit from there. You can try Googling “free file converter” to see if you can find something that works for you.",
    },
    {
      name: "The red text isn't converting to black in the PDF.",
      content:
        'There are rare occasions where this may happen. We are currently working on correcting this issue. If you are experiencing this, you can follow the instructions in <a href="https://drive.google.com/file/d/1al-mZTPe6NApSmuYgHLHRZNJcgAiveRh/view">this video</a> to correct the text color.',
    },
    {
      name: "How do I email a booklet?",
      content:
        'To email a booklet, you’ll first want to download the PDF. You can do this by scrolling to the bottom of the booklet editor and selecting the blue, “Download PDF” button. <br/><br/> <img src="https://ic-dev.lightersideofrealestate.com/wp-content/uploads/profile_pic_temp_path/re_sized/7997_photo_677396.jpg"/> <br/><br/> Once you’ve downloaded the PDF, you can attach the file to any email you want to send out. To learn how to do this, google “How to attach a file to my email using [email service].” ',
    },
  ],
  "Witty Postcards": [
    {
      name: "Where can I download the postcard templates?",
      title:'Witty Postcards',
      content:
        "To download a Witty Postcard template: <br/><br/> <ul><li>Select “Content” at the top of your membership site <br/><br/> </li> <li>Select “Witty Postcards” <br/><br/> </li> <li>Select the postcard you want to download <br/> <ul> <li>If necessary, select one of the categories that the top of the postcard page to narrow down your search <br/><br/> </li> </ul> </li> <li>Scroll down on the postcard’s preview page, and select “Standard Sizes” or “Every Door Direct Mail Sizes (EDDM)” <br/><br/> </li> <li>Select the size you want to preview <br/><br/> </li> <li>Download the template</li> </ul>",
    },
    {
      name: "Can you print and/or ship these for me?",
      content:
        'No, we’re just here to provide you with the creative content. Simply download these and re-upload them into your online printer of choice. <br/><br/> However, we do have a relationship with ProspectsPlus, a direct response marketing company specializing in design, print, and publishing for Real Estate Agents nationwide. They have given our Inner Circle Members a flat 17% off any order. <a href="http://www.prospectsplus.com/ProductCats.asp?cid=388&utm_source=LSRE&utm_campaign=Promo&utm_medium=Exclusive">Take a look here</a>.',
    },
    {
      name: "You don't have the size I need. How can I get a custom size?",
      content:
        'No worries, just <a href="https://ic-dev.lightersideofrealestate.com/contact">contact us here</a>  and tell us the postcard you need and its size. We’ve included all the standard sizes used by most printers, but if we overlooked the size you need we’re happy to create one for your particular needs.',
    },
    {
      name: "Do you take requests?",
      content:
        'We sure do, and we’d love to hear from you! Just  <a href="https://ic-dev.lightersideofrealestate.com/contact">contact us here</a>  and tell us your idea. All we ask is that it’s not so highly customized to you or your market that it can’t be used by others.',
    },
    {
      name: "Why are there no templates for the back side of the postcards?",
      content:
        "Any online printer you choose to use will have back side templates for you to use. Using theirs makes the process simpler. In most cases they’ll let you upload your headshot and/or logo, as well as a block of text. We don’t give you a back side template because we’re just here to bring you “the good stuff” (i.e. the creative content on the front side).",
    },
    {
      name: "Can I insert an image on the front side (of me, my listing, etc.)?",
      content:
        "Unfortunately no. This is something we’re planning to add in the near future, though.",
    },
  ],
  "Facebook Covers": [
    {
      name: "How do I download and apply these covers to my Facebook profile or page?",
      title:'Facebook Covers',
      content:
        "Please watch this brief tutorial to learn how to download these images and upload them to your Facebook profile or page. <br/><br/>",
      iframe_video:
        '<iframe src="https://player.vimeo.com/video/207045930" allowfullscreen="allowfullscreen" frameborder="0"></iframe>',
    },
    {
      name: "The cover looks good on my computer, but is cut off on my phone or tablet.",
      content:
        'First, our apologies. Some of our Facebook covers were designed before Facebook started cropping them smaller for mobile devices. If you see a cover that doesn’t look right on your mobile device, <a href="https://ic-dev.lightersideofrealestate.com/contact">please let us know</a>. <br/><br/> We’ll be happy to adjust it for you.',
    },
  ],
  "Email & Letter Templates": [
    {
      name: "How do I use the Email & Letter Templates?",
      title:'Email & Letter Templates',
      content:
        'To use the <a href="/emails-letters">Email & Letter Templates</a>: <br/><br/> <ul> <li>Select the template you want to open it <br/><br/> </li> <li>Highlight the text for the template <br/><br/> </li> <li>Copy the highlighted text <br/><br/> </li> <li>Paste the copied text into your email program/service <br/><br/> </li> <li>Change any necessary text to fit your personal situation <br/><br/> </li> </ul>',
    },
  ],
};

export const contentTitle = [
  "Editing Branded Booklets",
  "Using the Booklet Lead Gen",
  "Booklet Troubleshooting",
  "Witty Postcards",
  "Facebook Covers",
  "Email & Letter Templates",
];
