import React, { useState } from 'react';
import { Button, Modal, CardBody } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';

const PublishnowModal = (props) => {

    const { isOpen, handleClose ,handlePublishNow} = props


    return (
        <div className='contactForm1'>
            <Modal className="modal-dialog-centered ccpublish Deals" isOpen={isOpen} size="md">
                <div className="modal-body  d-flex justify-content-center p-0">
                    <CardBody className='p-0'>
                        <div>
                            <h4 className='m-0 align-self-center d-mp-title text-center mb-4'>Are you sure?</h4>
                            <p>This action will publish the post immediately, are you sure?</p>
                            <div className='cpublishbtn text-center'>
                                <Button color="secondary mr-2" onClick={handleClose}>Cancel</Button>
                                <Button color="primary" onClick={handlePublishNow}>Yes</Button>
                            </div>
                        </div>
                    </CardBody>
                </div>
            </Modal>
        </div>
    )
}


export default PublishnowModal;

