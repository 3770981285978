import React, { useState } from "react";
import { Markup } from "interweave";
import { AgentData, agentKey} from "../../../_LocalData/AgentScriptConstantValues";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function AccodianWrapper(props) {
  const [expended, setExpended] = useState(false);

  const handleChange = (isExpanded, panel, index1) => {
    setExpended(isExpanded ? panel : false);
  };
  return (
    <div className="Agent AccodianWrapperDiv">
      {agentKey.map((items, index1) => (
        <>
          <div className="Accodian-box" id={index1}>
            {AgentData[items].map((item, index) => (
              <div className="">
                {item?.name && (
                  <h3 className="accodian-h3">
                    {index1 + 1 === agentKey.length ? (
                      <Markup content={item?.name} />
                    ) : (
                      item?.name
                    )} &nbsp;
                    <span className="AccodianSpan">
                      (
                      <a className="AccodianSpan"
                        href="#return_to_top"
                        onClick={() =>
                          window.scrollTo({ top: 100, behavior: "smooth" })
                        }
                      >
                        return to top
                      </a>
                      )
                    </span>
                  </h3>
                )}
                <Accordion
                  elevation={0}
                  expanded={expended === item?.title}
                  onChange={(event, isExpanded) =>
                    handleChange(isExpanded, item?.title)
                  }
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={
                      <ArrowDropDownIcon style={{ color: "#2d4250" }} />
                    }
                  >
                    <Typography>{item?.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <div className="innerAccodianDiv">
                        <div className="accodian_redDiv">
                          <span>
                            <Markup content={item.useThis} />
                          </span>
                        </div>
                        <br />
                        <div className="accodian_OrangeDiv">
                          <Markup content={item.notes} />
                        </div>
                        <br />
                        {item?.responseText?.map((items, index) => (
                          <>
                            <div className="responseType_1">
                              <div className="type_1_header">
                                <div className="type_1_">
                                  <h5>
                                    <b>
                                      <Markup content={items.name} />
                                    </b>
                                  </h5>
                                </div>
                                <div className="type_1_1">
                                  <h4>
                                    <b>
                                      <Markup content={items.header} />
                                    </b>
                                  </h4>
                                </div>
                              </div>
                              <br />
                              {/*  */}
                              <div className="type_contents">
                                <Markup content={items.content} />
                              </div>
                            </div>
                            {item.responseText.length === index + 1 ? null : (
                              <hr />
                            )}
                          </>
                        ))}
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </>
      ))}
    </div>
  );
}
