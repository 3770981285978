import React, { useState, useEffect } from "react";
import { Collapse, Col, Row } from "reactstrap";
import Favorite from "../../../Assets/Images/Icons/favorites_icon.svg";
import Envelope from "../../../Assets/Images/Icons/Envelope_icon.svg";
import SunLogo from "../../../Assets/Images/sunLogo.svg";
import User from "../../../Assets/Images/Icons/User_icon.svg";
import Logout from "../../../Assets/Images/Icons/off_icon.svg";
import ArticleIcon from "../../../Assets/Images/Icons/Article.svg";
import Booklet from "../../../Assets/Images/Icons/booklet.svg";
import ContentIcon from "../../../Assets/Images/Icons/content.svg";
import DashboardIcon from "../../../Assets/Images/Icons/DashboardIcon.svg";
import PostCard from "../../../Assets/Images/Icons/Postcard.svg";
import FbIcon from "../../../Assets/Images/Icons/FbCoverIcon.svg";
import MemeIcon from "../../../Assets/Images/Icons/MemeIcon.svg";
import CloseIcon from "../../../Assets/Images/Icons/CloseIcon.svg";
import IcIcon from "../../../Assets/Images/Icons/IcIcon.svg";
import Calendar from "../../../Assets/Images/Icons/Calendar_icon.svg";
import PlusIcon from "../../../Assets/Images/calendar/plusIcon.svg";
import DropArrow from "../../../Assets/Images/Icons/menuDrop.svg";
import MinusIcon from "../../../Assets/Images/calendar/minusIcon.svg";
import ResourcesIcon from "../../../Assets/Images/Icons/ResourcesIcon.svg";
import Folder from "../../../Assets/Images/Icons/Folder.svg";
import Scripts from "../../../Assets/Images/Icons/Vector.svg";
import Videos from "../../../Assets/Images/Icons/Video.svg";
import Discount from "../../../Assets/Images/Icons/Discount.svg";

const SideMenus = (props) => {
  const { items, level, collapse, closeDrawer } = props;
  const [state, setState] = useState({
    collapse: {},
    meme: false,
  });
  const toggleOpen = (id) => {
    setState({
      ...state,
      collapse: { ["open" + id]: !state.collapse["open" + id] },
    });
  };

  const togglechildOpen = (pid, cid) => {
    setState({
      ...state,
      collapse: {
        ["open" + pid]: true,
        ["open" + cid]: !state.collapse["open" + cid],
      },
    });
  };
  const togglesubchildOpen = (pid, cid, sid) => {
    setState({
      ...state,
      collapse: {
        ["open" + pid]: true,
        ["open" + cid]: true,
        ["open" + sid]: !state.collapse["open" + sid],
      },
    });
  };
  const toggleClose = () => {
    closeDrawer();
  };
  useEffect(() => {
    console.log(state.collapse)
  }, [state.collapse])
  
  return (
    <div>
      {/* Side menu design starts here */}
      <Row className="d-flex align-items-center menu-top">
        <Col xs={10} className="d-flex ">
          <img
            src={SunLogo}
            className="menu-sun menu-sun-center"
            alt="icon"
            onClick={toggleClose}
          />
        </Col>
        <Col xs={2}>
          <img src={CloseIcon} alt="close" onClick={toggleClose} />
        </Col>
      </Row>
      <hr />
      {items.map((x) => {
        return (
          <>
            {x.children?.length ? (
              <>
                <div
                  className={`d-flex justify-content-between align-items-center curs-point py-2 ${state.collapse['open'+x.id]?'highlightColor':''}`}
                  onClick={() => {
                    toggleOpen(x.id);
                    console.log(x.id)
                  }}
                >
                  <div className={ `pl-3 parent d-flex align-items-center `  }>
                    {x.title == "Resources" && (
                      <img
                        src={ResourcesIcon}
                        alt="Favorite"
                        className="mr-3"
                      />
                    )}
                    {x.title == "IC Engage" && (
                      <img src={IcIcon} alt="Favorite" className="mr-3" />
                    )}
                    {x.title == "My account" && (
                      <img src={User} alt="Favorite" className="mr-3" />
                    )}
                    <p className="m-0">{x.title}</p>
                  </div>
                  <div className=" pl-3 pr-3">
                    {!state.collapse["open" + x.id] ? (
                      <img
                        src={DropArrow}
                        alt="PlusIcon "
                        className="curs-point acc_icon"
                      />
                    ) : (
                      <img
                        src={DropArrow}
                        alt="minus "
                        className="curs-point acc_icon rotate-180"
                      />
                    )}
                  </div>
                </div>
                
                {/* <hr /> */}
                <Collapse
                  className="curs-point"
                  isOpen={state.collapse["open" + x.id]}
                >
                  {x.children.map((y) => {
                    return y.children?.length ? (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center py-2 curs-point"
                          onClick={() => togglechildOpen(x.id, y.id)}
                        >
                          <div className=" ml-10  pl-5 child-fw">
                            <b className=" child-fw">{y.title}</b>
                          </div>
                          <div className="pl-3 pr-3">
                            {!state.collapse["open" + y.id] ? (
                              <img
                                src={DropArrow}
                                alt="PlusIcon "
                                className="curs-point acc_icon"
                              />
                            ) : (
                              <img
                                src={DropArrow}
                                alt="minus "
                                className="curs-point acc_icon rotate-180"
                              />
                            )}
                          </div>
                        </div>
                        {/* <hr /> */}
                        <Collapse
                          className="curs-point"
                          isOpen={state.collapse["open" + y.id]}
                        >
                          {y.children.map((z) => {
                            return z.subchildren?.length ? (
                              <>
                                <div
                                  className="d-flex justify-content-between align-items-center py-2 curs-point"
                                  onClick={() =>
                                    togglesubchildOpen(x.id, y.id, z.id)
                                  }
                                >
                                  <div className="pl-5 child-fw">
                                    <b className="ml-20 child-fw">{z.title}</b>
                                  </div>
                                  <div className="pl-3 pr-3 ">
                                    {!state.collapse["open" + z.id] ? (
                                      <img
                                        src={PlusIcon}
                                        alt="PlusIcon"
                                        className="curs-point sub_acc_icon"
                                      />
                                    ) : (
                                      <img
                                        src={MinusIcon}
                                        alt="minus "
                                        className="curs-point sub_acc_icon"
                                      />
                                    )}
                                  </div>
                                </div>
                                <Collapse
                                  className="curs-point"
                                  isOpen={state.collapse["open" + z.id]}
                                >
                                  {z.subchildren.map((s) => {
                                    return (
                                      <>
                                        <a
                                          className="hrf_dat"
                                          href={s.link}
                                          target={s.target}
                                        >
                                          <div className="pl-5 pr-1 py-2   child-fw meme curs-point">
                                            <p className="ml-35 child-fw m-0">
                                              {s.title}
                                            </p>
                                          </div>
                                        </a>
                                      </>
                                    );
                                  })}
                                </Collapse>
                     
                              </>
                            ) : (
                              <>
                                <a
                                  className="hrf_dat"
                                  href={z.link}
                                  target={z.target}
                                >
                                  <div className="pl-5 pr-1 py-2   child-fw meme curs-point">
                                    <p className=" ml-20 child-fw m-0">
                                      {z.title}
                                    </p>
                                  </div>
                                </a>
                               
                              </>
                            );
                          })}
                        </Collapse>
                        {y.hr ? (
                  <div className="hr_menu">
                    <hr />
                  </div>
                ) : (
                  ""
                )}
                      </>
                    ) : (
                      <>
                        <a className="hrf_dat" href={y.link} target={y.target}>
                          <div className=" pl-5 pt-2 pb-2  ml-10 parent curs-point d-flex align-items-center">
                            {y.title == "Weekly round up archive" && (
                              <img
                                src={Folder}
                                alt="Favorite"
                                className="mr-10"
                              />
                            )}
                            {y.title == "Agent scripts" && (
                              <img
                                src={Scripts}
                                alt="Favorite"
                                className="mr-10"
                              />
                            )}
                            {y.title == "Training videos" && (
                              <img
                                src={Videos}
                                alt="Favorite"
                                className="mr-10"
                              />
                            )}
                            {y.title == "Vendor deals" && (
                              <img
                                src={Discount}
                                alt="Favorite"
                                className="mr-10"
                              />
                            )}
                            <p className="child-fw m-0">{y.title}</p>
                          </div>
                        </a>
                        {/* <hr /> */}
                      </>
                    );
                  })}
                </Collapse>{" "}
                {x.hr ? (
                  <div className="hr_menu">
                    <hr />
                  </div>
                ) : (
                  ""
                )}
              </>
              
            ) : (
              <>
                {x.title == "My Memepacks" ? (
                  ""
                ) : (
                  <a className="hrf_dat" href={x.link} target={x.target}>
                    <div className=" pl-3 pr-3 pt-2 pb-2   parent curs-point d-flex align-items-center">
                      {x.title == "Calendar" && (
                        <img src={Calendar} alt="Favorite" className="mr-3" />
                      )}
                      {x.title == "My favorites" && (
                        // <i className="fa fa-bookmark-o mr-3" aria-hidden="true"></i>
                        <img src={Favorite} alt="Favorite" className="mr-3" />
                      )}
                      {/* <i className="fa fa-heart-o mr-3" aria-hidden="true"></i>} */}
                      {x.title == "Articles" && (
                        <img
                          src={ArticleIcon}
                          alt="Favorite"
                          className="mr-3"
                        />
                      )}
                      {x.title == "All content" && (
                        <img
                          src={ContentIcon}
                          alt="Favorite"
                          className="mr-3"
                        />
                      )}
                      {x.title == "Dashboard" && (
                        <img
                          src={DashboardIcon}
                          alt="Favorite"
                          className="mr-3"
                        />
                      )}
                      {/* <HistoryIcon className='mr-2' />} */}
                      {x.title == "Postcards" && (
                        <img src={PostCard} alt="Favorite" className="mr-3" />
                      )}
                      {x.title == "Memes" && (
                        <img src={MemeIcon} alt="Favorite" className="mr-3" />
                      )}
                      {x.title == "Booklets" && (
                        <img src={Booklet} alt="Favorite" className="mr-3" />
                      )}
                      {x.title == "FB covers" && (
                        <img src={FbIcon} alt="Favorite" className="mr-3" />
                      )}
                      {x.title == "Emails & Letters" && (
                        <img src={Envelope} alt="Favorite" className="mr-3" />
                      )}
                      {x.title == "Sign Out" && (
                        <img src={Logout} alt="Favorite" className="mr-3" />
                      )}
                      <p className="m-0 p-0">{x.title}</p>
                    </div>
                  </a>
                )}
                {x.hr ? (
                  <div className="hr_menu">
                    <hr />
                  </div>
                ) : (
                  ""
                )}
                {/* <hr /> */}
              </>
            )}
          </>
        );
      })}
      {/* Side menu design ends here */}
    </div>
  );
};

export default SideMenus;
