import React from "react";
import Avatar from "@mui/material/Avatar";
import { bindActionCreators } from "redux";
import * as dealsAction from "../../../_Config/Actions/DealsAction";
import { connect } from "react-redux";
import logo from "../../../Assets/Images/innercircle-logo.png";
import AvatarUser from "../../../Assets/Images/placeholder/userAvator.png";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import Sidedrawer from "../SideMenuBar/Sidedrawer";
import { DropdownSubmenu } from "react-bootstrap-submenu";
import Multidropdown from "../Inputs/Multidropdown";
import Breadcrumb from "../Breadcrump/Breadcrumb";
import { Markup } from "interweave";
import SearchIcon from "@mui/icons-material/Search";
import OutsideClickHandler from "react-outside-click-handler";

class CustomNav extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.seaarchInputShow = this.seaarchInputShow.bind(this);
    this.hidesearchField = this.hidesearchField.bind(this);
    this.finalarr = [];
    this.state = {
      dropdownOpen: false,
      dropdownOpen2: false,
      dropdownOpen3: false,
      collapse1: false,
      collapse2: false,
      right: false,
      open: false,
      topMenu: this.props.dealsList?.menus?.top_main_bar,
      showSearchField: false,
      onScroll: "",
    };
  }

  handleDrawerOpen = () => {
    this.setState({ ...this.state, open: true });
  };

  handleDrawerClose = () => {
    this.setState({ ...this.state, open: false });
  };

  componentDidMount() {
    if (document.getElementsByTagName("body")[0].clientWidth < 950) {
      window.addEventListener("scroll", () => {
        if (window.pageYOffset < 60) {
          this.setState({ ...this.state, onScroll: "" });
        } else if (window.pageYOffset > 60) {
          this.setState({ ...this.state, onScroll: "newPosition" });
        }
      });
    }
  }

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.state.right = open;
    this.setState({ ...this.state, [anchor]: open });
  };

  toggle(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  onMouseEnter(id) {
    this.setState({ [id]: true });
  }

  onMouseLeave(id) {
    this.setState({ [id]: false });
  }

  toggle1() {
    this.setState({ collapse1: !this.state.collapse1 });
  }

  seaarchInputShow() {
    this.setState({
      ...this.state,
      showSearchField: true,
      collapse2: !this.state.collapse2,
    });
  }

  hidesearchField(status) {
    this.setState({ collapse2: !this.state.collapse2, showSearchField: false });
  }

  render() {
    return (
      <div>
        {/* Navbar design starts */}
        <Navbar className="ic-blog" light expand="md">
          <NavbarBrand className="" href="/">
            <img
              alt="logo"
              src={
                this.props.dealsList?.header?.header_logo
                  ? this.props.dealsList?.header?.header_logo
                  : logo
              }
              width={150}
            />
          </NavbarBrand>
          <NavbarToggler className="blog-tag" onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto blog-navbar" navbar>
              {this.state.topMenu.map((x, i) => {
                return x.children?.length ? (
                  <div key={i}>
                    <UncontrolledDropdown
                      onMouseOver={() =>
                        this.onMouseEnter("dropdownOpen" + x.id)
                      }
                      onMouseLeave={() =>
                        this.onMouseLeave("dropdownOpen" + x.id)
                      }
                      isOpen={this.state["dropdownOpen" + x.id]}
                      toggle={() => this.toggle("dropdownOpen" + x.id)}
                      nav
                      inNavbar
                    >
                      <DropdownToggle nav caret>
                        {x.title}
                      </DropdownToggle>
                      <DropdownMenu right>
                        {x.children.map((y, i) => {
                          return (
                            <div key={i}>
                              {y.subchildren?.length ? (
                                <DropdownSubmenu
                                  className="sub-menu"
                                  title={y.title}
                                  onMouseOver={() =>
                                    this.onMouseEnter("dropdownOpen" + y.id)
                                  }
                                  onMouseLeave={() =>
                                    this.onMouseLeave("dropdownOpen" + y.id)
                                  }
                                  expand={this.state["dropdownOpen" + y.id]}
                                  onToggle={() =>
                                    this.toggle("dropdownOpen" + y.id)
                                  }
                                >
                                  {y.subchildren?.map((z) => {
                                    return (
                                      <a
                                        className="ic-anchor"
                                        style={{ color: "#444444" }}
                                        href={z.link}
                                      >
                                        <DropdownItem>{z.title}</DropdownItem>
                                      </a>
                                    );
                                  })}
                                </DropdownSubmenu>
                              ) : (
                                <a
                                  className="ic-anchor"
                                  style={{ color: "#444444" }}
                                  href={y.link}
                                >
                                  <DropdownItem>
                                    <Markup content={y.title} />
                                  </DropdownItem>
                                </a>
                              )}
                            </div>
                          );
                        })}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                ) : (
                  <NavItem>
                    <NavLink className="curs-point">
                      <a
                        className="ic-anchor"
                        style={{ color: "#444444" }}
                        href="https://ic-dev.lightersideofrealestate.com/dashboard"
                        target="_blank"
                      >
                        {x.title}
                      </a>
                    </NavLink>
                  </NavItem>
                );
              })}
              <Collapse className="ic-search" isOpen={this.state.collapse1}>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    this.setState({ collapse1: false });
                  }}
                >
                  <Multidropdown
                    handleClose={this.toggle1}
                    isMobile={false}
                    searchDropdown={this.state.collapse1}
                  />
                </OutsideClickHandler>
              </Collapse>
              <NavItem>
                <NavLink
                  href="#"
                  className={
                    this.state.collapse1 ? "searchActive pt-2" : "pt-2"
                  }
                  onClick={this.toggle1}
                >
                  <SearchIcon
                    className={
                      this.state.collapse1
                        ? "baseSearchIcon"
                        : "Ic-Info ic-h-search-btn"
                    }
                  />{" "}
                </NavLink>
              </NavItem>
              <NavItem>
                <a
                  href={this.props.dealsList?.ic_view_myblog_link}
                  target="_blank"
                >
                  {" "}
                  <Button className="Ic-info ic-header-view-btn" size="sm">
                    View My Blog
                  </Button>
                </a>
              </NavItem>
            </Nav>
          </Collapse>
          {this.state.showSearchField ? (
            <Multidropdown
              closeHandle={this.hidesearchField}
              isMobile={true}
              collapse2={this.state.collapse2}
            />
          ) : null}
          <Nav className={`navAvatarNav ${this.state.onScroll}`}>
            <NavItem className="SearchNavItem">
              <NavLink
                href="#"
                className={this.state.collapse1 ? "searchActive " : ""}
                onClick={this.seaarchInputShow}
              >
                <SearchIcon className="Ic-Info ic-h-search-btn" />{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink>
                <Avatar
                  className="curs-point navAvatar"
                  alt="Remy Sharp"
                  src={
                    this.props.dealsList?.ic_user_profile_pic
                      ? this.props.dealsList?.ic_user_profile_pic
                      : AvatarUser
                  }
                  onClick={this.toggleDrawer("right", !this.state.right)}
                />
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar>
        {/* Navbar design ends */}

        {/* Imported Sidedrawer  */}
        <Sidedrawer
          toggleDrawer={this.toggleDrawer}
          drawerOpen={this.state}
          dealsList={this.props.dealsList}
        />

        {/* Imported Breadcrumb  */}
        {/* <Breadcrumb dealsList={this.props.dealsList?.page_info}/> */}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    // dealsList: state.blogs
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dealsAction: bindActionCreators(dealsAction, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomNav);
