import React, { useState, useEffect } from "react";
import ShareIcon from "@mui/icons-material/Share";
import OutsideClickHandler from 'react-outside-click-handler';
import { Markup } from 'interweave';
import { Row, Col, Container } from "reactstrap";

export default function MobileFooter(props) {
  const [open, setOpen] = useState({});
  const [show, setShow] = React.useState(false);
  const [footermenus, setFooterMenus] = useState(props.dealsList);
  const [blog, setBlog] = useState(props.dealsList1)

  let handleToggle = (id) => {
    setShow(true)
    setOpen({ ["open" + id]: !open["open" + id] });
  };

  return (
    <div className="CCMobileFooter">
      <OutsideClickHandler onOutsideClick={() => {
        handleToggle()
      }
      }>
        <div className='pt-2 pb-2 footer_contents' id='MobileFooter'>
          {/* Footer design starts */}
          <div id="bottom-bar" class="logo-left" role="contentinfo">
            <div class="wf-wrap">
              <div class="wf-container-bottom">

                {/* <div id="branding-bottom"><a class="" href={this.props.siteUrl}><img class=" preload-me" src={props.dealsList2?.footer_logo} width="106" height="21" sizes="106px" alt="The Inner Circle" /></a></div> */}
                <div class="wf-float-left">
                  <Markup content={props.dealsList2?.footer_copy_right} /><a className='ml-1' href={props.dealsList2?.footer_privacy_link}>{props.dealsList2?.footer_privacy_text} </a> - <a href={props.dealsList2?.footer_terms_link}>{props.dealsList2?.footer_terms_text} </a>
                </div>
              </div>
            </div>
          </div>
          {/* Footer design ends */}
        </div>
        {/* mobile footer design starts */}
        <div className="ResponsiveFooter">
          {footermenus.map(x => {
            return (
              <>
                {x.children?.length ?
                  <>
                    <div className={`curs-point ${x.title == "Share" ? "share" : "" ||
                      x.title == "Resources" ? "resources" : "" ||
                        x.title == "IC engage" ? "ic_engage" : ""
                      }`}
                      onClick={() => handleToggle(x.id)}>
                      <div className="_inner">
                        <center>
                          {x.title == "Share" &&
                            <div className="_share">
                              <ShareIcon className="shareIcon" sx={{ fontSize: 30 }} />
                            </div>
                          }
                          {x.title == "Resources" &&
                            <div className="res_book">
                              <img
                                src="https://innercircle.lightersideofrealestate.com/wp-content/themes/innercircle/images/onboarding/mobile_icon/ft_resource_icon.png"
                                alt="resources"
                              />
                            </div>
                          }
                          {x.title == "IC engage" &&
                            <div className="group">
                              <img
                                src="https://innercircle.lightersideofrealestate.com/wp-content/themes/innercircle/images/onboarding/mobile_icon/ft_icengage_icon.png"
                                alt="ic_engage"
                              />
                            </div>
                          }
                          <p>{x.title}</p>
                        </center>
                      </div>
                    </div>
                    <div className={`${!show ? "noneTransition" : ''} ${open["open" + x.id] ? "forwardTransition" : ''} ${!open["open" + x.id] ? "reverseTransition" : ''} ${!open["open" + x.id] ? "noneTransition" : ''}  `}>
                      {
                        x.children.map((y, index) => (
                          <div>
                            <div
                              className={
                                index === 0 && x.title == "Share" ? "boderTopColorShare sharelist" :
                                  index === 0 && x.title == "Resources" ? "boderTopColorResources sharelist" :
                                    index === 0 && x.title == "IC Engage" ? "boderTopColorIcEngage sharelist" : "sharelist"
                              }
                              key={index}
                            >
                              <b className="shareListText">
                                <a className="shareListText"
                                  href={y.link}
                                >
                                  <Markup content={y.title} />
                                </a>
                              </b>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </>
                  : null}
              </>
            )
          })
          }
          <div className="my_blog curs-point">
            <div className="_inner">
              <center>
                <a href={blog} target="_blank">
                  <div className="myBlog">
                    <img
                      src="https://innercircle.lightersideofrealestate.com/wp-content/themes/innercircle/images/onboarding/mobile_icon/ft_viewblog_icon.png"
                      alt="My_Blog"
                    />
                  </div></a>
                <p>My Blog</p>

              </center>
            </div>
          </div>
        </div>
        {/* mobile footer design ends */}
      </OutsideClickHandler >
    </div >
  );
}
