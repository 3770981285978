import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dealsAction from '../../../_Config/Actions/DealsAction';
class Breadcrumb extends React.Component {
   constructor(props) {
     super(props)
   
     this.state = {
        // Get Api data from CustomNav as props
        dealList : this.props.dealsList
     }
   }
   

    render() {
        return (
            <div className='Breadcrump'>
                {/* Breadcrump design starts */}
                <div className="header_banner_wrapper">
                    <div className="header_banner_innrwrapper">
                        <div className="profile_inner_header clearfix">
                            <h1 className="pull-left">{this.state.dealList?.page_title}</h1>
                            <div className="pull-right view_article_content branding_viewonly mobile_view">
                                <button className="font_14 light_yellow_color no_text_decoration getting_preview_manage_mobile view_article_btn border_radius_common"><span>View Example</span></button>
                            </div>
                        </div>
                        {
                             <p className={`mb-0 ${this.state.dealList?.page_sub_title ? "pt-2":""}`} style={{color:"#ffff"}}>{this.state.dealList?.page_sub_title }<a className='curs-point ' style={{color:"#ffe200",textDecoration:"underline"}} href={this.state.dealList?.page_sub_title_text_link }>{this.state.dealList?.page_sub_title_text }</a></p>
                            }
                    </div>
                    <div className="header_banner_overlay"></div>
                </div>
                {/* Breadcrump design ends */}
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        // dealList: state.blogs.page_info
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dealsAction: bindActionCreators(dealsAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
