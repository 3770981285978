import * as types from './ActionTypes';
import contentCalendarApi from '../Api/ContentCalendarApi';


export function getContentCalendar(data) {
  return function (dispatch) {
    return contentCalendarApi.getContentCalendar(data).then(data => {
      return data;
    }).catch(error => {
      throw (error);
    })
  }
}

