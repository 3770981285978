import React, { useState,useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { Collapse } from "reactstrap";
import axios from "axios";
import { Markup } from "interweave";
import "antd/dist/antd.css";
import { AutoComplete } from "antd";
import { bindActionCreators } from "redux";
import * as dealsAction from "../../../_Config/Actions/DealsAction";
import { connect } from "react-redux";

function Multidropdown(props) {
  const { handleClose, closeHandle } = props;
  const [selectedValue, setSelectedValue] = useState();
  const [countobj, setCountobj] = useState([]);
  const [objTitle, setObjTitle] = useState({
    meme: "In Memes",
    post: "In Articles",
    white_paper_types: "In Branded Booklets",
    postcards: "In Witty Postcards",
    facebook_covers: "In Facebook Covers",
    videomeme: "In Videos",
  });
  const [objValue, setObjValue] = useState({
    meme: "In Memes",
    post: "In Articles",
    white_paper_types: "In Branded Booklets",
    postcards: "In Witty Postcards",
    facebook_covers: "In Facebook Covers",
    videomeme: "In Videos",
  });

  // Redirect to the url
  const onSelect = (value, options) => {
    setSelectedValue(
      options.text.includes("typeof") ? selectedValue : options.text
    );
    let url = countobj[value - 1].url;
    if (url) {
      window.location.href =
        "https://innercircle.lightersideofrealestate.com/search?" + url;
    }
  };

  function debounce(func, delay) {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  }
  function capitalizeFirstLetter(str) {
    // Regex to match tags and separate text
    const regex = /(<[^>]+>|[^<]+)/g;
    
    // Split the string by the regex to separate text from tags
    const parts = str.match(regex);
    
    if (parts && parts.length > 0) {
      // Iterate through parts, find the first text part, and capitalize the first letter
      for (let i = 0; i < parts.length; i++) {
        if (!parts[i].startsWith('<')) { // Ignore tags
          parts[i] = parts[i].charAt(0).toUpperCase() + parts[i].slice(1);
          break; // Only capitalize the first text part
        }
      }
      
      // Join the parts back together
      return parts.join('');
    }
    
    return str;
  }
  const handleInputChange = async (query) => {
    if (query != "" && query.length > 1) {
      let temp = [];
      temp.push({ label: query });

      // Call the swifttype count api
      let res = await axios
        .post(
          `https://innercircle.lightersideofrealestate.com/wp-json/elastic_search_api/v1/search_by_keyword`,
          { query: query }
        )
        .then((res) => {
          let resData = res?.data?.data.facts;
          for (let key in objTitle) {
            if (resData[key])
              temp.push({
                options: [
                  renderItem(
                    objTitle[key] + " ( " + resData[key] + " ) ",
                    temp.length
                  ),
                ],
                url:
                  "mode=s&q=" +
                  query +
                  "&type=" +
                  objValue[key].replace("In ", "") +
                  "&pass=cpt&filter=most_recent",
              });
          }
          let resData2 = res?.data?.data.hits;
          console.log(resData2, "resData2")
          const allHighlights = resData2.flatMap(
            (obj) => obj._source.highlight
          );
          const unique = [...new Set(allHighlights)];
          unique.map((x, i) => {
            let val = capitalizeFirstLetter(x);
            if (val != undefined)
              temp.push({
                label: <Markup content={val} />,
                text: val.replace(/<\/?[^>]+(>|$)/g, ""),
                url:
                  "mode=s&q=" +
                  val.replace(/(<([^>]+)>)/gi, "") +
                  "&type=All Content&pass=tag&filter=featured",
              });
          });
        });

      // Call the swifttype search api
      temp.forEach((o, i) => (o.value = i + 1));
      setCountobj(temp);
      console.log(temp,'temp')
    } else {
      setCountobj([]);
    }
  };
  // Wrap handleInputChange with debounce
  const debouncedHandleInputChange = useCallback(
    debounce(handleInputChange, 300),
    []
  );

  const searchResult = async (query) => {
    setSelectedValue(query);
    debouncedHandleInputChange(query);
  };

  function filterDuplicates(arr, nestedKey) {
    const seen = new Set();
    return arr.filter((obj) => {
      const nestedValue = obj[nestedKey].tags_display; // Adjust the key path as needed
      if (!seen.has(nestedValue)) {
        seen.add(nestedValue);
        return true;
      }
      return false;
    });
  }

  const renderItem = (title, length) => ({
    value: length + 1,
    label: <span>{title}</span>,
    text: title + "typeof",
  });

  return (
    <>
      {/* top search design starts */}
      <div
        className="search-box"
        style={{ display: props.isMobile === false ? "block" : "none" }}
      >
        <span className="search-boxtext">
          <AutoComplete
            dropdownClassName={
              !props.searchDropdown ? "muiltiSearchDropdown" : ""
            }
            dropdownMatchSelectWidth={252}
            listItemHeight="100%"
            style={{ width: "100%" }}
            options={countobj}
            value={selectedValue}
            onSelect={onSelect}
            onSearch={searchResult}
            placeholder="Search memes and more..."
          />
          <a
            href="/search?init=1"
            className="brse_all desktop_browse_all browseall_contnet_link"
          >
            Browse all
          </a>
          <div
            className="searchbtn text-center curs-point"
            onClick={handleClose}
          >
            <SearchIcon className="Ic-Info ic-h-search-btn1" />
          </div>
        </span>
      </div>
      {/* top search design ends */}

      {/* mobile  top search design starts */}

      <div
        className="mobile-search-box"
        style={{ display: props.isMobile === true ? "block" : "none" }}
      >
        <Collapse
          className="ic-search mobileSearch-collapse"
          isOpen={props.collapse2}
        >
          <div className="mobileSearchWrapper">
            <span className="search-boxtext">
              <AutoComplete
                dropdownMatchSelectWidth={252}
                listItemHeight="100%"
                style={{ width: "100%" }}
                options={countobj}
                value={selectedValue}
                onSelect={onSelect}
                onSearch={searchResult}
                placeholder="Search memes and more..."
              />
              <a onClick={closeHandle}>
                <CloseIcon
                  className="closeIcon_mobileSearch"
                  sx={{ fontSize: 30 }}
                />
              </a>
            </span>
            <div className="BrowseContent">
              <a className="BrowseContent_text" href="#">
                Browse All
              </a>
            </div>
          </div>
        </Collapse>
      </div>
      {/* mobile  top search design ends */}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    // dealsList: state.blogs
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dealsAction: bindActionCreators(dealsAction, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Multidropdown);
