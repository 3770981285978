import React, { Component } from 'react';
import Breadcrumb from '../../Common/Breadcrump/Breadcrumb';
import Deals from './Deals';

export default class Wrapper extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         dealsdata:this.props.dealsList,
         breadcrumbData : {
            page_title: 'Vendor Deals',
            page_sub_title: 'We are not compensated by these companies. We’ve simply asked them to offer their deepest discounts for you. If you’d like us to contact your favorite vendor(s),',
            page_sub_title_text:'let us know' 
        }
      }
    }
     
    
    render() {
        return (
            <div>
                <Breadcrumb dealsList={this.state.breadcrumbData} />
                <Deals data={this.state.dealsdata}/>
            </div>
        );
    }
}