import React from 'react'
import { useState } from 'react';
import { Container, Button } from 'reactstrap';
import IframeModal from './IframeModal';

import FullCalendar, { EventApi, DateSelectArg, EventClickArg, EventContentArg, formatDate } from '@fullcalendar/react'
// import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import Breadcrumb from '../../Common/Breadcrump/Breadcrumb';


import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import Events from './Events'

const Content = () => {
    const [open, setOpen] = useState(false)
    const [data, setData] = useState({})
    const [postid, setPostId] = useState('')
    const [publicId, setPublicId] = useState('')
    const [randomId, setRandomId] = useState('')
    const [publishTo, setPublishTo] = useState('')
    const [iframeHeight, setIframeHeight] = useState('')

    const breadcrumbData = {
        page_title: 'Content Calendar',
        page_sub_title: ''
    }

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleEventClick = (clickInfo) => {
        setOpen(true)
        setData(clickInfo)
        setPostId(clickInfo.event._def.extendedProps.postid)
        setPublicId(clickInfo.event._def.publicId)
        setRandomId(clickInfo.event._def.extendedProps.random_id)
        setPublishTo(clickInfo.event._def.extendedProps.publish_to)
    }
    window.addEventListener('message', receiveMessage, false);
    function receiveMessage(evt) {
      setIframeHeight(evt.data)
       
    }

    return (
        <div>
            <Breadcrumb dealsList={breadcrumbData} />
            <Container fluid>
                <IframeModal isOpen={open} handleClose={handleClose} postid={postid} publicId={publicId} randomId={randomId} publishTo={publishTo} iframeHeight={iframeHeight}/>
                <div className='calc-desk text-center mb-4'>
                    <FullCalendar
                        initialView='dayGridMonth'
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                        }}
                        events={Events}
                        eventClick={handleEventClick}
                    />
                </div>
                <div className='calc-mobile text-center'>
                    <FullCalendar
                        initialView='timeGridDay'
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        headerToolbar={false}
                        events={Events}
                        allDaySlot={false}
                        contentHeight="auto"
                        eventClick={handleEventClick}
                    />
                </div>

            </Container>
        </div>
    )
}

export default Content
