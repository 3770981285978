import React from "react";
// import { Markup } from "interweave";
import { sectionData, month } from "../../../_LocalData/TemplateData";

function TemplateSection() {
  return (
    <div className="section-wrapper">
      <div className="">
        <h3>
          <b>
            <p className="section_header">Jump to section</p>
          </b>
        </h3>
      </div>
      {/*  */}
      <div className="section-month">
        {month.map((item, index) => (
          <span>
            &nbsp;<a href={`#${item.url}`}>{item.name}</a> {index === month.length - 1 ? null : `|` }
          </span>
        ))}
      </div>
      <hr />
      <div className="sectionData-wrapper">
        {sectionData.map((item, index) => (
          <a href={item.url}>{item.name}</a>
        ))}
      </div>
    </div>
  );
}

export default TemplateSection;
