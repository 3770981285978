import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { brandedBooks } from '../../../_LocalData/BrandedBookList';
import Breadcrumb from '../../Common/Breadcrump/Breadcrumb';

const BrandedBook = () => {
    const breadcrumbData = {
        page_title: 'Branded Booklets',
        page_sub_title: ''
      }
    return (
        <div className='page-container'>
            <Breadcrumb dealsList={breadcrumbData} />
            <Container>
                <Row>
                    {brandedBooks.map(x => {
                        return (
                            <Col lg="4" sm="4">
                                <div className='bklt-main'>
                                    <img className='bklt-card' src={x.Img_url} />
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </div>
    );
};

export default BrandedBook;