import React, { useState } from 'react';
import { Button, Modal, CardBody } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';

const PublishSuccessModal = (props) => {

    const { isOpen, handleClose, data } = props


    return (
        <div className='contactForm1'>
            <Modal className="modal-dialog-centered ccpublish Deals" isOpen={isOpen} size="md">
                <div className="modal-body  d-flex justify-content-center p-0">
                    <CardBody className='p-0'>
                        <div>
                            <div className="publish_success">The {data} has been published successfully.</div>
                        </div>
                    </CardBody>
                </div>
            </Modal>
        </div>
    )
}


export default PublishSuccessModal;

