import React from "react";
import { Row, Col, Container } from "reactstrap";
import TemplateContent from "./TemplateContent";
import TemplateSection from "./TemplateSection";
import Breadcrumb from '../../Common/Breadcrump/Breadcrumb';

function TemplateWrapper() {
  const breadcrumbData = {
    page_title: 'Email & Letter Templates',
    page_sub_title: 'Looking for something to email something clever to your followers? We’ve got you covered.'
  }
  return (
    <div className="Template-Wrapper">
      <Breadcrumb dealsList={breadcrumbData} />
      <Container>
        <Row className="margintop-5">
          <Col lg="4" sm="12">
            <TemplateSection />
          </Col>
          <Col lg="8" sm="12">
            <TemplateContent />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TemplateWrapper;
