export const brandedBooks = [
    {
        "Img_url": "https://icdevcdn.lightersideofrealestate.com/uploads/2021/01/investors-guide-sellers-thumb.jpg"
    },
    {
        "Img_url": "https://icdevcdn.lightersideofrealestate.com/uploads/2021/01/investors-guide-buyers-thumb.jpg"
    },
    {
        "Img_url": "https://icdevcdn.lightersideofrealestate.com/uploads/2020/05/first-time-buyer-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/01/homeseller-bb-thumb1.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/01/homebuyer-bb-thumb1.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/01/fsbo-bb-thumb1.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/09/renter-to-owner-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/10/new-construction-bb-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/08/preparing-to-sell-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/05/buying-fsbo-home-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/05/relocation-guide-buyers-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/05/relocation-guide-sellers-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/03/open-house-bb-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/03/recently-expired-guide-thumb-v1.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/03/recently-expired-guide-thumb-v2.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/06/handling-estate-booklet-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/02/divorce-guide-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/07/foreclosure-guide-thumb.jpg"
    },
    {
        "Img_url": "https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/09/downsizing-home1-thumb.jpg"
    }
]
