import React from 'react'
import './Loader.scss'

export const Dotloder = (props) => {
    // const { saved } = props;
    return (
        <>
            <div className='Dotload'></div>
            <div className='CCloader'>
                <div className='Load pl-3 pr-3'>
                    <div>
                        <h4 className='mb-0'>
                            Updating<span>.</span><span>.</span><span>.</span>
                        </h4>
                    </div>
                </div>
            </div>
        </>
    )
}
