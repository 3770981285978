import React, { Component } from "react";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIMG from "../../../Assets/Images/facebook.png";

export class HelpCenterQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div className="HelpQuestion-wrapper">
        <div className="HelpQuestion-topic">
          <h3>Have another question?</h3>
        </div>
        <div className="HelpQuestion-Buttons">
          <div className="help-ContactUS">
            <EmailIcon className="email-Icon" /> &nbsp;
            <a href="#" className="contact-us">
              {" "}
              Contact Us
            </a>
          </div>
          <div className="help-ICFacebook">
            <img
              alt="facebook-group"
              src={FacebookIMG}
              className="icon-facebook"
            />{" "}
            &nbsp;
            <a href="#" className="IC-Facebook-Group">
              IC Facebook Group
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default HelpCenterQuestion;
