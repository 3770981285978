import * as types from './ActionTypes';
import dealsApi from '../../_Config/Api/DealsApi';

export function loadAllBlogSucess(blogs) {
  return { type: types.LOAD_ALL_BLOGS_SUCCESS, blogs };
}

export function getDeals(data) {
  return function (dispatch) {
      return dealsApi.getDeals(data).then(data => {
        dispatch(loadAllBlogSucess(data));
        return data;
      }).catch(error => {
          throw (error);
      })
  }
}

export function getContactForm(data) {
  return function (dispatch) {
    return dealsApi.getContactForm(data).then(data => {
      return data;
    }).catch(error => {
      throw (error);
    })
  }
}

