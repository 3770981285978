import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import SideMenus from './SideMenus';

export default function Sidedrawer(props) {

  const { toggleDrawer, onClose,drawerOpen, dealsList, data } = props;


  const list = (anchor) => (
    <Box sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }} role="presentation">
        <SideMenus items={dealsList?.menus?.header_profile_menu} closeDrawer={onClose} />
    </Box>
  );

  return (
    <div>
      {/* Side drawer design starts here */}
      <Drawer
       anchor={'left'}
       open={drawerOpen['left']}
       onClose={toggleDrawer('left', false)}
        className="custom-drawer side-cusdrawer"
      >
        {list('left')}
      </Drawer>
      {/* Side drawer design ends here */}
    </div>
  );
}
