import * as React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MobileFooter from '../Footer/MobileFooter';
import './Swipe.scss'

const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
    height: '100%',
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = () => {
    return (
        <div className='Swipedrawer text-center'>
           <span><i className="fa fa-angle-up" aria-hidden="true"></i></span> 
           <span><i className="fa fa-angle-up" aria-hidden="true"></i></span> 
           <span><i className="fa fa-angle-up" aria-hidden="true"></i></span> 
        </div>
    )
}

function Swipedrawer(props) {
    const { window, dealsList, dealsList1, dealsList2 } = props;
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Root>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        overflow: 'visible',
                    },
                }}
            />
            {/* <Box className='Clickup' sx={{ textAlign: 'center', pt: 1 }}>
                <span><i  onClick={toggleDrawer(true)} className="fa fa-caret-up" aria-hidden="true"></i></span> 
            </Box> */}
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={10}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: - 35,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                    }}
                >
                    {/* <Puller /> */}
                </StyledBox>
                <i className="fa fa-caret-down CCdown" onClick={toggleDrawer(false)}  aria-hidden="true"></i>
                <MobileFooter dealsList={dealsList} dealsList1={dealsList1} dealsList2={dealsList2} />

            </SwipeableDrawer>
        </Root>
    );
}

Swipedrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Swipedrawer;
