import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate,useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as dealsAction from '../_Config/Actions/DealsAction';
import { connect } from 'react-redux';
import CustomNav from '../Components/Common/CustomNavbar/CustomNav';
import Footer from '../Components/Common/Footer/Footer'
import Wrapper from '../Components/Pages/Deals/Wrapper'
import MobileFooter from '../Components/Common/Footer/MobileFooter'
import CircularProgress from '@mui/material/CircularProgress';
import BrandedBook from '../Components/Pages/BrandedBooklet/BrandedBook';
import EmailNotificationWrapper from '../Components/Pages/EmailNotification/Wrapper'
import AgentScript from '../Components/Pages/AgentScript/AgentScript'
import ContactPills from '../Components/Common/Contactpills/ContactPills';
import OutsideClickHandler from 'react-outside-click-handler';
import HelpWrapper from '../Components/Pages/HelpCenter/HelpWrapper'
import { meta } from '../_LocalData/NewApi'
import Content from '../Components/Pages/ContentCalendar/Content'
import ContentCalendar from '../Components/Pages/ContentCalendar/ContentCalendar'
import TemplateWrapper from '../Components/Pages/EmailLetterTemplate/TemplateWrapper'
import Mobilecalendar from '../Components/Pages/ContentCalendar/Mobilecalendar';
import CCNav from '../Components/Common/CustomNavbar/CCNav';


export class AdminLayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            location:""
        }
    }
    
    handleClick = () => {
        this.setState({ open: !this.state.open })
    }

    componentDidMount() {
        // this.getDeals();
        this.getslug()
    }

    getslug = () => {
        const location1 = window.location.pathname.split('/')
        console.log("location",location1[2])
        this.setState({location : location1[2]})
    }
    
    getDeals = () => {
        this.props.dealsAction.getDeals()
            .then(res => {

            }
            )
            .catch(error => { console.log(error) });
    }


    render() {
        console.log("first",this.state.location)
        return (
            <div>
                {/* {this.props.meta.ic_user_token ? */}
                <div>
                    <CustomNav
                        dealsList={meta}
                    // dealsList={this.props.meta}
                    />
                    {this.state.location=='content-calendar' && <CCNav
                    dealsList={meta}
                    // dealsList={this.props.meta}
                    />}
                    <div className='Page-Content'>
                        <Router basename='/app/'>
                            <Routes>
                                <Route path="/" element={<Navigate to="/content-calendar" />} />
                                <Route path="/deals"
                                    element={<Wrapper
                                        dealsList={meta}
                                    // dealsList={this.props.meta}
                                    />} />
                                <Route path="/demo" element={<Content />} />
                                {/* <Route path="/content-calendar" element={<ContentCalendar />} /> */}
                                <Route path="/content-calendar" element={<Mobilecalendar 
                                dealsList={meta?.menus?.mobile_footer}
                                dealsList1={meta?.ic_view_myblog_link}
                                dealsList2={meta?.footer} siteUrl={meta?.ic_site_url}
                                dataList={meta}
                                />} />
                                <Route path="/branded-booklets" element={<BrandedBook />} />
                                <Route path="/email-subscription" element={<EmailNotificationWrapper />} />
                                <Route path="/agent-scripts" element={<AgentScript />} />
                                <Route path="/emails-letters" element={<TemplateWrapper />} />
                                <Route path="/helpcenter" element={<HelpWrapper />} />
                            </Routes>
                        </Router>
                        {
                            meta.ic_display_service_icon == 'yes' ?
                                // this.props.meta.ic_display_service_icon == 'yes' ?
                                <OutsideClickHandler onOutsideClick={() => { this.setState({ open: false }) }}>
                                    <ContactPills handleClick={this.handleClick} open={this.state.open}
                                        dealsList={meta}
                                    // dealsList={this.props.meta}
                                    />
                                </OutsideClickHandler> :
                                ''
                        }
                    </div>
                    
                    <Footer
                            dealsList={meta?.footer} siteUrl={meta?.ic_site_url}
                        // dealsList={this.props.meta?.footer} siteUrl={this.props.meta?.ic_site_url}
                        />
                      {this.state.location!='content-calendar' && <MobileFooter
                            dealsList={meta?.menus?.mobile_footer}
                            dealsList1={meta?.ic_view_myblog_link}
                            dealsList2={meta?.footer} siteUrl={meta?.ic_site_url}
                        // dealsList={this.props.meta?.menus?.mobile_footer}
                        // dealsList1={this.props.meta?.ic_view_myblog_link}
                        />}
                </div>
                {/* :
                    <div className='loader'> <CircularProgress /> </div>
                } */}
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return {
        // meta: state.blogs
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dealsAction: bindActionCreators(dealsAction, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminLayout);
