import delay from './Delay';
import axios from 'axios';
import Base from './Base.json';
const url = Base.baseUrl + Base.version + Base.user;

class DealsApi {

    static getDeals(data) {
        const config = { headers: { 'content-type': 'multipart/form-data' } }
        return new Promise((resolve, reject) => {
            axios.post(url + `ic_react_api_index.php`, data, config)
                .then(res => {
                    setTimeout(() => {
                        resolve(Object.assign([], res));
                    }, delay)
                }).catch(error => {
                    resolve(error);
                })
        });
    }
    

    static getContactForm(data) {
        const config = { headers: { 'content-type': 'multipart/form-data', 'Authorization': 'Bearer ' + localStorage.getItem('token') } }
        return new Promise((resolve, reject) => {
            axios.post(url + `laravel_vendor_email_popup_subscribe.php`, data ,config)
                .then(res => {
                    setTimeout(() => {
                        resolve(Object.assign([], res.data));
                    }, delay)
                }).catch(error => {
                    resolve(error);
                })
        });
    }


}
export default DealsApi;