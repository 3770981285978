import React, { Component } from "react";
import { information_values } from "../../../_LocalData/AgentScriptConstantValues";
import { Markup } from "interweave";
import AccodianWrapper from "./AccodianWrapper";

export class AgentScriptContents extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div className="AgentContentsWrapper">
        <div className="informationWrapper mb-4">
          {information_values.map((items, index) => (
            <div key={index} className="infoValues">
              <Markup content={items.values} />
            </div>
          ))}
        </div>
        <AccodianWrapper />
      </div>
    );
  }
}

export default AgentScriptContents;
