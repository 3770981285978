import React from "react";
import { useState } from "react";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import IframeModal from "./IframeModal";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import calendarIcon from "../../../Assets/Images/calendar/calendar_icon.svg";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import Events from "./Events";
import Breadcrumb from "../../Common/Breadcrump/Breadcrumb";
import { useEffect } from "react";
import moment from 'moment'
import { bindActionCreators } from 'redux';
import * as dealsAction from '../../../_Config/Actions/DealsAction';
import * as contentCalendarAction from '../../../_Config/Actions/ContentCalendarAction';
import { connect } from 'react-redux';
import linkedinIcon from '../../../Assets/Images/calendar/calendar_colored_linkedin.png'
import facebook from '../../../Assets/Images/calendar/facebook.png'
import twitter from '../../../Assets/Images/calendar/twitter.png'
import pinInterest from '../../../Assets/Images/calendar/calendar_grey_pinterest_new.png'
import instagram from '../../../Assets/Images/calendar/instagramlight.png'
import instagramBright from '../../../Assets/Images/calendar/insta.png'
import linkedinLite from '../../../Assets/Images/calendar/calendar_grey_linkedin_content_calendar.png'
import pinInterestBright from '../../../Assets/Images/calendar/calendar_colored_pinterest.png'
import { toast } from 'react-toastify';
import jQuery from "jquery";
import events from "./Events";
import Close from "@mui/icons-material/Close";
import PublishnowModal from "./PublishnowModal";
import PublishSuccessModal from "./PublishSuccessModal";
import OutsideClickHandler from 'react-outside-click-handler';
import Successpost from '../../../Assets/Images/Icons/calendar_success.png'
import facebookBright from '../../../Assets/Images/calendar/calendar_colored_fb.png'
import twitterBright from '../../../Assets/Images/calendar/calendar_colored_twitter.png'
import Failpost from '../../../Assets/Images/calendar/calendar_failure.png'
import { Dotloder } from "../../Common/Loder/Dotloder";
import { set } from "react-hook-form";

const ContentCalendar = (props) => {

  const calendarComponentRef = React.createRef();
  const today = moment(new Date()).format("YYYY-MM-DD")
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [postid, setPostId] = useState("");
  const [publicId, setPublicId] = useState("");
  const [randomId, setRandomId] = useState("");
  const [publishTo, setPublishTo] = useState("");
  const [postStatus, setPostStatus] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(0);
  const [selectedItem, setSelectedItem] = useState(['article', 'meme']); /*'videomeme'*/
  const [gotoDay, setGotoDay] = useState(today);
  const [timeDisplayDay, setTimeDisplayDay] = useState('');
  const [timeDisplayDate, setTimeDisplayDate] = useState('');
  const [dropMonth, setDropMonth] = useState('');
  const [scrollCalendarClassName, setScrollCalendarClassName] = useState('');
  const [ccContactPills, setCcContactPills] = useState(true);
  let [loader, setLoder] = useState(false);
  const [filterEvents, setFilterEvents] = useState([]);
  const [scheduledPostEvents, setScheduledPostEvents] = useState([]);
  const [prevActive, setPrevActive] = useState(false);
  const [nextActive, setNextActive] = useState(false);
  const [dragDropedData, setDragDropedData] = useState({});
  const [openpublish, setOpenPublish] = useState(false);
  const [openPublishSuccess, setOpenPublishSuccess] = useState(false);
  const [iframeData, setIframeData] = useState('')
  const [iframeSelectedDate, setIframeSelectedDate] = useState('')
  const [dayCalcHeight, setDayCalcHeight] = useState();
  const [dragsave, setDragsave] = useState(false)
  const dataarr = [
    { id: 0, label: "Article", value: 'article' },
    { id: 1, label: "Memes", value: 'meme' },
    // { id: 2, label: "Video memes", value: 'videomeme' },
  ];
  const [items, setItem] = useState(dataarr);
  const [state, setState] = useState({
    dropdownOpen: false,
  });
  const breadcrumbData = {
    page_title: "Content Calendar",
    page_sub_title: "",
  };

  const toastoptions = {
    type: toast.TYPE.INFO,
    closeButton: <Close className="closeCusToast" />,
  };

  const handleClose = () => {
    setOpen(false);
    if (iframeData == 'save_success') {
      getScheduledSignlePost(iframeSelectedDate);
    } else {

    }
    setPostId("");
    setPublicId("");
    setRandomId("");
    setPublishTo("");
    setIframeSelectedDate("");
    setPostStatus("");
    getFilterCount()
  };

  const handlePublishClose = () => {
    setOpenPublish(false)
    dragDropedData.info.revert();
  }

  window.addEventListener('scroll', () => {

    document.body.classList.add('CCFooterhide')

    if (isOpen == true && jQuery('.content-month').hasClass('open')) {
      setIsOpen(false);
      setTimeout(() => {
        document.getElementById('one').classList.add("content-monthhidden")
      }, 2000);
    }
    // var current_positon = jQuery(window).scrollTop();
    // if(148 <= current_positon ){
    //   setScrollCalendarClassName('conCalcScroll');
    // }else{
    //   setScrollCalendarClassName('');
    // }

    // if (scrollCalendarClassName !== 'conCalcScroll ' && window.pageYOffset >= 50) {
    //   let height = window.innerHeight
    //   console.log("height", height)
    //   screenHeight(height)
    // }

    if (scrollCalendarClassName !== 'conCalcScroll ' && window.pageYOffset >= 151) {
      setScrollCalendarClassName('conCalcScroll');
      setCcContactPills(false)
    } else if (scrollCalendarClassName === 'conCalcScroll' && window.pageYOffset < 135) {
      setScrollCalendarClassName('');
      setCcContactPills(true)
    }
  });



  jQuery(".fc-scroller").scroll(function () {
    if (isOpen) {
      setIsOpen(false);
      setTimeout(() => {
        document.getElementById('one').classList.add("content-monthhidden")
      }, 2000);
    }
  });

  window.addEventListener('message', receiveMessage, false);
  function receiveMessage(evt) {
    setIframeData(evt.data)
  }
  // api integration

  let Flag = 0;

  useEffect(() => {

    // setDropMonth(moment(new Date()).format("MMM YYYY"))

    // document.body.classList.add('CCFooterhide')

    if (!Flag) {
      document.getElementById('one').classList.add("content-monthhidden")
      Flag = 1;
    }
    if (selectedItem.length >= 0) {
      getFilterCount()
      getScheduledSignlePost(today);
    }
    let height = document.getElementsByTagName('body')[0].clientHeight
    screenHeight(height)

  }, [selectedItem])

  useEffect(() => {
    if (ccContactPills) {
      document.body.classList.add('CCContactPills')
    } else {
      document.body.classList.remove('CCContactPills')
    }
  })
  const screenHeight = (height) => {
    let newHeight = height - 150
    setDayCalcHeight(newHeight)
  }


  const getFilterCount = () => {

    let filterdata = {
      action: 'get_all_scheduling_post_ajax',
      filter1: selectedItem[0],
      filter2: selectedItem[1],
      filter3: selectedItem[2],
      user_id: props.meta?.ic_id
    }
    setLoder(true)
    props.contentCalendarAction.getContentCalendar(filterdata)
      .then(res => {
        setLoder(false)
        setFilterEvents(res.data)
      })
      .catch(error => { console.log(error) });
  }

  const getScheduledSignlePost = (date) => {

    let postdata = {
      action: 'get_single_scheduling_post_ajax',
      filter1: selectedItem[0],
      filter2: selectedItem[1],
      filter3: selectedItem[2],
      user_id: props.meta?.ic_id,
      get_date: date
    }
    setLoder(true)

    props.contentCalendarAction.getContentCalendar(postdata)
      .then(res => {
        setLoder(false)
        if (res.data.length != 0) {
          res.data.scheduled_post.forEach(function (element) {
            element.start = moment(element.start).format('YYYY-MM-DD HH:mm:ss');
            element.allDay = false;
            element.display = 'block';
            element.title = "title test";
            if (element.post_status == 'future_post') {
              element.editable = true;
            }
            else {
              element.editable = false;
            }
          });
        }

        setScheduledPostEvents(res.data.scheduled_post)

      })
      .catch(error => { console.log(error) });


  }
  // 

  const handleEventClick = (clickInfo) => {
    setOpen(true);
    setData(clickInfo);
    setPostId(clickInfo.event._def.extendedProps.postid);
    setPublicId(clickInfo.event._def.publicId);
    setRandomId(clickInfo.event._def.extendedProps.random_id);
    setPublishTo(clickInfo.event._def.extendedProps.publish_to);
    setIframeSelectedDate(moment(clickInfo.view.currentStart).format("YYYY-MM-DD"))
    setPostStatus(clickInfo.event._def.extendedProps.post_status)
  };

  const handleMonthEventClick = (clickInfo) => {
    var eventDate = moment(clickInfo.event.start).format("YYYY-MM-DD")
    let calendarApi = calendarComponentRef.current.getApi();
    getScheduledSignlePost(moment(eventDate).format("YYYY-MM-DD"))
    calendarApi.gotoDate(moment(eventDate).format("YYYY-MM-DD"));

    setTimeout(function () {
      jQuery('body').find('.fc-daygrid-day-frame').removeClass('current_active_date');
    }, 50);
    setTimeout(function () {
      jQuery(clickInfo.el).closest('.fc-daygrid-day-frame').addClass('current_active_date');
    }, 50);
  };


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (isOpen == true) {
      setTimeout(() => {
        document.getElementById('one').classList.add("content-monthhidden")
      }, 2000);
    }
  }

  const toggle = () => {
    setState({ dropdownOpen: !state.dropdownOpen });
  };


  const toggleFilterCheckbox = (id) => {
    setSelectedCheck(id.checked);
    setSelectedItem([...selectedItem, id]);
    id.checked = !id.checked;
  };


  const handleEventContent = (eventInfo) => {
    return (
      <div class="mobile_calendar_meme_article_count">
        {
          eventInfo?.event?._def?.extendedProps?.meme_count > 0 &&
          <div class="meme_count relative">
            <p>{eventInfo?.event?._def?.extendedProps?.meme_count}</p>
          </div>
        }
        {
          eventInfo?.event?._def?.extendedProps?.article_count > 0 &&
          <div class="article_count relative">
            <p>{eventInfo?.event?._def?.extendedProps?.article_count}</p>
          </div>
        }
      </div>
    )
  };

  const handleFunction = (id) => {
    if (selectedItem.indexOf(id) !== -1) {
      let index = selectedItem.indexOf(id);
      let spliced = selectedItem.splice(index, 1);
      setSelectedItem([...selectedItem]);
    } else {
      setSelectedItem([...selectedItem, id]);
    }
  };
  const handleMobileEventContent = (eventInfo) => {
    let event = eventInfo?.event?._def?.extendedProps
    var ui_time = moment(eventInfo.event.extendedProps.scheduled_date_time, 'YYYY-MM-DD hh:mm A').format('ha');
    return (
      <>
        {/*  future post */}
        {event?.post_status === 'future_post' &&

          < div className={event?.schedule_type === 'schedule-article' ? 'schedule_posts sc-article' : 'schedule_posts'} data-ui_time={ui_time.slice(0, -1)}>
            <div className='Card_header pl-1 pr-1 d-flex justify-content-between'>
              {/* post status - future post */}
              <div id="timedrag" className="event_time"><b>{eventInfo.timeText}</b></div>
              {
                // twitter
                event?.publish_to === 'twitter' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={twitterBright} alt="social images" />
                  <span className='ml-2'>
                    {event?.twitter_profile_name}
                  </span>
                </div>
              }
              {
                // facebook
                (event?.publish_to === 'facebook_page' || event?.publish_to === 'facebook_profile') &&
                <div className='sc-social-img fb icon_name '>
                  <img src={facebookBright} alt="social images" />
                  <span className='ml-2'>
                    {event?.fb_page_name}
                  </span>
                </div>
              }
              {
                // linkedin
                event?.publish_to === 'linkedin' &&
                <div className='sc-social-img icon_name'>
                  <img src={linkedinIcon} alt="social images" />
                  <span className='ml-2'>
                    {event?.linkedin_profile_name}
                  </span>
                </div>
              }
              {
                // pinterest
                event?.publish_to === 'pinterest' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={pinInterestBright} alt="social images" />
                  <span className='ml-2'>
                    {event?.pn_board_name}
                  </span>
                </div>
              }
              {
                // instagram
                event?.publish_to === 'instagram' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={instagramBright} alt="social images" />
                  <span className='ml-2'>
                    {event?.instagram_profilename}
                  </span>
                </div>
              }
              <div className='Timeslot'>
                <span className="d-flex"><b className="mobile_post_time">{(event?.show_time).slice(0, -1)}</b></span>
              </div>
            </div>
            <div className='Card_content'>
              <div className="inside_card">
                <p>{event?.caption ? event?.caption : 'Add caption'}</p>
              </div>
            </div>
          </div>
        }

        {/* success post */}
        {
          event?.post_status === 'success_post' &&
          <div className={event?.schedule_type === 'schedule-article' ? 'schedule_posts sc-article successpost' : 'schedule_posts successpost'} data-ui_time={ui_time.slice(0, -1)}>
            <div className='Card_header pl-1 pr-1 d-flex justify-content-between'>
              {/* post status - future post */}

              {
                // twitter
                event?.publish_to === 'twitter' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={twitter} alt="social images" />
                  <span className='ml-2'>
                    {event?.twitter_profile_name}
                  </span>
                </div>
              }
              {
                // facebook
                (event?.publish_to === 'facebook_page' || event?.publish_to === 'facebook_profile') &&
                <div className='sc-social-img fb icon_name '>
                  <img src={facebook} alt="social images" />
                  <span className='ml-2'>
                    {event?.fb_page_name}
                  </span>
                </div>
              }
              {
                // linkedin
                event?.publish_to === 'linkedin' &&
                <div className='sc-social-img icon_name'>
                  <img src={linkedinLite} alt="social images" />
                  <span className='ml-2'>
                    {event?.linkedin_profile_name}
                  </span>
                </div>
              }
              {
                // pinterest
                event?.publish_to === 'pinterest' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={pinInterest} alt="social images" />
                  <span className='ml-2'>
                    {event?.pn_board_name}
                  </span>
                </div>
              }
              {
                // instagram
                event?.publish_to === 'instagram' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={instagram} alt="social images" />
                  <span className='ml-2'>
                    {event?.instagram_profilename}
                  </span>
                </div>
              }
              <div className='Timeslot'>
                <span className="d-flex"><b className="mobile_post_time">{(event?.show_time).slice(0, -1)}</b>
                  <span className="selectIcon">
                    <img className="success_icon" src={Successpost} alt='' />
                  </span></span>
              </div>
            </div>
            <div className='Card_content'>
              <div className="inside_card">
                <p>{event?.caption ? event?.caption : 'Published without caption'}</p>
              </div>
            </div>
          </div>
        }

        {/* failed post */}
        {
          event?.post_status === 'failed_post' &&
          <div className={event?.schedule_type === 'schedule-article' ? 'schedule_posts sc-article failedpost' : 'schedule_posts failed_post'} data-ui_time={ui_time.slice(0, -1)}>
            <div className='Card_header pl-1 pr-1 d-flex justify-content-between'>
              {
                // twitter
                event?.publish_to === 'twitter' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={twitter} alt="social images" />
                  <span className='ml-2'>
                    {event?.twitter_profile_name}
                  </span>
                </div>
              }
              {
                // facebook
                (event?.publish_to === 'facebook_page' || event?.publish_to === 'facebook_profile') &&
                <div className='sc-social-img fb icon_name '>
                  <img src={facebook} alt="social images" />
                  <span className='ml-2'>
                    {event?.fb_page_name}
                  </span>
                </div>
              }
              {
                // linkedin
                event?.publish_to === 'linkedin' &&
                <div className='sc-social-img icon_name'>
                  <img src={linkedinIcon} alt="social images" />
                  <span className='ml-2'>
                    {event?.linkedin_profile_name}
                  </span>
                </div>
              }
              {
                // pinterest
                event?.publish_to === 'pinterest' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={pinInterest} alt="social images" />
                  <span className='ml-2'>
                    {event?.pn_board_name}
                  </span>
                </div>
              }
              {
                // instagram
                event?.publish_to === 'instagram' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={instagram} alt="social images" />
                  <span className='ml-2'>
                    {event?.instagram_profilename}
                  </span>
                </div>
              }
              <div className='Timeslot'>
                <span className="d-flex"><b className="mobile_post_time">{(event?.show_time).slice(0, -1)}</b>
                  <span className="selectIcon">
                    <img className="fail_icon" src={Failpost} alt='' />
                    {/* <i class="fa fa-close" aria-hidden="true"></i> */}
                  </span></span>
              </div>
            </div>
            <div className='Card_content'>
              <div className="inside_card">
                <p>{event?.caption ? event?.caption : 'Add caption'}</p>
              </div>
            </div>
          </div>
        }

        {/* Failed future post */}

        {
          event?.post_status === 'future_failed_post' &&
          <div className={event?.schedule_type === 'schedule-article' ? 'schedule_posts sc-article' : 'schedule_posts'} data-ui_time={ui_time.slice(0, -1)}>
            <div className='Card_header pl-1 pr-1 d-flex justify-content-between'>
              {/* post status - future post */}

              {
                // twitter
                event?.publish_to === 'twitter' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={twitter} alt="social images" />
                  <span className='ml-2'>
                    {event?.twitter_profile_name}
                  </span>
                </div>
              }
              {
                // facebook
                (event?.publish_to === 'facebook_page' || event?.publish_to === 'facebook_profile') &&
                <div className='sc-social-img fb icon_name '>
                  <img src={facebookBright} alt="social images" />
                  <span className='ml-2'>
                    {event?.fb_page_name}
                  </span>
                </div>
              }
              {
                // linkedin
                event?.publish_to === 'linkedin' &&
                <div className='sc-social-img icon_name'>
                  <img src={linkedinIcon} alt="social images" />
                  <span className='ml-2'>
                    {event?.linkedin_profile_name}
                  </span>
                </div>
              }
              {
                // pinterest
                event?.publish_to === 'pinterest' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={pinInterestBright} alt="social images" />
                  <span className='ml-2'>
                    {event?.pn_board_name}
                  </span>
                </div>
              }
              {
                // instagram
                event?.publish_to === 'instagram' &&
                <div className='sc-social-img fb icon_name'>
                  <img src={instagramBright} alt="social images" />
                  <span className='ml-2'>
                    {event?.instagram_profilename}
                  </span>
                </div>
              }
              <div className='Timeslot'>
                <span className="d-flex"><b className="mobile_post_time">{(event?.show_time).slice(0, -1)}</b></span>
              </div>
            </div>
            <div className='Card_content'>
              <div className="inside_card">
                <p className='ml-2'>{event?.caption ? event?.caption : 'Add caption'}</p>
              </div>

              <div className="notifi_icon">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        }

        {/* article */}
      </>

    );
  };

  const handleSelectAllow = (selectionInfo) => {
    let startDate = selectionInfo.start;
    let endDate = selectionInfo.end;
    endDate.setSeconds(endDate.getSeconds() - 1);  // allow full day selection
    if (startDate.getDate() === endDate.getDate()) {
      return true;
    } else {
      return false;
    }
  }

  const handleHeaderContent = (views) => {
    setTimeDisplayDate(moment(views.date).format("DD"))
    setTimeDisplayDay(views.text)
  };

  const handleFirstHeaderContent = (views) => {
    setDropMonth(moment(views.date).subtract(1, 'months').endOf('month').format('MMM YYYY'))
  };


  const handleDateClick = (arg) => {
    let calendarApi = calendarComponentRef.current.getApi();
    calendarApi.gotoDate(arg.dateStr);
    // setDropMonth(moment(arg.dateStr).format("MMM YYYY"))
    getScheduledSignlePost(arg.dateStr);
    setTimeout(function () {
      jQuery('body').find('.fc-daygrid-day-frame').removeClass('current_active_date');
    }, 50);
    setTimeout(function () {
      jQuery(arg.dayEl).find('.fc-daygrid-day-frame').addClass('current_active_date');
    }, 50);
  }

  const prev = () => {
    setScheduledPostEvents([])
    let calendarApi = calendarComponentRef.current.getApi();
    calendarApi.prev();
    let prevDate = moment(calendarApi.getDate()).format("YYYY-MM-DD")
    getScheduledSignlePost(prevDate);
    setPrevActive(true)
    setNextActive(false)
    setTimeout(function () {
      setPrevActive(false);
    }, 300);
  }

  const next = () => {
    // console.log("first",scheduledPostEvents?.length)
    setScheduledPostEvents([])
    let calendarApi = calendarComponentRef.current.getApi();
    console.log("Second", calendarApi.getEvents()?.length)
    calendarApi.getEvents().forEach(event => {
      console.log("eve", event)
      event.remove()
    })
    console.log("third", calendarApi.getEvents()?.length)
    calendarApi.next();
    let nextDate = moment(calendarApi.getDate()).format("YYYY-MM-DD")
    getScheduledSignlePost(nextDate);
    setPrevActive(false)
    setNextActive(true)
    setTimeout(function () {
      setNextActive(false);
    }, 500);
  }

  const handleEventCard = (arg) => {
    setTimeout(function () {
      mobilePostUiChange();
    }, 500);
  }

  const mobilePostUiChange = () => {
    var mobile_time_array = ['1a', '2a', '3a', '4a', '5a', '6a', '7a', '8a', '9a', '10a', '11a', '12a', '1p', '2p', '3p', '4p', '5p', '6p', '7p', '8p', '9p', '10p', '11p', '12p'];
    jQuery(mobile_time_array).each(function (i) {
      var len = jQuery('.fc-timegrid-event-harness').find('.schedule_posts[data-ui_time="' + mobile_time_array[i] + '"]').length;
      if (len > 4) {
        jQuery('.schedule_posts[data-ui_time="' + mobile_time_array[i] + '"]').addClass('shrink_mobile_ui')
      }
    });
  }

  const handleEventDrop = (info) => {
    var drop_time_utc = moment(info.event.start).utc().format('hh:mm a');
    var drop_Date = moment(info.event.start).format('MM-DD-YYYY');
    var drop_time = moment(info.event.start).format('hh:mm a');
    var post_type = 'meme';
    if (info.event.extendedProps.schedule_type == "schedule-article") {
      post_type = 'article'
    }

    var droped_data = {
      date: drop_Date,
      drop_time: drop_time,
      drop_time_utc: drop_time_utc,
      schedule_id: info.event.id,
      post_id: info.event.extendedProps.postid,
      publish_to: info.event.extendedProps.publish_to,
      random_id: info.event.extendedProps.random_id,
      timezone: info.event.extendedProps.timezone,
      caption: info.event.extendedProps.caption,
      post_media: info.event.extendedProps.post_media,
      post_type: post_type,
      info: info
    }
    jQuery('body').find('.fc-timegrid-event-harness').removeClass('current_drag_active');
    setDragDropedData(droped_data)
    dragdropPostCheck(droped_data, info)
  }

  const dragdropPostCheck = (droped_data, info) => {
    document.body.classList.add('Hiddencard')

    let data = {
      action: 'drag_drop_post_check',
      date: droped_data.date,
      time: droped_data.drop_time,
      timezone: droped_data.timezone,
      random_id: droped_data.random_id
    }
    setLoder(true)
    props.contentCalendarAction.getContentCalendar(data)
      .then(res => {
        setLoder(false)
        if (res.data == 'valid') {
          setDragsave(true)
          dragdropPostData(droped_data, info)
          let uitime = moment(info.event.start).format('h:mma')
          jQuery(info.el).find('.mobile_post_time').html(uitime.slice(0, -1))
        }
        else if (res.data == 'Past_time') {

          document.body.classList.remove('Hiddencard')
          toast('You cannot schedule a post in the past', toastoptions)
          info.revert();
        }
        else if (res.data == 'publish_post') {
          document.body.classList.remove('Hiddencard')
          setOpenPublish(true)
        }
      })
      .catch(error => { console.log(error) });
  }

  const dragdropPostData = (droped_data, info) => {
    let data = {
      action: 'save_post_data',
      type: 'drag_drop',
      date: droped_data.date,
      time: droped_data.drop_time,
      timezone: droped_data.timezone,
      schedule_id: droped_data.schedule_id,
      post_id: droped_data.post_id,
      random_id: droped_data.random_id,
      publish_to: droped_data.publish_to,
      device: 'mobile',
      user_id: props.meta?.ic_id,
    }
    setLoder(true)
    props.contentCalendarAction.getContentCalendar(data)
      .then(res => {
        setLoder(false)
        document.body.classList.remove('Hiddencard')
        setDragsave(false)

        if (res.data) {
          if (res.data == 'caption_error') {
            toast("Looks like you've already used that caption for a post you previously published. Unfortunately Linkedin and Twitter don't allow duplicate captions when publishing outside their platforms.", toastoptions)
            info.revert();
          }
          else {
            let selecteddate = moment(info.event.start).format('YYYY-MM-DD')
            getScheduledSignlePost(selecteddate);
          }
        }
      })
      .catch(error => { console.log(error) });
  }

  const publishNow = () => {
    let data = {
      action: 'post_publish_now',
      schedule_id: dragDropedData.schedule_id,
      post_id: dragDropedData.post_id,
      random_id: dragDropedData.random_id,
      user_id: props.meta?.ic_id,
      caption: dragDropedData.caption,
      post_media: dragDropedData.publish_to,
      instagram_id: dragDropedData.publish_to == 'instagram' ? dragDropedData.instagram_user_id : ''
    }
    setLoder(true)
    props.contentCalendarAction.getContentCalendar(data)
      .then(res => {
        setLoder(false)
        if (res) {
          if (res == 'caption_error') {
            toast("Looks like you've already used that caption for a post you previously published. Unfortunately Linkedin and Twitter don't allow duplicate captions when publishing outside their platforms.", toastoptions);
            dragDropedData.info.revert();
          }
          else {
            setOpenPublishSuccess(true)
          }
        }
        setOpenPublish(false)
      })
      .catch(error => { console.log(error) });
  }

  return (
    <div className="Content-Calc">
      <Breadcrumb dealsList={breadcrumbData} />
      {dragsave && <Dotloder />}
      <IframeModal
        isOpen={open}
        handleClose={handleClose}
        postid={postid}
        publicId={publicId}
        randomId={randomId}
        publishTo={publishTo}
        iframeData={iframeData}
        postStatus={postStatus}
      />
      <PublishnowModal isOpen={openpublish} handleClose={handlePublishClose} handlePublishNow={publishNow} />
      <OutsideClickHandler onOutsideClick={() => { setOpenPublishSuccess(false) }}>
        <PublishSuccessModal isOpen={openPublishSuccess} data={dragDropedData.post_type} />
      </OutsideClickHandler>
      <div className={`content-calendar ${scrollCalendarClassName} ${isOpen && "open"}`}>
        <div className="month-content-calendar">
          <div className="filter-month d-flex justify-content-between align-self-center p-2">
            <div className="Left-filter d-flex justify-content-start align-self-center" onClick={toggleDropdown}>
              <img className="align-self-center ml-1 mr-1" src={calendarIcon} width={15} height={16} alt="calendar icon" />
              <h5 className="m-0">{dropMonth}</h5>
              <i className={`align-self-center ml-1 fa fa-chevron-up icon ${isOpen && "open"}`}></i>
            </div>
            <div className="right-filter d-flex justify-content-start align-self-center">
              <ButtonDropdown isOpen={state.dropdownOpen} toggle={toggle}>
                <DropdownToggle className="display-filter align-self-center" caret size="sm" outline>
                  Filter
                </DropdownToggle>
                <DropdownMenu>
                  {items.map((item) => (
                    <div className="dropdown-item" onClick={() => handleFunction(item.value)}>
                      <div>
                        {item.label}
                        <span>
                          <input
                            type="checkbox"
                            onChange={(e) => toggleFilterCheckbox(e.target.id)}
                            id={item.id}
                            className="fil-checkbox"
                            checked={selectedCheck}
                          />
                        </span>
                        <span
                          className={`dropdown-item-dot ${selectedItem.indexOf(item.value) !== -1 ? "selected" : ""
                            }`}
                        >
                          <i
                            class="fa fa-check select-tick"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  ))}
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </div>
          <div className={`curs-point content-month ${isOpen && "open"}`} id="one">
            <div className="text-center month-calc">
              <div className="c-meme">
                <div className="mobile_calendar_meme_info">
                  <p>Memes</p>
                </div>
                <div className="mobile_calendar_article_info">
                  <p>Articles</p>
                </div>
              </div>
              <FullCalendar
                initialView="dayGridMonth"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "prev",
                  right: "next",
                }}
                contentHeight="auto"
                events={filterEvents}
                eventClick={handleMonthEventClick}
                eventContent={handleEventContent}
                dayCellContent={handleFirstHeaderContent}
                dateClick={handleDateClick}
                selectable={true}
                selectAllow={handleSelectAllow}
                longPressDelay={0}
                selectLongPressDelay={0}
                eventLongPressDelay={0}
                weekends={true}
                dayHeaderFormat={{ weekday: 'narrow' }}
              />
            </div>
          </div>
          <div class="mobile_day_view_header">
            <div class="mobile_day_view_prev" onClick={prev}></div>
            <div class="mobile_day_view_title">
              <div class={`mobile_day_view_title_wrapper previous_date  ${prevActive && 'preactive'} ${nextActive && 'nextactive'}`}>
                <p class="today_day_name">{timeDisplayDay}</p>
                <p class="today_date">{timeDisplayDate}</p>
              </div>
            </div>
            <div class="mobile_day_view_next" onClick={next}></div>
          </div>
        </div>
        <div className="text-center time-calc">
          <FullCalendar
            initialView="timeGridDay"
            plugins={[timeGridPlugin, interactionPlugin]}
            headerToolbar={false}
            height={dayCalcHeight}
            events={scheduledPostEvents}
            allDaySlot={false}
            contentHeight={dayCalcHeight}
            // eventClick={handleEventClick}
            eventContent={handleMobileEventContent}
            dayHeaderContent={handleHeaderContent}
            initialDate={gotoDay}
            ref={calendarComponentRef}
            slotEventOverlap={false}
            slotDuration='00:15:00'
            slotLabelInterval='01:00'
            selectMirror={false}
            editable={false}
            selectable={false}
            eventOverlap={true}
            displayEventTime={true}
            eventMinHeight={60}
            slotMinWidth={70}
            eventDurationEditable={false}
            eventDidMount={handleEventCard}
            longPressDelay={0}
            selectLongPressDelay={0}
            eventLongPressDelay={400}
            eventDrop={handleEventDrop}
            scrollTime={moment().format("HH:mm:ss")}
          // nextDayThreshold ='00:00:00'
          />
        </div>
      </div>
    </div>

  );
};

function mapStateToProps(state, ownProps) {
  return {
    meta: state.blogs
  };
}
function mapDispatchToProps(dispatch) {
  return {
    contentCalendarAction: bindActionCreators(contentCalendarAction, dispatch),
    dealsAction: bindActionCreators(dealsAction, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContentCalendar);
