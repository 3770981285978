import axios from 'axios';
import delay from './Delay';
import Base from './Base.json';
const url = Base.baseUrl + Base.version + Base.user;

export const postMethod = async (data) => {
    const config = { headers: { 'content-type': 'application/json' } }
    return new Promise((resolve, reject) => {
        axios.post( url + data.url, data.body, config)
            .then(res => {
                setTimeout(() => {
                    resolve(Object.assign([], res));
                }, delay)
            }).catch(error => {
                resolve(error);
            })
    });
}

export const postFormdataMethod = async (data) => {
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    return new Promise((resolve, reject) => {
        axios.post(url + data.url, data.body, config)
            .then(res => {
                setTimeout(() => {
                    resolve(Object.assign([], res));
                }, delay)
            }).catch(error => {
                resolve(error);
            })
    });
}

