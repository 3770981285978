import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import Switch from "react-switch";
import AddIcon from "@mui/icons-material/Add";
import { inputClasses } from "@mui/material";
import { postFormdataMethod } from '../../../_Config/Api'

const EmailNotification = (props) => {
  const Ic_id = props.data
  const [addEmail, setAddEmail] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [state, setState] = useState({
    toggle1: false,
    toggle2: false,
    toggle3: false,
    toggle4: false,
    toggle5: false,
    toggle6: false,
    primary_email: "",
    ic_assist_email: "",
    onboard_link: ""
  });
  const [inputs, setInputs] = useState({});
  const style = {
    largeIcon: {
      width: 60,
      height: 60,
    },
  };

  useEffect(() => {
    getEmailData();
  }, [])
  const getEmailData = async () => {
    const data = {
      url: "ic_react_api_page_info.php",
      body: {
        user_id: Ic_id,
        slug: 'email-subscription'
      },
    };
    let newData = await postFormdataMethod(data)
    let res = newData.data.page_info

    if (res) {
      setState({
        ...state,
        ic_assist_email: res.ic_assist_email,
        toggle1: res.primary_general_status == 1 ? true : false,
        toggle2: res.primary_weekly_status == 1 ? true : false,
        toggle3: res.primary_onboard_status == 1 ? true : false,
        toggle4: res.assist_general_status == 1 ? true : false,
        toggle5: res.assist_weekly_status == 1 ? true : false,
        toggle6: res.assist_onboard_status == 1 ? true : false,
        primary_email: res.primary_email,
        onboard_link: res.view_onboard_email_link
      })
    }
  }

  const toggleStatusData = async (clickInfo) => {
    const data = {
      url: "ic_react_email_subscription_ajax_functions.php",
      body: {
        action: 'toggle_status_change_user',
        user_id: Ic_id,
        tag_id: clickInfo.id,
        toggle_status: clickInfo.status==true ? 'Yes' : 'No',
        user_type: clickInfo.type
      },
    };
    let newData = await postFormdataMethod(data)
    let res = newData.data.status
    if(res){
      getEmailData()
    }
  }

  const handleChange = (checked, even, id) => {
    let user_data ={type:"currentuser", id:id,status:checked}
    if (id == 29) {
      setState({ ...state, toggle1: !state.toggle1 })
      toggleStatusData(user_data)
    } else if (id == 31) {
      setState({ ...state, toggle2: !state.toggle2 })
      toggleStatusData(user_data)
    } else if (id == 30) {
      setState({ ...state, toggle3: !state.toggle3 })
      toggleStatusData(user_data)
    }
  }

  const assistChange = (checked, even, id) => {
    let user_data ={type:"assistuser", id:id,status:checked}
    if (id == 29) {
      setState({ ...state, toggle4: !state.toggle4 })
      toggleStatusData(user_data)
    } else if (id == 31) {
      setState({ ...state, toggle5: !state.toggle5 })
      toggleStatusData(user_data)
    } else if (id == 30) {
      setState({ ...state, toggle6: !state.toggle6 })
      toggleStatusData(user_data)
    }
  }

  const inputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }
  const handleSubmit = (event) => {
    event.preventDefault();
  }

  return (
    <div>
      {/* Email notification design starts here */}
      <div className="emailNotification">
        <h2 className="en-main-title">Email Notifications</h2>
        <p className="en-desc">
          Use the settings below to manage email communications you receive from
          us concerning your membership. If you have any questions, please{" "}
          <a href="">contact our support team</a>.
        </p>

        <div className="en-blockone email-Box">
          <Form>
            <FormGroup className="email-Fromgroup">
              <Label className="email-Primary">Primary account email</Label>
              {editEmail ? (
                <div className="email-editDiv">
                  <div className="email-textEmail">
                    <Input
                      type="email"
                      className="email-inputField"
                      id="primary_email"
                      name="primary_email"
                      // placeholder={emaildata.primary_email}
                      value={inputs.primary_email || state.primary_email}
                      onChange={inputChange}
                    />
                    <Button
                      className="email-EditButton"
                      onClick={handleSubmit}
                    >
                      Save
                    </Button>
                  </div>
                  <span className="email-Updated"></span>
                </div>
              ) : (
                <div className="email-Editwrapper">
                  <span className="InitialEmail">
                    {state.primary_email}
                  </span>
                  <Button
                    className="email-FromButton"
                    onClick={() => setEditEmail(true)}
                  >
                    Edit
                  </Button>
                </div>
              )}
            </FormGroup>
            <div className="email-spliter"></div>
            <FormGroup className="email-Fromgroup-switchButton">
              <div className="email-switchDiv">
                <div className="email-switchWrapper">
                  <div>
                    <Switch
                      id="29"
                      // width={55}
                      // height={23}
                      checked={state.toggle1}
                      onChange={handleChange}
                      uncheckedIcon="OFF"
                      checkedIcon="ON"
                      onColor='#87d23a'
                      offColor="#a6a6a6"
                    />
                  </div>
                  {/*  */}
                  <div className="email-SwitchText">
                    <p> General Announcement Emails</p>
                  </div>
                </div>
                {/*  */}
                <div className="email-switchWrapper">
                  <div>
                    <Switch
                      id="31"
                      checked={state.toggle2}
                      onChange={handleChange}
                      uncheckedIcon="OFF"
                      checkedIcon="ON"
                      onColor='#87d23a'
                      offColor="#a6a6a6"
                    />
                  </div>
                  {/*  */}
                  <div className="email-SwitchText">
                    <p> Weekly Round-up Emails</p>
                  </div>
                </div>
                <div className="email-switchWrapper">
                  <div>
                    <Switch
                      id="30"
                      checked={state.toggle3}
                      onChange={handleChange}
                      uncheckedIcon="OFF"
                      checkedIcon="ON"
                      onColor='#87d23a'
                      offColor="#a6a6a6"
                    />
                  </div>
                  {/*  */}
                  <div className="email-SwitchText">
                    <p>
                      30-Day Onboarding Sequence
                      <a href={state.onboard_link} className="email-viewAll">
                        View all onboarding emails
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </FormGroup>
          </Form>
        </div>

        {state.ic_assist_email &&
          <div className="en-blockone email-Box">
            <Form>
              <FormGroup className="email-Fromgroup">
                <Label className="email-Primary">Secondary email (e.g., for an assistant) </Label>
                {editEmail ? (
                  <div className="email-editDiv">
                    <div className="email-textEmail">
                      <Input
                        type="email"
                        className="email-inputField"
                        id="primary_email"
                        name="primary_email"
                        // placeholder={emaildata.primary_email}
                        value={inputs.primary_email || state.primary_email}
                        onChange={inputChange}
                      />
                      <Button
                        className="email-EditButton"
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    </div>
                    <span className="email-Updated"></span>
                  </div>
                ) : (
                  <div className="email-Editwrapper">
                    <span className="InitialEmail">
                      {state.ic_assist_email}
                    </span>
                    <Button
                      className="email-FromButton"
                      onClick={() => setEditEmail(true)}
                    >
                      Edit
                    </Button>

                    <Button
                      onClick={() => setEditEmail(true)}
                    >
                      Remove
                    </Button>
                  </div>
                )}
              </FormGroup>
              <div className="email-spliter"></div>
              <FormGroup className="email-Fromgroup-switchButton">
                <div className="email-switchDiv">
                  <div className="email-switchWrapper">
                    <div>
                      <Switch
                        id="29"
                        // width={55}
                        // height={23}
                        checked={state.toggle4}
                        onChange={assistChange}
                        uncheckedIcon="OFF"
                        checkedIcon="ON"
                        onColor='#87d23a'
                        offColor="#a6a6a6"
                      />
                    </div>
                    {/*  */}
                    <div className="email-SwitchText">
                      <p> General Announcement Emails</p>
                    </div>
                  </div>
                  {/*  */}
                  <div className="email-switchWrapper">
                    <div>
                      <Switch
                        id="31"
                        checked={state.toggle5}
                        onChange={assistChange}
                        uncheckedIcon="OFF"
                        checkedIcon="ON"
                        onColor='#87d23a'
                        offColor="#a6a6a6"
                      />
                    </div>
                    {/*  */}
                    <div className="email-SwitchText">
                      <p> Weekly Round-up Emails</p>
                    </div>
                  </div>
                  <div className="email-switchWrapper">
                    <div>
                      <Switch
                        id="30"
                        checked={state.toggle6}
                        onChange={assistChange}
                        uncheckedIcon="OFF"
                        checkedIcon="ON"
                        onColor='#87d23a'
                        offColor="#a6a6a6"
                      />
                    </div>
                    {/*  */}
                    <div className="email-SwitchText">
                      <p>
                        30-Day Onboarding Sequence
                        <a href={state.onboard_link} className="email-viewAll">
                          View all onboarding emails
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Form>
          </div>}
        {state.ic_assist_email == "" && <>
          {addEmail ? (
            <div className="email-secondary">
              <Label className="email-Primary">
                Secondary email
                <span className="SpanText-email">(e.g., for an assistant)</span>
              </Label>
              <div className="email-textField">
                <Input
                  type="email"
                  className="email-inputField"
                  id="ic_assist_email"
                  name="ic_assist_email"
                  // placeholder={emaildata.primary_email}
                  value={inputs.ic_assist_email || state.ic_assist_email}
                  onChange={inputChange}
                />
                <Button className="email-AddButton" onClick={handleSubmit}>Add</Button>
              </div>
              <span className="error"></span>
            </div>
          ) : (
            <div className="email-AddEmail" onClick={() => setAddEmail(true)}>
              <div className="add-plusIcon">
                <AddIcon iconStyle={style.largeIcon} fontSizeLarge />
              </div>
              <div className="email-AddText">
                <b>Add another email</b>
                <span className="SpanText-email"> (e.g., for an assistant)</span>
              </div>
            </div>
          )}</>}
      </div>
      {/* Email notification design ends here */}
    </div>
  );
};

export default EmailNotification;
