import React, { Component } from "react";
import HelpCenterQuestion from "./HelpCenterQuestion";
import HelpCenterTopicChoose from "./HelpCenterTopicChoose";
import { Row, Col, Container } from "reactstrap";
import Breadcrumb from '../../Common/Breadcrump/Breadcrumb';

export class HelpWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      breadcrumbData: {
        page_title: 'Help Center',
        page_sub_title: 'Welcome to Inner Circle support. Here you will everything you need to understand how the Inner Circle works. Please feel free to contact us if you can’t find what you are looking for.'
      }
    }
  }

  render() {
    return (
      <div>
        <Breadcrumb dealsList={this.state.breadcrumbData} />
        <div /*className="helpCenter-wrapper"*/ className="help-wrapper">
          {/* <Container>
          <Row>
            <Col sm="12" lg="8" className="help-topic help-TopicChoose">
              <HelpCenterTopicChoose />
            </Col>
            <Col lg="4" sm="12" className="help-Question">
              <HelpCenterQuestion />
            </Col>
          </Row>
        </Container> */}

          <div className="help-TopicChoose">
            <HelpCenterTopicChoose />
          </div>
          <div className="help-Question">
            <HelpCenterQuestion />
          </div>

        </div>
      </div>
    );
  }
}

export default HelpWrapper;
