export const meta={
    "ic_id": "11393",
    "ic_user_token": "ZGDJCTdjvacVbIzkESkh",
    "ic_user_membership_level": 2,
    "ic_user_membership_status": 1,
    "ic_breb_domain": "brebspacing",
    "ic_breb_domain_edit_count": "0",
    "ic_breb_domain_edit_status": null,
    "ic_view_myblog_link": "https:\/\/brebspacing.devbreb.com",
    "ic_user_profile_pic": "https:\/\/s3.amazonaws.com\/icdevcdn.lightersideofrealestate.com\/uploads\/profile_pic_temp_path\/re_sized\/12700_photo_1652160828.jpg",
    "ic_user_display_header_search": "hide",
    "ic_breb_popup_link": "https://ic-dev.lightersideofrealestate.com/manage-subscription#billing-and-usage",
    "ic_breb_reactivate_link": "https://ic-dev.lightersideofrealestate.com/manage-subscription#billing-and-usage?reactivate",
    "ic_paused_popup_content": " <p>Your account has been paused and will reactivate on '01/01/1970' , Until then you can't access this content</p> <p> Reactivate your membership below to get access<p>",
    "ic_plus_basic_popup_content": "<p>You're subscriped to our 'Plus' Plan, Which only gives you accessto our Memes, Articles and Facebook Group only.</p> <p>To unlock this piece of content (and lots more like it), you'll need to upgrade your membership.</p>",
    "ic_site_url": "https:\/\/ic-dev.lightersideofrealestate.com",
    "ic_user_first_name": "breb",
    "ic_user_last_name": "spacing",
    "ic_user_email": "brebspacing441@gmnail.com",
    "ic_user_backend_link": "https:\/\/ic-dev.lightersideofrealestate.com\/wp-admin\/admin.php?page=manage_members&module=details_general&user_id=12700",
    "ic_support_center_link": "https:\/\/lightersideofrealestate.freshdesk.com\/support\/solutions",
    "ic_vendor_deals_from_email": "helpdesk@lightersideofrealestate.com",
    "ic_vendor_deals_to_email": "devteam@lightersideofrealestate.com",
    "ic_vendor_deals_subject_text": "Inner Circle Contact Form",
    "ic_display_service_icon" : "yes",
    "header": {
        "header_logo": "https:\/\/icdevcdn.lightersideofrealestate.com\/uploads\/2015\/12\/innercircle-logo-top.png"
    },
    "menus": {
        "header_profile_menu": [
            {
                "child": true,
                "id": 58785,
                "title": "My Profile",
                "link": "https://ic-dev.lightersideofrealestate.com#",
                "class": [
                    "menu_hide_show",
                    "parent_filter",
                    "mobile_manage_profile"
                ],
                "children": [
                    {
                        "child": false,
                        "id": 58793,
                        "title": "Social Accounts",
                        "link": "https://ic-dev.lightersideofrealestate.com/manage-profile#social-accounts",
                        "parentId": "58785",
                        "class": [
                            "profile_child_filter"
                        ]
                    },
                    {
                        "child": false,
                        "id": 58789,
                        "title": "Meme Branding",
                        "link": "https://ic-dev.lightersideofrealestate.com#",
                        "parentId": "58785",
                        "class": [
                            "child_filter",
                            "",
                            "meme_child_filter",
                            "meme_branding_toggle"
                        ],
                        "subchildren": [
                            {
                                "child": true,
                                "id": 58790,
                                "title": "Watermark",
                                "link": "https://ic-dev.lightersideofrealestate.com/manage-profile#watermark",
                                "class": [
                                    ""
                                ],
                                "parentID": "58785"
                            },
                            {
                                "child": true,
                                "id": 58791,
                                "title": "Branding Bar",
                                "link": "https://ic-dev.lightersideofrealestate.com/manage-profile#meme-branding-bar",
                                "class": [
                                    "meme_manage"
                                ],
                                "parentID": "58785"
                            }
                        ]
                    },
                    {
                        "child": false,
                        "id": 89221,
                        "title": "Article Branding",
                        "link": "https://ic-dev.lightersideofrealestate.com/manage-profile#article-branding",
                        "parentId": "58785",
                        "class": [
                            "article_branding_manage"
                        ]
                    },
                    {
                        "child": false,
                        "id": 58792,
                        "title": "Blog Settings",
                        "link": "https://ic-dev.lightersideofrealestate.com/manage-profile#blog-settings",
                        "parentId": "58785",
                        "class": [
                            "profile_child_filter"
                        ]
                    }
                ]
            },
            {
                "child": true,
                "id": 78964,
                "title": "My Subscription",
                "link": "https://ic-dev.lightersideofrealestate.com#",
                "class": [
                    "parent_filter",
                    "mobile_manage_profile",
                    "mobile_manage_subscription"
                ],
                "children": [
                    {
                        "child": false,
                        "id": 78965,
                        "title": "Account Details",
                        "link": "https://ic-dev.lightersideofrealestate.com/manage-subscription/account-details",
                        "parentId": "78964",
                        "class": [
                            "dual_menu_click1",
                            "mobile_manage_account_details"
                        ]
                    },
                    {
                        "child": false,
                        "id": 82072,
                        "title": "Billing & Usage",
                        "link": "https://ic-dev.lightersideofrealestate.com/manage-subscription/billing-and-usage",
                        "parentId": "78964",
                        "class": [
                            "dual_menu_click1",
                            "mobile_manage_billing_usage",
                            "ic_new_freemium_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 78968,
                        "title": "Email Notifications",
                        "link": "https://ic-dev.lightersideofrealestate.com/manage-subscription/email-subscription",
                        "parentId": "78964",
                        "class": [
                            "dual_menu_click1",
                            "mobile_manage_email_subscriptions"
                        ]
                    }
                ]
            },
            {
                "child": false,
                "id": 89780,
                "title": "Support Center",
                "link": "https://lightersideofrealestate.freshdesk.com/support/solutions",
                "class": [
                    "mobile_support_center",
                    "",
                    "custom_support_center"
                ]
            },
            {
                "child": false,
                "id": 58797,
                "title": "Contact Us",
                "link": "https://ic-dev.lightersideofrealestate.com/contact",
                "class": [
                    "mobile_contactus"
                ]
            }
        ],
        "top_main_bar": [
            {
                "child": false,
                "id": 20194,
                "title": "Dashboard",
                "link": "https:\/\/ic-dev.lightersideofrealestate.com\/dashboard",
                "class": [
                    "basic_hide_menu",
                    "pause_hide_menu"
                ]
            },
            {
                "child": true,
                "id": 82162,
                "title": "Content",
                "link": "https:\/\/ic-dev.lightersideofrealestate.com\/content",
                "class": [
                    "unclickable",
                    "basic_hide_menu",
                    "pause_hide_menu"
                ],
                "children": [
                    {
                        "child": false,
                        "id": 69028,
                        "title": "Articles",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/search?mode=b&type=Articles&filter=most_recent",
                        "parentId": "82162",
                        "class": [
                            "site_search_article",
                            "search_article_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 69029,
                        "title": "Memes",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/search?mode=b&type=Memes&filter=featured",
                        "parentId": "82162",
                        "class": [
                            "site_search_meme",
                            "search_meme_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 69030,
                        "title": "Videos",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/search?mode=b&type=Videos&filter=featured",
                        "parentId": "82162",
                        "class": [
                            "site_search_video",
                            "search_video_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 38050,
                        "title": "Branded Booklets",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/branded-booklets",
                        "parentId": "82162",
                        "class": [
                            "plus_hide_menu",
                            "basic_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 25342,
                        "title": "Email & Letter Templates",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/emails-letters",
                        "parentId": "82162",
                        "class": [
                            "plus_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 82849,
                        "title": "Witty Postcards",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/search?mode=b&type=Witty%20Postcards&filter=most_recent",
                        "parentId": "82162",
                        "class": [
                            "plus_hide_menu",
                            "site_search_video",
                            "search_video_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 82850,
                        "title": "Facebook Covers",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/search?mode=b&type=Facebook%20Covers&filter=most_recent",
                        "parentId": "82162",
                        "class": [
                            "plus_hide_menu",
                            "site_search_video",
                            "search_video_menu"
                        ]
                    }
                ]
            },
            {
                "child": true,
                "id": 82165,
                "title": "Resources",
                "link": "https:\/\/ic-dev.lightersideofrealestate.com\/resources",
                "class": [
                    "unclickable",
                    "basic_hide_menu",
                    "pause_hide_menu"
                ],
                "children": [
                    {
                        "child": false,
                        "id": 28333,
                        "title": "Inner Circle Engage",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/ic-engage",
                        "parentId": "82165",
                        "class": [
                            "unclickable"
                        ],
                        "subchildren": [
                            {
                                "child": true,
                                "id": 28332,
                                "title": "Go To The Group",
                                "link": "https:\/\/ic-dev.lightersideofrealestate.com\/ic-engage",
                                "class": [
                                    ""
                                ],
                                "parentID": "82165"
                            },
                            {
                                "child": true,
                                "id": 28331,
                                "title": "View Group Files",
                                "link": "https:\/\/ic-dev.lightersideofrealestate.com\/ic-engage-files",
                                "class": [
                                    "plus_hide_menu"
                                ],
                                "parentID": "82165"
                            }
                        ]
                    },
                    {
                        "child": false,
                        "id": 37513,
                        "title": "Agent Scripts",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/agent-scripts",
                        "parentId": "82165",
                        "class": [
                            "plus_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 27447,
                        "title": "Training Videos",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/training-videos",
                        "parentId": "82165",
                        "class": [
                            "plus_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 67795,
                        "title": "Weekly Round-ups",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/round-up-archive",
                        "parentId": "82165",
                        "class": [
                            ""
                        ]
                    },
                    {
                        "child": false,
                        "id": 85156,
                        "title": "Vendor Deals",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/support\/app\/deals",
                        "parentId": "82165",
                        "class": [
                            "plus_hide_menu"
                        ]
                    }
                ]
            }
        ],
        "mobile_footer": [
            {
                "child": true,
                "id": 58799,
                "title": "Share",
                "link": "https:\/\/ic-dev.lightersideofrealestate.com#",
                "class": [
                    "mobile_sharable_list",
                    "basic_hide_menu",
                    "",
                    "pause_hide_menu"
                ],
                "children": [
                    {
                        "child": false,
                        "id": 58811,
                        "title": "Articles",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/?page_id=28040",
                        "parentId": "58799",
                        "class": [
                            "default_article"
                        ]
                    },
                    {
                        "child": false,
                        "id": 58813,
                        "title": "Memes",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/?page_id=15128",
                        "parentId": "58799",
                        "class": [
                            "default_meme"
                        ]
                    },
                    {
                        "child": false,
                        "id": 58815,
                        "title": "Videos",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/share-a-video",
                        "parentId": "58799",
                        "class": [
                            "default_video"
                        ]
                    },
                    {
                        "child": false,
                        "id": 69031,
                        "title": "Articles",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/search?mode=b&type=Articles&filter=most_recent",
                        "parentId": "58799",
                        "class": [
                            "site_search_article",
                            "search_article_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 69032,
                        "title": "Memes",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/search?mode=b&type=Memes&filter=most_recent",
                        "parentId": "58799",
                        "class": [
                            "site_search_meme",
                            "search_meme_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 69033,
                        "title": "Videos",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/search?mode=b&type=Videos&filter=most_recent",
                        "parentId": "58799",
                        "class": [
                            "site_search_video",
                            "search_video_menu"
                        ]
                    }
                ]
            },
            {
                "child": true,
                "id": 58800,
                "title": "Resources",
                "link": "https:\/\/ic-dev.lightersideofrealestate.com#",
                "class": [
                    "mobile_resource_list",
                    "basic_hide_menu",
                    "",
                    "pause_hide_menu"
                ],
                "children": [
                    {
                        "child": false,
                        "id": 58804,
                        "title": "Branded Booklets",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/branded-booklets",
                        "parentId": "58800",
                        "class": [
                            "plus_hide_menu",
                            "basic_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 58805,
                        "title": "Email & Letter Templates",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/emails-letters",
                        "parentId": "58800",
                        "class": [
                            "plus_hide_menu",
                            "basic_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 58810,
                        "title": "Agent Scripts",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/agent-scripts",
                        "parentId": "58800",
                        "class": [
                            "plus_hide_menu",
                            "basic_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 58806,
                        "title": "Facebook Covers",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/facebook-covers",
                        "parentId": "58800",
                        "class": [
                            "basic_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 58808,
                        "title": "Training Videos",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/training-videos",
                        "parentId": "58800",
                        "class": [
                            "plus_hide_menu",
                            "basic_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 58809,
                        "title": "Vendor Deals",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/deals",
                        "parentId": "58800",
                        "class": [
                            "plus_hide_menu",
                            "basic_hide_menu"
                        ]
                    }
                ]
            },
            {
                "child": true,
                "id": 58801,
                "title": "IC Engage",
                "link": "https:\/\/ic-dev.lightersideofrealestate.com#",
                "class": [
                    "mobile_icengage_list",
                    "basic_hide_menu",
                    "",
                    "pause_hide_menu"
                ],
                "children": [
                    {
                        "child": false,
                        "id": 58802,
                        "title": "Go To The Group",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/ic-engage",
                        "parentId": "58801",
                        "class": [
                            ""
                        ]
                    },
                    {
                        "child": false,
                        "id": 58803,
                        "title": "View Group Files",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/ic-engage-files",
                        "parentId": "58801",
                        "class": [
                            "plus_hide_menu",
                            "basic_hide_menu"
                        ]
                    },
                    {
                        "child": false,
                        "id": 67794,
                        "title": "Weekly Round-ups",
                        "link": "https:\/\/ic-dev.lightersideofrealestate.com\/round-up-archive",
                        "parentId": "58801",
                        "class": [
                            "plus_hide_menu",
                            "basic_hide_menu"
                        ]
                    }
                ]
            }
        ]
    },
    "left_menus": [
        {
            "child": true,
            "id": 78964,
            "title": "Manage Subscription",
            "link": "https:\/\/ic-dev.lightersideofrealestate.com#",
            "class": [
                "parent_filter",
                "mobile_manage_profile",
                "mobile_manage_subscription"
            ],
            "children": [
                {
                    "child": false,
                    "id": 78965,
                    "title": "Account Details",
                    "link": "https:\/\/ic-dev.lightersideofrealestate.com\/manage-subscription#account-details",
                    "parentId": "78964",
                    "class": [
                        "dual_menu_click",
                        "mobile_manage_account_details"
                    ]
                },
                {
                    "child": false,
                    "id": 82072,
                    "title": "Billing & Usage",
                    "link": "https:\/\/ic-dev.lightersideofrealestate.com\/manage-subscription#billing-and-usage",
                    "parentId": "78964",
                    "class": [
                        "dual_menu_click",
                        "mobile_manage_billing_usage",
                        ""
                    ]
                },
                {
                    "child": false,
                    "id": 78968,
                    "title": "Email Notifications",
                    "link": "https:\/\/ic-dev.lightersideofrealestate.com\/manage-subscription#email-subscription",
                    "parentId": "78964",
                    "class": [
                        "dual_menu_click",
                        "mobile_manage_email_subscriptions",
                        "menu_hide_show",
                        "basic_hide_menu",
                        "pause_hide_menu"
                    ]
                }
            ]
        },
        {
            "child": false,
            "id": 87826,
            "title": "Content Calendar",
            "link": "https:\/\/ic-dev.lightersideofrealestate.com\/content-calendar",
            "class": [
                "mobile_schedule_post",
                "hide_menu_ios",
                "basic_hide_menu",
                "",
                "pause_hide_menu"
            ]
        },
        {
            "child": false,
            "id": 69010,
            "title": "My Favorites",
            "link": "https:\/\/ic-dev.lightersideofrealestate.com\/manage-subscription#my-favorites",
            "class": [
                "dual_menu_click",
                "mobile_manage_favorite",
                "menu_hide_show",
                "basic_hide_menu",
                "",
                "pause_hide_menu"
            ]
        },
        {
            "child": false,
            "id": 58796,
            "title": "Shared Articles",
            "link": "https:\/\/ic-dev.lightersideofrealestate.com\/manage-subscription#shared-articles",
            "class": [
                "dual_menu_click",
                "mobile_manage_shared_article",
                "menu_hide_show",
                "basic_hide_menu",
                "",
                "pause_hide_menu"
            ]
        },
        {
            "child": false,
            "id": 58818,
            "title": "Support Center",
            "link": "https:\/\/ic-dev.lightersideofrealestate.com\/helpcenter",
            "class": [
                "mobile_support_center"
            ]
        },
        {
            "child": false,
            "id": 58797,
            "title": "Contact Us",
            "link": "https:\/\/ic-dev.lightersideofrealestate.com\/contact",
            "class": [
                "mobile_contactus"
            ]
        }
    ],
    "footer": {
        "footer_logo": "https:\/\/icdevcdn.lightersideofrealestate.com\/uploads\/2015\/12\/innercircle-logo-lt-small.png",
        "footer_copy_right": "© 2022 Lighterside of Real Estate",
        "footer_privacy_text": "Privacy",
        "footer_privacy_link": "https:\/\/ic-dev.lightersideofrealestate.com\/privacy-policy",
        "footer_terms_text": "Terms",
        "footer_terms_link": "https:\/\/ic-dev.lightersideofrealestate.com\/terms"
    },
    "status_code": "200"
}