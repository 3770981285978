import * as types from '../Actions/ActionTypes';
import initialState from './InitialState';

export default function UsersReducer(state = initialState.blogs, action) {
    switch (action.type) {
        case types.LOAD_ALL_BLOGS_SUCCESS:
            var blogs = action.blogs.data;
            return Object.assign([], blogs);
        default:
            return state;
    }
}
