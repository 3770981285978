import React, { useState, useEffect } from 'react';
import { Button, Modal, CardBody } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from '../../../Assets/Images/Icons/spinner.svg'

const IframeModal = (props) => {

    const { isOpen, handleClose, postid, publicId, randomId, publishTo, iframeData, postStatus, postType, cc_Mobile } = props

    const [post_status, setPost_status] = useState(false);
    const [iframeHeight, setIframeHeight] = useState();
    const [progress, setProgress] = useState(true);

    useEffect(() => {
        // if (postStatus) {
        //     setPost_status("success_post")
        // }
        // else{
        //     setPost_status("")
        // }
        if (iframeData == 'save_success' || iframeData == 'add_post_popup') {
            handleClose()
        } else {
            setIframeHeight(iframeData)
        }

    }, [iframeData])
    useEffect(() => {
        setProgress(true);
    }, [!isOpen])

    const handleLoad = () => {
        setProgress(false);
    };


    return (
        <div className='contactForm'>
            <Modal className="modal-dialog-centered contentCalendar Deals contentCalendar contactForm" isOpen={isOpen} size="md">
                <div className="modal-body  d-flex justify-content-center p-0">
                    <CardBody className={`p-0 ${postType == 'meme' ? 'memeBorder' : 'articleBorder'}`}>
                        <div>
                            <div className='d-flex justify-content-between align-self-center'>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                                    <span aria-hidden={true}><CloseIcon className="m-close" /></span>
                                </button>
                            </div>
                            {progress && <div className="iframeloader"><img src={Spinner} alt="" /></div>}
                            <div className='parentiframe'>
                                <iframe
                                    src={`${cc_Mobile ? `https://innercircle.lightersideofrealestate.com/mobile-content-calendar?cc_mobile_schedule=${cc_Mobile}&schedule_post_type=${postType}` : `https://innercircle.lightersideofrealestate.com/mobile-content-calendar?postid=${postid}&id=${publicId}&randomid=${randomId}&publishto=${publishTo}&poststatus=${postStatus}`}`}
                                    title="sample"
                                    frameBorder="0" width="100%"
                                    // height={iframeHeight} 
                                    onLoad={handleLoad}
                                    className={progress ? 'hidden' : 'visible'}
                                    sandbox="allow-forms allow-scripts allow-same-origin allow-popups"></iframe>
                            </div>
                        </div>
                    </CardBody>
                </div>
            </Modal >
        </div >
    )
}


export default IframeModal;

