import React from 'react';
import Avatar from '@mui/material/Avatar';
import { bindActionCreators } from 'redux';
import * as dealsAction from '../../../_Config/Actions/DealsAction';
import { connect } from 'react-redux';
import logo from '../../../Assets/Images/innercircle-logo.png'
import AvatarUser from '../../../Assets/Images/placeholder/userAvator.png'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import Sidedrawer from '../SideMenuBar/Sidedrawer';
import { DropdownSubmenu } from 'react-bootstrap-submenu';
import Multidropdown from '../Inputs/Multidropdown';
import Breadcrumb from '../Breadcrump/Breadcrumb'
import { Markup } from 'interweave';
import Search from '../../../Assets/Images/Icons/searchIcon.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import SunLogo from  '../../../Assets/Images/sunLogo.svg'


class CCNav extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.seaarchInputShow = this.seaarchInputShow.bind(this);
    this.hidesearchField = this.hidesearchField.bind(this);
    this.finalarr = [];
    this.state = {
      dropdownOpen: false,
      dropdownOpen2: false,
      dropdownOpen3: false,
      collapse1: false,
      collapse2: false,
      left: false,
      open: false,
      topMenu: this.props.dealsList?.menus?.top_main_bar,
      showSearchField: false,
      onScroll: ''
    };
  }



  handleDrawerOpen = () => {
    this.setState({ ...this.state, open: true });
  };

  handleDrawerClose = () => {
    this.setState({ ...this.state, open: false });
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset < 60) {
        this.setState({ ...this.state, onScroll: '' })
      }
      else if (window.pageYOffset > 60) {
        this.setState({ ...this.state, onScroll: 'newPosition' })
      }
    })
  }

  toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.state.left = open;
    this.setState({ ...this.state, [anchor]: open });
  };
  closeDrawer = () => {
    this.setState({ ...this.state, 'left': false });
  }
  
  toggle(id) {
    this.setState(prevState => ({
      [id]: !prevState[id]
    }));
  }

  onMouseEnter(id) {
    this.setState({ [id]: true });
  }

  onMouseLeave(id) {
    this.setState({ [id]: false });
  }

  toggle1() {
    this.setState({ collapse1: !this.state.collapse1 });
  }

  seaarchInputShow() {
    this.setState({ ...this.state, showSearchField: true, collapse2: !this.state.collapse2 });
  }

  hidesearchField(status) {
    this.setState({ collapse2: !this.state.collapse2, showSearchField: false })
  }

  render() {
    return (
      <div>
        {/* Navbar design starts */}
        {/* <Navbar> */}
        {this.state.showSearchField ?
          <Multidropdown closeHandle={this.hidesearchField} isMobile={true} collapse2={this.state.collapse2} />
          : null}
        <Nav className={`navAvatarNav `}>
          {/* ${this.state.onScroll} */}
          <NavItem className='SearchItem'>
            <NavLink href="#" className={this.state.collapse1 ? 'searchActive ' : ''} onClick={this.seaarchInputShow}>
              {/* <SearchIcon className='Ic-Info ic-h-search-btn' /> */}
              <img src={Search} alt="" />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink>
            <Avatar className='curs-point navAvatar ml-1 m_t-2 menu-sun' alt="Remy Sharp" src={SunLogo} onClick={this.toggleDrawer('left', !this.state.left)} />
            </NavLink>
          </NavItem>
        </Nav>

        {/* </Navbar> */}
        {/* Navbar design ends */}

        {/* Imported Sidedrawer  */}
      <Sidedrawer toggleDrawer={this.toggleDrawer} onClose={this.closeDrawer} drawerOpen={this.state} dealsList={this.props.dealsList} />


        {/* Imported Breadcrumb  */}
        {/* <Breadcrumb dealsList={this.props.dealsList?.page_info}/> */}
      </div >
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    // dealsList: state.blogs
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dealsAction: bindActionCreators(dealsAction, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CCNav);

