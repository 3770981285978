import React, { Component } from "react";
import {
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import AccodianHelpCenter from "./AccodianHelpCenter";

export class HelpCenterTopicChoose extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1",
      currentTab: "General FAQ",
    };
  }

  toggle(tab, current_tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        currentTab: current_tab,
      });
    }
  }

  render() {
    return (
      <div className="help-center-topic">
        <div className="help-chooseTopic">
          <h3>Choose a Topic</h3>
        </div>
        <div className="help-tabs-wrapper dealsPage">
          <Nav tabs className="my-4 deals-tab help-Tabs">
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "1" })}
                onClick={() => {
                  this.toggle("1", "General FAQ");
                }}
              >
                General FAQ 
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "2" })}
                onClick={() => {
                  this.toggle("2", "Using Content");
                }}
              >
                Using Content 
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "3" })}
                onClick={() => {
                  this.toggle("3", "Account / Billing");
                }}
              >
                Account / Billing
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === "4" })}
                onClick={() => {
                  this.toggle("4", "Report a Bug");
                }}
              >
               Report a Bug 
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {/*  */}
          <AccodianHelpCenter tabs={this.state.currentTab} />
      </div>
    );
  }
}

export default HelpCenterTopicChoose;
