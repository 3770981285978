import React, { Component } from "react";
import AgentScriptSections from "./AgentScriptSections";
import AgentScriptContents from "./AgentScriptContents";
import Breadcrumb from '../../Common/Breadcrump/Breadcrumb';

export class Wrapper extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      breadcrumbData : {
        page_title: 'Agent Scripts',
        page_sub_title: ' Because saying the hard stuff doesn’t have to be hard.'
      }
    }
  }
  
  render() {
    return (
      <div>
        <Breadcrumb dealsList={this.state.breadcrumbData} />
        <div className="AgentWrapper">
          <div className="AgentWrapper_Section">
            <AgentScriptSections />
          </div>
          <div className="AgentWrapper_Contents">
            <AgentScriptContents />
          </div>
        </div>
      </div>
    );
  }
}

export default Wrapper;
