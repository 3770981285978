let todayStr = new Date().toISOString().replace(/T.*$/, '')
const events = [
  {
    title: 'test',
    start: '2022-08-05T4:40:00',
    description: 'test'
  },
  {
    title: 'BCH237',
    start: '2022-08-05T19:55:00',
    description: 'Lecture'
  },
    {
      id: 1,
      title: 'All-day event',
      start: todayStr+ 'T13:05:00'
    },
    {
      id: 2,
      title: 'Timed event',
      start: todayStr + 'T23:55:00'
    } ,{
      id: 3,
      title: 'event',
      start: todayStr+ 'T10:05:00'
    },

]



export default events;