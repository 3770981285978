const urlHeader = `https://ic-dev.lightersideofrealestate.com/`
export const Sections_Values = [
  {
    name: "Commission talk",
    url: "",
  },
  {
    name: "Dealing with prospects",
    url: "",
  },
  {
    name: "Dealing with seller clients",
    url: "",
  },
  {
    name: "Dealing with buyer clients",
    url: "",
  },
  {
    name: "Dealing with clients (buyer or seller)",
    url: "",
  },
  {
    name: "Dealing with friends, family, acquaintances",
    url: "",
  },
  {
    name: "Dealing with <em>anyone</em> who steps out of line",
    url: "",
  },
];

export const information_values = [
  {
    values:
      '<strong>Never get caught off guard or fumble over your words again!</strong>  Of course you’ll probably need to edit these based on your particular situation…but they’re field tested and <span style="text-decoration: line-through;">mom</span> agent approved! <br/><br /> Written by a grizzled real estate veteran, they’re meant to be used in written <em>or</em>  verbal communication. So be sure to refer back to them as necessary, or loosely commit them to memory so they roll off your tongue with ease.',
  },
];
export const agentKey=["Commission talk","Dealing with prospects","Dealing with seller clients","Dealing with buyer clients","Dealing with clients (buyer or seller)","Dealing with friends, family, acquaintances","Dealing with anyone who steps out of line"]
export const AgentData = {
  "Commission talk": [
    {
      title: "Standing firm",
      name: "Commission talk",
      id: 1,
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When your fee is the problem, but you have no ability to lower your fee…or simply don’t want to.",
      notes:
        "<strong>NOTES:</strong>  This one is good for when your seller prospect mentions that they’d like to work with you…“if only you could do something with your fee”. <br /><br />Let’s face it, if they’re getting a better rate from another agent, and they like the other agent, and have confidence in the other agent…they probably wouldn’t even mention it. So, it’s probably a sign they like you and have confidence in you. <br /><br /> It means there’s still a chance, even if you can’t or won’t lower your fee. <br /><br /> Who knows, they might not even have anyone else who will agree to do it for less. They may just be bluffing, or simply asking to see if they can get a better rate.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I <em>wish</em> I could. That’d make it easy. <br/><br/>Because, obviously, I’d love to work with you. And from the sounds of it, you’d prefer to work with me too…if my fee were lower.<br /><br/>But then again, you’d probably regret it because that’d mean I’m not all that careful about my value in the marketplace. <br/><br/> And if I’m not careful about <em>my</em> value, I probably wouldn’t be an agent who’s all that careful with the value of my clients’ homes.<br/><br/> Believe me, I <em>love</em> negotiating. But I love doing it <em>for</em> my clients. Not with them. That’s not a very good way to start a relationship. <br/><br/>As much as I don’t want you to feel like I’m just being firm and stubborn (because I truly want you to like me)… <br /><br/> …I’m not so sure you’d like me all that much if I <em>wasn’t</em> this firm and stubborn as we move forward. <br /><br/>It’s a double-edged sword, I know. But I think you’ll appreciate having a sharp sword, instead of a dull dagger.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I totally wish I could. <br/><br/> Actually, y’know what I really wish? <br/><br/> Well, if we’re going <em>there</em>, I really wish you were just like, “You’re hired!”, and we didn’t even have to talk about my fee. <br/><br/> But since we <em>are</em> talking it… <br/><br/> I wish I could push a fast-forward button, or hop in a time machine with you, and go into the future. <br/><br/> Wayyyy beyond this conversation (after we’ve already agreed I’m worth every penny). <br /><br/>Allllll the way to the closing day, when you <em>see</em> and <em>know</em> that I was worth every penny. <br/><br/> Unfortunately, I don’t have either of those things. <br/><br/>But… <em>but</em>…I <em>can</em> see into the future! And it appears it all worked out! You hired me! I did a great job! And you’re so happy you hired me. <br/><br/>And it all started right after this sentence when you say… “Where do I sign?”<br/>",
        },
      ],
    },
    {
      title: "Seller brings up commission too early",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>   When you have a seller who asks how much commission you charge, before you even meet with them.",
      notes:
        "<strong>NOTES:</strong>  You know the question, and you know the type of person… <br /><br />Every once in a while, you get someone who wants to know how much you charge, before you’ve even stepped foot in their house. You haven’t even gone through your listing presentation. You haven’t even gotten the chance to show how much experience, skills, or value you bring to the table. <br /><br /> They want to make a decision to invite you in or not, based solely upon what you say your commission is. <br /><br /> Rather than just blurt that out…blurt these responses out instead.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "That’s the million dollar question! (I <em>assure</em> you…it won’t be a million dollars. LOL) <br/><br/>Not sure if you know this, but there’s no “standard” commission rate. That’s by law actually.<br /><br/>So, every company (and even every agent) could charge a different amount. Most are probably going to sound pretty close to the others, and I’m sure mine will be “in the ballpark”, as they say. <br/><br/> That said, it’s impossible for me to say for sure until we meet and I get a chance to see your house. I also need to chat with you about what all you’ll want and need done during the process of selling your house.<br/><br/> But it’s definitely something we’ll discuss. I know it isn’t the only thing you need to analyze when you decide to hire an agent, but it’s certainly part of what you will consider. <br/><br/>So, just looking at my calendar…<br /><br/> I can swing by on <span style='color: #ff422d;'>[insert date and time]</span>  or <span style='color: #ff422d;'>[insert another date and time] </span>…or is there a better day and time for you? <br /><br/>",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I could tell you what I think you <em>want</em>  to hear, like people do on dating websites, but then what if we meet and I’m not delivering the goods you expected for whatever I quoted? <br/><br/> Heck, what if I get to your house and it’s not what <em>I’m</em> expecting, or what you want and need in an agent isn’t what I <em>think</em>  it will be. <br/><br/> My commission depends on a lot of things, and I can’t be sure until we meet and I see your house. <br/><br/> But I promise you, it’s something we’ll definitely discuss when we meet. Is <span style='color: #ff422d;'>[insert date and time]</span> good for you, or would <span style='color: #ff422d;'> [insert another date and time]</span>  be better? <br/>",
        },
      ],
    },
    {
      title: "Seller wants to know your rate",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When you are telling a SELLER how much you charge / your rate.",
      notes:
        "<strong>NOTES:</strong>  Do you fear the moment you have to break the news about your commission? Well, you’re not alone. <br /><br /> Agents tend to <em>expect</em>  people to be upset and ask for a lower commission rate. And some will. But, maybe they’re just asking to see how you react. <br /><br /> Too many agents go into a meeting almost prepared to have to agree to a lower commission. Instead, you should go in prepared to simply state your rate with confidence…that way they’ll be less likely to ask. And even if they do, you’ll be less likely to give in. <br /><br /> (Sorry, if you’re the rare agent who charges up front; this one’s not for you.)",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Just to review, before we get into my fee… <br/><br/>I will be <span style='color: #ff422d;'>[create a list of everything you will be handling for them from start to finish]</span>. <br /><br/> The first thing I will do is <span style='color: #ff422d;'>[insert the first thing you do]</span> . And I’d like to do that on <span style='color: #ff422d;'>[insert day]</span>  if we can get all of the paperwork done.<br/><br/> By the way, just to clarify, you won’t be paying me any money to get the process started. There’s no deposit or retainer. I just charge <span style='color: #ff422d;'>[insert your rate]</span> at the closing table… <br/><br/> In other words, you don’t pay anything until we’ve gotten the best possible price for you home, we’ve closed the deal, and you’ve gotten <em>your</em> money. <br/><br/>Anyhow, back to <span style='color: #ff422d;'>[insert day you said before]</span>… I want to make sure I put it in my schedule. Can we shoot for that day?",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "OK! Let’s just rip the Band-Aid off… <br/><br/> My fee is <span style='color: #ff422d;'>[insert your rate]</span>. <br/><br/> <span style='color: #ff422d;'>( <strong>NOTE:</strong> If in person, let them react. If they don’t disagree with it…yahoo! Move along. If they do wince and say it seems high, or ask if you can lower the rate, continue with everything below.) </span>  <br/><br/> <em>That’s </em>  the problem with rippin’ off a Band-aid! <br/><br/> At first you’re expecting it too hurt really bad, but it only winces for like a few seconds. Then you realize it’s not so bad. <br/><br/> You just heard my rate and it’s natural to wince, but when you really consider everything I’m doing… <br/><br/> <span style='color: #ff422d;'>[List everything you’ll be doing…]</span> <br/><br/> All of that gets done before I ever get paid a <em>cent</em> . Heck, I may never get paid a cent if I don’t get your house sold. So, believe me, I’m going to be working hard, doing all of that stuff, to make sure I get your house sold. <br/><br/> Man, when I say it that way, it makes me feel like I just ripped a Band-Aid off. Kinda crazy business to be in, right? It’s actually a better deal for consumers than agents. <br/><br/> We better get the paperwork done before I start rethinking my rates…or my sanity. LOL",
        },
      ],
    },
    {
      title: "Seller lacks equity / needs a break",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When you’re dealing with a seller who simply can’t afford your rate, and you want to give them a break for whatever reason. Basically, you want to be nice, or you feel like some money is better than no money, etc.",
      notes:
        "<strong>NOTES:</strong> There are times when you meet someone who truly is in a tough spot. They want or need to sell their home, but they just can’t swing it without you charging a lower rate. <br /><br /> Deciding to hook them up is nice, but it’s nicer if they appreciate it. Because sometimes, being the good, giving real estate agent simply doesn’t get appreciated, and can sometimes lead to more work, more headaches, and all for less pay. <br /><br /> This script sets you up to be appreciated, and hopefully pave the way for a smooth transaction (and future referrals!). <br /><br /> Plus, if you don’t qualify <em>why</em>  you’re giving them a deal, then it could come across as being desperate for business, or willing to lower your fees in the future.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "You certainly need a lower commission in order to do what you need to do. <br/><br/>It’d be tough to find an agent who can or will be as flexible on the commission as you need to be—at least one you’d want to hire. <br /><br/> And I don’t want to see you hire an agent who isn’t great, or won’t do the best job for you, just because of the tight spot you’re in. <br/><br/> So, I’m willing to make an exception and charge you <span style='color: #ff422d;'>[insert rate] </span>. <br/><br/> The only thing I want to say is this… <br/><br/> I’ve heard other agents talk about how they gave someone a break, and then regretted it because their client ended up not appreciating it, and were extremely demanding. (Not that I can even imagine you being that way!).<br/><br/> Anyhow, it probably happens because, when other agents do it, they often cut back on the things they offer or do… <br/><br/> I won’t be doing that. <br/><br/> I’m still going to be putting in 100% for you, for less than 100% of what I’d typically earn. <br/><br/> All I ask is that you appreciate my efforts and refer me to anyone you know who needs to buy or sell. <br/><br/> But please don’t mention that I gave you a break, because this is truly a rare exception!",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I forgot to pack my cape today, so you might not realize what a superhero I am… <br/><br/> But I can and will save you! <br/><br/> Obviously you’re in a tight spot. <br/><br/> I think if you even tried to find another agent who could swing it, you’d end up feeling like the cape they were wearing ended up being more of a beach towel draped over their shoulders. <br/><br/> I don’t want to see you hire an agent who says they can get you out of the one jam, but then put you in a worse spot by not doing as good of a job as I can do. <br/><br/> So, I’m willing to make an exception and charge you <span style='color: #ff422d;'>[insert rate]</span> <br/><br/> The only thing I want to say is this… <br/><br/> Giving people a break can be like Kryptonite in this business… <br/><br/> I’ve heard other agents talk about how they gave someone a break, and then regretted it because their client ended up not appreciating it, and were extremely demanding. (Not that I can even imagine you being that way!) <br/><br/> Anyhow, it probably happens because, when other agents do it, they often cut back on the things they offer or do… <br/><br/> I won’t be doing that. <br/><br/> I’m still going to be putting in 100% for you, for less than 100% of what I’d typically earn. <br/><br/> All I ask is that you appreciate my efforts and refer me to others. <br/><br/> Just shine the Bat-signal up in the sky the next time you hear someone you know needs a real estate agent. <br/><br/> But please don’t mention that I gave you a break, because this is truly a rare exception!",
        },
      ],
    },
    {
      title: "Buyer asks how much you charge",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> Buyer asks how much you charge",
      notes:
        "<strong>NOTES:</strong> Sometimes a buyer has no clue how you get paid. Here we aren’t talking about a buyer who is questioning your commission, or trying to get you to kick it back, reduce it, or any other sort of thing like that. This is just dealing with someone who is concerned that they have to actually pay you out of their own pocket. <br /><br /> This question isn’t something tough to overcome, but you might as well use it as a moment to endear them to you. <br /><br /> ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I love it when people ask me this, because it makes me sound super nice… <br/><br/>You won’t need to whip out your wallet, or a checkbook in order to work with me…ever. <br /><br/> You probably wonder <em>how</em>  I get paid then…’cause it wouldn’t make sense for me to work for free. <br/><br/> I get paid out of the seller’s equity. <br/><br/> Not to get too technical, but here’s the basics… <br/><br/> The seller pays the company they listed their house with. Part of what they agree to pay them is used to pay the buyer’s agent’s company. Then my company will pay me out of that amount. <br/><br/> That doesn’t mean I work for the seller at all! <br/><br/> I work for you, and on <em> your </em>  behalf. <br/><br/> But I’m kinda glad you brought it up. It shows you care about me getting paid. <br/><br/> In a nutshell, I don’t get paid until and unless my client buys a house. So I really need to be careful about who I’m dedicating my time to…which is why I appreciate your question! <br/><br/> So don’t even think about having to pay me. Just assure me that you’re serious and in this for the entire process with me as your agent.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Before I say this, I just want to make sure you don’t have a heart condition or anything… <br/><br/> You good? You ready to hear this? <br/><br/> You won’t need to whip out your checkbook in order to work with me…ever. <br/><br/> Which probably begs the next question… <br/><br/> How <em> do </em>  I get paid, right? <br/><br/> The seller pays me. Kinda. Here’s the quick way of looking at it… <br/><br/> The seller pays the company they list their house with out of the equity in their home. <br/><br/> Then the listing agent’s company pays my company. <br/><br/> Then my company will pay me out of that amount. <br/><br/> Nice, right? You get me working for you, and looking out for your interests, and get someone else to pay for it…LOL. <br/><br/> And thanks, by the way… <br/><br/> The fact that you asked means a lot. <br/><br/> It’s a sign that you’re a good client. And since I don’t get paid until and unless a buyer buys a house, I need to make sure I’m working with good people. <br/><br/> You good people?",
        },
      ],
    },
    {
      title: "Buyer asks about rebate",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When you have a buyer who asks you to kick back your commission, or rebate money to them.",
      notes:
        "<strong>NOTES:</strong>  Somehow, some buyers feel like you should hook them up, just for the pleasure of working with them. Problem is, the question itself isn’t pleasant. So the whole pleasure thing gets off to a rocky start. <br /><br /> Kick back with this script when they ask for a kick-back. ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "First of all, this is not the craziest thing to ask me. So don’t feel weird or bad. It’s a pretty common question for buyers to ask. <br/><br/>And I hope you don’t feel weird or bad when I say that I’m sorry, I can’t do that. <br /><br/> I think, from the outside, it looks like real estate agents are just raking in the money.<br/><br/> But the truth is, I simply don’t make, let alone  <em> rake</em>, as much money as you might think. <br/><br/> The thing is, the commission isn’t all <em> mine </em>  to take home. It gets split up many ways before I even get my share of it. And then I still have to pay business expenses and taxes before I even pay a bill, or buy groceries. <br/><br/> And that’s exactly why I <em>am</em>  a real estate agent…to earn a living so I can afford to live. <br/><br/> Luckily, it’s a career I love, so it barely feels like work. And it’s something I’m really good at…so I’ve been told. Hopefully you’ll be telling me the same thing shortly.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Only kickin’ back I’ll be doin’ is on my own private island with a <span style='color: #ff422d;'>[insert your favorite drink]</span> in my hand, after I make all this money off of you… <br/><br/> Kidding! I <em>wish!</em> <br/><br/> The reality is, I truly wish I made as much money as you think I will. <br/><br/> But I’ve got way more hands reaching into my pockets than yours… <br/><br/> I’ve gotta split my commission with my broker, pay expenses longer than I care to list, or you care to hear. Pay taxes. And then perhaps pay my bills, and put some grub on the table. <br/><br/> Then, maybe… <em>mayyyyybe</em> …I might treat myself to a <span style='color: #ff422d;'> [insert your favorite drink]</span>  at a local bar. <br/><br/> Heck, I might even kick you back a drink or two if I see you there… <br/><br/> … <em> if it’s <span style='text-decoration: underline'>happy hour </span></em> (remember all those hands reaching into my pockets? LOL). ",
        },
      ],
    },
    {
      title: "Lowering rate to get a client",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When you feel like the prospect is slipping away, but a better fee would do the trick.",
      notes:
        "<strong>NOTES:</strong> You probably always hear agents talk about how you should never give in…never lower your fee. “You’re worth every penny!” <br /><br /> And you probably are. <br/><br/> That doesn’t mean it wouldn’t be nice to get every penny you can, even in the moments when you’re getting paid less pennies than you normally would. Sometimes, getting the business makes sense at a lower rate, rather than watching someone else do it for a lower rate. <br/><br/> But if you do, you need to do it in a way that’s appreciated and doesn’t come across as you being desperate for business. Because that can lead to a horrible relationship and a lack of respect. <br/><br/> Use this script to give someone the deal they want, but maintain your integrity.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I’m getting the sense that the only way you’ll hire me, or any other agent, is if you can manage to negotiate the commission down? <br/><br/>Can’t fault you for wanting to keep your cost of selling your house down as much as possible. <br /><br/> So, I’ll save you some time and effort. Because agents—at least the really good ones—are trained on how to turn that around on you and overcome your objection and get you to pay their higher rate. <br/><br/> Then there’s the others who will bend immediately and lower their commission for no other reason than because you asked. They’re obviously not the best ones to hire to help you negotiate the most money for your house. <br/><br/> On the other hand, while I could pull out all of my training and experience to stick by my commission, I’d rather make an exception for you. <br/><br/>Here’s why… <br/><br/> I want to impress you. I can tell by your personality that, if I do impress you, you’ll make sure to sing my praises. So I’ll look at this as a loss-leader. <br/><br/> I’ll charge you less than I would normally charge, but in turn, I ask that you make sure to refer me to anyone you know who’s buying or selling…without telling them I work for <span style='color: #ff422d;'>[insert rate] </span>. Because that’s a one time deal for you. <br/><br /> That work for you?",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Normally, when someone asks me for a lower commission, I agree to it… <br/><br/> <em>If</em>  the person can beat me in an arm wrestling match. <br/><br/> But I slept on my arm the wrong way last night, so I know I’d end up losing to ya anyway. <br/><br/> So, I’ll skip right to the good part. <br/><br/> You win. I’ll work with you for <span style='color: #ff422d;'>[insert rate] </span> . <br/><br/> But just this time, and only ‘cause my arm hurts. <br/><br/> Only thing I ask is this… <br/><br/> I actually don’t like arm wrestling. I’d rather people not even ask me to lower my rate. <br/><br/> So, the next time you come across someone who needs to buy or sell a house and refer them to me, tell ‘em I’m worth every penny that you <em> should </em>  paid me…and I <em> will</ em> prove that I am.</em>  <br/><br/> Deal?",
        },
      ],
    },
  ],
  "Dealing with prospects": [
    {
      title: "They refuse to get pre-approved",
      name: "Dealing with prospects",
      id: 2,
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a prospect or buyer client refuses or resists getting pre-approved.",
      notes:
        "<strong>NOTES:</strong> This is probably the equivalent to sellers always thinking their house is worth more than your CMA suggests… <br /><br />For every seller who overestimates their value, there’s a buyer who doesn’t don’t want to be bothered to get pre-approved “until we find a house we want to buy”. Usually followed by, “I know how much I can afford…” <br /><br /> Sure, most of us agree that you should never even take a buyer out until (and unless) they’re pre-approved. But it happens to the best of us. And once you do, it can become very difficult to ever find the right moment, or words, to put your foot down, draw a line, and insist they get pre-approved before showing them anymore homes. <br /><br /> There’s a lot of reasons you’ve heard and given before, but this one should be a different approach than most agents are thinking of taking… <br/><br/> With this script, you’re giving them a solid (selfish) reason to do it for their own good. ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Most agents push their clients to get pre-approved because they want to make sure they’re working with a qualified buyer. I get it. But that’s not why I do. <br/><br/>To me, it’s the best advice I can give someone because that’s one of the ways my buyers are able to get a better deal on the house they buy. <br /><br/>Everyone focuses on the price they get a house for. But so many people overlook getting the best <em>mortgage</em> <br/><br/> That’s because they wait to start that process <em>until they find a house they love</em> . At that point, they need to rush and get pre-approved, and start the mortgage process quickly. They lose the time they had to truly find the best mortgage lender, with the best combination of rates and programs. <br/><br/> Even if they got a good deal on a house, they’re often paying way more in interest or fees than they would’ve if they’d started the process as early on in their house hunting as possible. <br/><br/>So, do you want to call a lender you already know and trust, or would you like a list of ones I trust and recommend? ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "You wanna know one of my secrets to getting my clients the best deal when they buy a house? <br/><br/> Well, it’s not really a secret, <em>everyone</em>  knows you need to do what I’m about to tell you. <br/><br/> However, a lot of buyers (and even some sloppy agents) think it’s fine to put it off… <br/><br/> It’s getting pre-approved for a mortgage. The secret in my sauce is that I make sure my buyers do it as early on in the process as possible! <br/><br/> I doesn’t matter if someone got the greatest deal on a house…too many buyers get hosed on their mortgage <em> because they didn’t get pre-approved until they found the house they wanted</em>. <br/><br/> Then they gotta go from pre-approval to the loan process faster than a Ferrari, and that’s where, why, and when they lose money. Those rates and fees are along for the ride for a lot of years later. And it adds up to tons of money. <br /><br/>But since my clients get pre-approved so early on, they get a chance to really look at what the lenders’ programs, fees, and rates are, and make the best choice. <br/><br/> Here’s another thing I’ll let you in on… <br/><br/>I’ve got a private list of lenders I know and trust. Want in on that list? Or do you have a list of ones you know and trust already?",
        },
      ],
    },
    {
      title: "Persuading them to sign contract",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client is hesitant to sign your listing agreement or buyer agency agreement. They want to look it over, or think it over before they pull the trigger.",
      notes:
        "<strong>NOTES:</strong>  It’s normal for people to want to know what they’re signing. It’s also normal for them to use that as an excuse to just not make a decision. <br /><br /> This script softens your request, and makes it a no-brainer to sign your agreement, by making it risk-free. <br /><br /> Only offer this if you truly will honor releasing your client from the listing agreement or buyer agency agreement.  ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I can’t blame you for wanting to look it all over before you sign. Well, “wanting” probably isn’t the right word… <br/><br/>You feel like you <em>should</em> , but you probably don’t <em>want</em>  to read it all. <br /><br/>Here’s the good news… <br/><br/> This contract literally protects you more than it does me. <br/><br/> I need to live up to the job, but if at any point you aren’t happy, you can cancel this agreement. No strings attached. No cost to you. <br/><br/> It’s totally lopsided and, in some ways, it doesn’t make good business sense. <br/><br/> But in other ways, it <em>does </em>. <br/><br/> It ensures you that I’m certainly going to be on my toes making sure I do a good job, and that you’re happy. <br/><br/> It’s literally no risk to you, and all the risk is on me.<br/><br/> So, the only thing I ask of you is this… <br/><br/> If you’re not happy for whatever reason, let me know, and give me a chance to address your concern. While I don’t anticipate ever getting to that point, I can’t fix what I don’t know is an issue. <br/><br/> And if I can’t make things right or better for you, then of course I’ll gladly cancel our contract to work together. <br/><br/> <em>You’ve got my word!</em>  Can I get yours? <span style='color: #ff422d;'>( <strong>NOTE:</strong> Now indicate where they need to sign.) </span> ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I hear you. <br/><br/> But here’s the deal. (And the “deal” is as lopsided as a table with a short leg…) <br/><br/> You get to sign this agreement and cancel at any time if you aren’t happy with me. <br/><br/> Think about that—I literally have to make <em>sure</em>  you’re happy throughout this entire process, or you can just cancel this contract. No strings attached. No cost to you. <br/><br/> Don’t get me wrong…I do this willingly. <br/><br/> I do it because it keeps me on my toes (and my therapist in business), and ensures that I’m always doing the best job possible for my clients. <br /><br/>Just do me a favor… <br/><br/> If at any point you aren’t happy, give me a heads up, and a chance to make things right. <br/><br/>Oh, and one more favor… <br/><br/> Mind signing right here? <span style='color: #ff422d;'> ( <strong>NOTE: </strong>  Now indicate where they need to sign.)</span>",
        },
      ],
    },
    {
      title: "Giving them space before signing",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When a client is <em>insistent</em>  upon thoroughly reviewing your contract / paperwork before they sign your listing agreement or buyer agency agreement.",
      notes:
        "<strong>NOTES:</strong>  Sometimes pushing someone, “closing” them, or “handling” their objections can backfire. <br/><br/> If someone is insisting on looking over your contract / paperwork with a fine-toothed comb…give ‘em the comb. Separate yourself from the more pushy agents they may come across, while at the same time not seeming like a pushover yourself. <br/><br/> Give ‘em the time and space they need, with your blessing, while setting an authoritative tone.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "<em>Thank</em>  you…that’s a breath of fresh air. <br/><br/>I’m always amazed at how many people just sign these things without truly reading through it. After all, it  <em>is</em>  a contract to hire someone to help with one of your largest assets. <br /><br/>So please, take your time. I like seeing that, because it means I’m dealing with someone who’s careful and will take the time to make solid decisions as we move forward. <br/><br/> Kind of a side note… <br/><br/> It must boil down to the training some agents get, but so many agents push people to sign these things on the spot, and rush right into things. <br/><br/> I think it’s probably because they’re afraid if they don’t walk away from the appointment with signatures, they won’t get the business. <br/><br/> To me, if you  <em>deserve</em>  to be hired, you don’t have to push. <br/><br/> Heck, I feel like if you do push, you don’t deserve to be getting the business. Agents should be pushy <em>for</em> clients and their best interests…not pushy <em>with</em> them. <br/><br/> Anyhow, take the time you need. Ask me any questions you have. <br/><br/> I’m even glad to go over it line by line with you…would you prefer <em>that</em>, or time to read it over on your own? ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Oh, thank <em>goodness</em>… <br/><br/> I can’t tell you how many times people just sign on the dotted line, and throw in their first born, without even reading this stuff. <br/><br/> As much as that might sound good to some agents (minus taking on another mouth to feed), I much prefer someone reading the agreement before signing it. <br/><br/> <span style='color: #ff422d;'>[sale / purchase]</span> of a dollhouse. It’s a real house. <br/><br/> I’m sure some sales guru out there would cringe hearing me say any of this, like I ran my nails down a chalkboard. They all teach agents to get people to push people to sign on the spot. <br/><br/> And for whatever reason, people cave in to that. To me, any person falling for that kinda pressure is probably not the best fit for me. <br /><br/> I’d rather deal with people who are as careful about who they choose, and what they sign, as I am about looking after my clients’ best interests. <br/><br/> So don’t just feel free to read it all…I insist…and I appreciate it. <br/><br/> I can even read through it line by line with you if you want. Wanna do that? Or read it on your own?",
        },
      ],
    },
    {
      title: "Rekindling the conversation",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When you’ve met or spoken with a prospect who wasn’t ready to actually start the process or hire you yet (at least so they said).",
      notes:
        "<strong>NOTES:</strong> Hmmm, it coulda been a gentle blow-off. Lots of people don’t want to break bad news to you. <br/><br/> But they <em>did</em> seem sincere and honest. You felt like it went well with them. They assured you they’d call you once you were ready. <br/><br/> But you also haven’t heard anything from them in a while. People are busy. It’s probably just that. But it doesn’t hurt to give ‘em a holler. <br/><br/> Here’s whatcha holler… ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I was just thinking about how well we hit it off when we met back in <span style='color: #ff422d;'> [insert when you met]</span>. <br/><br/>I’ve been looking forward to working with you, and just realized it’s been a while since I checked in. <br /><br/>Are you putting the (sale / purchase) on the backburner for now? <span style='color: #ff422d;'>(<strong>NOTE:</strong> If they reply yes, add the line below…)</span> <br/><br/> When are you thinking you want to get going on it? <span style='color: #ff422d;'>(<strong>NOTE:</strong> Let them answer.)</span>  Oh, OK, no rush! It’ll give me something to look forward to! I’ll follow up with you in <span style='color: #ff422d;'>[insert a timeframe]</span>.<span style='color: #ff422d;'>(<strong>NOTE:</strong> If they say they are ready now, add the line below…)</span> <br/><br/> Wow, that’s crazy timing on my part! Glad I reached out. So why don’t we plan on getting together to review what <span style='color: #ff422d;'>[you are looking for in a house / you should do to get the most money for your house]</span>. <br/><br/> I’ve got time on <span style='color: #ff422d;'>[insert day and time]</span> or <span style='color: #ff422d;'>[insert another day and time]</span>…which of those works best for you?",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I remember the day we first met like it was yesterday… <br/><br/> I liked you and couldn’t wait to work with you. <br/><br/> And I couldn’t help but feel like you liked <em>me</em> back! (Not like let’s go to the prom “ <em>liked</em>” me liked me, back in junior high of course…) <br/><br/> Anyhow, I just got to thinkin’… <br/><br/> I know the “big date” to <span style='color: #ff422d;'>[sell / buy]</span> your house was a while away. But it’s been a while…so I just wanted to check and make sure we’re still on. <br/><br/> So, where are you with everything? You ready to <span style='color: #ff422d;'>[buy / sell]</span> at this point? <br /><br/> <span style='color: #ff422d;'>( <strong>NOTE:</strong> If they say not yet, add the line below…)</span> <br/><br/> OK, when are you thinking you’ll be putting on your dance shoes?",
        },
      ],
    },
    {
      title: "They hint at using friend or family",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a prospect drops hints (or blatantly says) they’re going to use a friend, family member, sister’s friend’s cousin as their agent.",
      notes:
        "<strong>NOTES:</strong> There’s plenty of ways to overcome this one. But they’re all kinda tired, and at times can be seen as being a bit rude or mean even, by questioning the abilities or integrity of the person’s friend or family member. <br/><br/> This approach is an “if you love something, let it go…if it comes back, it was always yours” approach. <br/><br/> You’re suggesting the sorts of things every other agent tends to throw at people, but distancing yourself from the sentiment. You’re showing support, but creating questions in the prospect’s mind. <br/><br/> You’re also using a little reverse psychology by being so willing to let them go, but also willing to take them on if they don’t end up feeling comfortable using the friend or family member. <br/><br/> This way, if the prospect truly isn’t comfortable working with their friend or family member at some point, you’ve separated yourself from all the other agents who took a shot at their friend or family member, instead of just being cool about it. ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Lots of people feel they should work with their friend or family member when  <span style='color: #ff422d;'> [buying / selling]</span> a house. Totally understandable. <br/><br/>I dislike how so many agents typically question whether the person’s <span style='color: #ff422d;'>[friend / relative]</span> is qualified to handle delicate situations with someone so close. Who are <em>they</em> to question your <span style='color: #ff422d;'>[friend / family member]</span>? <br /><br/>I don’t go there. I wouldn’t <em>dare</em> presume your friend or relative isn’t as good of an agent as I am…or that working with <span style='color: #ff422d;'>[him / her]</span> will lead to any issues between you. But agents are trained to say those sorts of things. <br/><br/> In fact, I appreciate seeing that sort of loyalty if someone is truly confident in their  <span style='color: #ff422d;'>[friend’s / family member’s]</span>  abilities, and confident there won’t be any conflicts working together. <br/><br/> Anyhow, good luck with your  <span style='color: #ff422d;'>[sale / purchase]</span>, and if at any point you decide to work with someone a little more arm’s length professionally, I’ll be glad to help. ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Hollllld on a second! If <em>that’s</em> how you’re picking your agent, then you gotta know… <br/><br/> All of my clients end up being <span style='color: #ff422d;'>[friends of mine / like family to me]</span>! <br/><br/> So, you should tell your  <em>other</em> <span style='color: #ff422d;'>[friend / family member]</span> that you’re going to work with a <span style='color: #ff422d;'>[friend / family member]</span>, and work with me. It’s literally just a matter of time! Ha! <br/><br/> How about this instead… <br/><br/> Since both of us are  <span style='color: #ff422d;'>[friends / family]</span>  of yours, decide based upon which of us will do a better job for you. <br/><br/> I can’t touch that with a ten-foot pole. I don’t know your <span style='color: #ff422d;'>[friend / family member]</span> , and I hate when other agents question whether a person’s friend or family member is qualified to handle delicate situations with someone so close. Who are they to question your<span style='color: #ff422d;'>[friend / family member]</span> ? (Agents like that should be <em>hit</em>  with a ten-foot pole!) <br /><br/> All I can do is vouch for myself, and do the best job possible for all my friends and family (and future friends and family…LOL). And I’d love a shot to show you how good of a job I can do for you. <br/><br/> And if your other <span style='color: #ff422d;'>[friend / family member]</span>  is the better choice, you’ll have my blessing…no hard feelings…and we can still be friends. <br/><br/> That sound fair to all of us?",
        },
      ],
    },
    {
      title: "You sense they won’t be hiring you",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When you have a prospect that you sense won’t be hiring you, or absolutely know won’t be hiring you.",
      notes:
        "<strong>NOTES:</strong> Rather than just let the prospective client sail off into the distance and find out when they stop calling, or before they tell you they’re “going with someone else”, use these to take control. <br/><br/> Take control of ending the relationship before they do. Save your integrity…you do the breaking up. But gently. And do it in a way that allows you to still make some money. ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I really appreciate the opportunity to have <span style='color: #ff422d;'> [worked / spoken]</span>  with you. <br/><br/>But I just don’t think I’m the best fit for you because  <span style='color: #ff422d;'>[insert what you feel the issue is]</span> . <br /><br/>However,  <span style='color: #ff422d;'>[I know a few agents I think are a better fit. / I know an agent who’d be a perfect fit for you!]</span> <br/><br/> You’re obviously welcome to hire anyone else you may know of, but given what I’ve heard from you, and what I know as an agent, I think I can save you some time and aggravation by connecting you with the agent I’m thinking of… <br/><br/> You interested? ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Being a real estate agent is kinda weird. I wear more hats than you could ever imagine. <br/><br/> (Unless you have a crazy imagination and like to imagine hats.) <br/><br/> Anyhow, one of the hats I end up wearing is match-maker. <br/><br/> ‘Cause sometimes I meet with a prospective client, and it’s obvious we’re just not a good match. <br/><br/> And I kinda get the feeling we’re not. Nothing wrong with that. What <em>would</em>  be wrong, is to ignore that, and try and make it work together. <br/><br/> Rather than have you search for your soul mate of an agent in the equivalent of a crowded bar, I know an agent or two that I think you’d really hit it off with. <br /><br/> Want me to write <span style='color: #ff422d;'>[him / her]</span>  a note, fold it up in a triangle, and pass it along and see if <span style='color: #ff422d;'>[he / she]</span>  is interested in meeting you?",
        },
      ],
    },
    {
      title: "You feel you’re over your head",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When you’re a newer agent, desperately need business, or have found a prospect that is worth a huge commission beyond what you’ve dealt with.",
      notes:
        "<strong>NOTES:</strong> Picture yourself coming across a great prospect. You want the business bad. But you may not feel worthy of getting the business. Maybe it’s a lack of experience, or a lack of sales under your belt. Maybe you’re just in a slump. Or, perhaps the prospect is in a price range higher than you’ve ever dealt with. <br/><br/> Whatever the reason, it’s just nerves. You want the business and don’t want to mess it up. Don’t be nervous. The scripts below will help you sound like this ain’t your first rodeo. <br/><br/> The key is to not sound desperate. In fact…quite the opposite. You need to make them feel like you’re the one who needs to be impressed. <br/><br/> Take control by setting up a time to chat in person, or on phone, to assess whether you can take the business on or not, and that you’re a good fit, rather than leaping right into action for them. All you’re doing is getting them to schedule an initial meeting…your first small step in taking control of the relationship and your credibility…and painting a picture that you are in demand. Or at least not desperate ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I’d love to help you <span style='color: #ff422d;'>[find a home / sell your house]</span> ! <br/><br/> I’d also love to say that I’m the best agent <em>to</em>  help you… <br /><br/>But that depends. <br/><br/> I know most agents would just sort of jump head-first into <span style='color: #ff422d;'>[showing you homes / getting you to sign a listing agreement]</span> . <br/><br/> But to me, that’s why so many people complain about real estate agents. They jump into the process and a relationship with an agent before truly knowing if they’re a good fit for each other. <br/><br/> So, I like to just schedule a <span style='color: #ff422d;'>[phone chat / quick time to sit down and chat in person]</span>  and get a feel for what you want and need. This helps me to get a feel for who I’m agreeing to dedicate my time to. <br/><br/> I promise once we get the ball rolling, I’m 100% available to the clients I take on…but the first chance I can chat this week would be <span style='color: #ff422d;'>[insert day and time]</span> …or I could do <span style='color: #ff422d;'>[insert optional days and times]</span> . <br/><br/> <span style='color: #ff422d;'>(<strong>NOTE:</strong> Now let them choose a day and time, or suggest another.)</span>",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I’ll be beyond honored to help you <span style='color: #ff422d;'>[find a home / sell your house]</span> !.<br/><br/> As long as I’m the best agent for you…which I probably am, since I have the guts to even say that. <br/><br/> Most agents get so excited when someone even breathes the words buy or sell (or even just breathes), that they cram people in their car to go see homes, or push you to list your house on the spot. <br/><br/> It’s like going in for a kiss when you’re asking someone <em>out</em>  on a date…let alone <em>on</em>  the first date! <br/><br/> I prefer setting a date…just to chat and get to know each other. <br/><br/> Hopefully we <em>are</em>  as good of a match as I feel like we’ll be! <br /><br/> So, for now, let’s just set up a good time to chat and get a feel for what you want and need in an agent. This will help me get a feel for who I’m agreeing to go steady with…before we put a ring on the relationship. <br/><br/> Not to play hard to get, but the first time I can get together this week is <span style='color: #ff422d;'>[insert day and time]</span> …or I could do <span style='color: #ff422d;'>[insert optional days and times]</span>. <br/><br/> <span style='color: #ff422d;'>( <strong>NOTE:</strong> Now let them choose a day and time, or suggest another.)</span>",
        },
      ],
    },
    {
      title: "You’re swamped (buyers)",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a buyer prospect wants to work with you, but you’re slammed and need them to have patience before you can really get started working with them.",
      notes:
        "<strong>NOTES:</strong>  Too busy to run out and show a new client some homes… That sounds like a good problem to have, huh?! <br/><br/> The problem is, you know that if you don’t jump, they could easily go find another agent who will. Which is why so many agents out there work crazy hours, every day of the week.<br/><br/> Use this script to get the prospect on the hook enough to wait for your schedule to be free, rather than lose them (or your sanity) when you jump for them and every other client you’re juggling! <br/><br/> This gets them moving into the process with you, and shows them you’re certainly available for them…if they can adjust their schedule a bit.  ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I’m really looking forward to helping you find a home! <br/><br/> One of the things I want to assure you is that when I agree to work with someone, I’m  <em>super</em> dedicated. And, I make sure I’m available when you need me. <br /><br/> What <em>isn’t</em>  super is that I’m not super available right now. My schedule is pretty packed. <br/><br/> But, I don’t want you to have to work with another agent, just because they’ve got plenty of time on their hands either. <br/><br/> It’s not even that big of a deal once I get through the next few weeks. I could definitely give you 100% of my time and attention if you can swing looking at houses during the week. Any chance you can do that? <span style='color: #ff422d;'>( <strong>NOTE:</strong> Let them answer. Hopefully it is a yes. If they say no, add the next sentence…)</span> <br/><br/> How about this… I promise that if the home of your dreams appears in the next few weekends, I will make <em>sure</em>  to rearrange my schedule and show you that home. You won’t miss the best home, or the best deal. I just can’t promise I can spend hours showing you a bunch of houses until <span style='color: #ff422d;'>[insert foreseeable dates]</span>.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I can tell we’d work well together… <br/><br/> …which makes me want to learn magic. <br/><br/> The first trick I’d learn is how to create more time. ‘Cause some other evil magician has figured out how to cast a spell on mine and make it disappear lately. <br/><br/> I’m guessing you’ll want an agent who’s available for you 100%, 24/7. And you’ll probably want to see homes on the weekends. Which is not a problem. Usually. <br/><br/> But I’m also guessing you’ll want to start looking <em>this</em> weekend… <br/><br/> Hmm, maybe I <em>do</em>  know some magic. Is mind-reading magic? <img src='https://s.w.org/images/core/emoji/13.0.0/svg/1f642.svg'/> <br /><br/> Problem is, I’m booked this weekend… <br/><br/> I don’t want to see <em>you</em>  disappear and go work with another agent just because they aren’t busy. That’s the worst possible reason to hire an agent. So, is there any way you can work some magic and go see houses during the week instead? I promise I’m worth the wait.",
        },
      ],
    },
    {
      title: "You’re swamped (sellers)",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a seller prospect wants their house on the market immediately, but you’re swamped, or need some time to get things ready and done. This is to stop them from going with another agent, or be aggravated that it takes time.",
      notes:
        "<strong>NOTES:</strong> You know how it goes, when it rains, it pours. <br/><br/> Once you have a house listed, there’s <em>still</em>  lots to do, but it gets a little bit easier. But the upfront work to get everything done and the house on the market takes time. And sometimes you just don’t have the time to run over right away and do the best job possible for your client. <br/><br/> <span>So, this will help you gain their trust and patience so they’ll wait for you to get their house on the market properly.</span> <br/><br/> <span> Notice that you’re not mentioning that you’re busy with other work or clients. Rather, you’re focusing on doing the best job possible.</span>",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I’m as anxious as you are to get it on the market and see the buyers start coming through. <br/><br/> I know you want to get it on the market as soon as possible. And we will. But I need a little time to do the right job for you. <br /><br/> We <em>could</em>  just pop your house on the market. That’s not hard. It just takes a few minutes on the computer really. <br/><br/> It’s what too many agents do. They rush to get the house input into the system, without doing the proper prep work. There’s so much they should do before making it active on the market. <br/><br/> One big example is when agents don’t even have one good picture on the Internet. Literally, no picture. It’s ridiculous. <br/><br/> The first hours…not even days…are so important when the current buyers see your home online for the first time. <br/><br/> And pictures are just one of <em>many</em>  things I like to make sure I take my time and do right before your house goes on the market. <br/><br/> So, give me until <span style='color: #ff422d;'>[insert date]</span> . That way, I can assure you it’ll be done right, right from the start. I promise it’ll be worth the wait!",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I’m as excited as you! I can’t <em>wait</em>  to get your house on the market! <br/><br/> I’ll let you in on a little secret… <br/><br/> Patience is not my <em>real</em>  middle name. But it’s my <em>real estate</em>  middle name. Because patience pays off. <br/><br/> There’s a lot to be said for doing things right, instead of <em>right now</em> .<br/><br/> You know the saying…good things come to those who wait. I promise you, it’ll be worth your wait.",
        },
      ],
    },
  ],
  "Dealing with seller clients": [
    {
      title: "Zestimates",
      name: "Dealing with seller clients",
      id: 3,
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a seller client or prospect brings up the Zillow Zestimate for their house.",
      notes:
        "<strong>NOTES:</strong> We all know that Zestimates are inaccurate most of the time. Homeowners on the other hand…not so much. Especially if the Zestimate is higher than you’re recommending they list their house for. <br/><br/> Here are a couple of ways to quickly put that conversation to rest.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Lots of sellers bring up Zillow. Mostly when the Zestimate is higher than what they’re hearing from actual real estate agents.<br/><br/> But I’ve also heard people complain because the value of their house was too <em>low</em>  on Zillow, and they worried how that would affect buyers. <br /><br/>What I <em>haven’t</em>  heard a whole lot of people say is, “Wow, my Zillow Zestimate is exactly the same as what you’re saying the value of my home is!” <br/><br/> Obviously, you want to get as much as you can for your home! And when you see a site as popular as Zillow, you have to figure it has some merit. <br/><br/> Well, that can lead people to make big mistakes when pricing their home. Which is a shame…because Zillow actually tells people that <em>they</em> make big mistakes! <br/><br/> They actually have disclaimers on their site which state they’re off on value oftentimes. They state that they’re within 5% of the value of a home only about half the time in most areas. Most of the time, they claim to be within <em>20%</em>  of the value! In real estate, that’s a huge discrepancy. <br /><br/> In their defense, their values are computer generated. Of <em>course</em>  they’re likely to be off. They haven’t actually seen the inside of the house…they don’t even <em>sell</em>  houses; they just display them online. <br/><br/> Luckily, I pride myself on accuracy, so whatever Zillow says about your house shouldn’t hurt you…as long as you pay more attention to <em>my</em>  analysis than their Zestimate.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I feel like I should trademark the word “ <span style='color: #ff422d;'>[insert your first name]</span> stimate” and call my home valuations that! <br/><br/> <span style='color: #ff422d;'>( <strong>NOTE:</strong> For example “Mikestimate” or “Jillstimate”)</span> <br/><br/> Because people seem to totally believe a Zestimate! Gotta be something in the clever play on words, because it  <em>definitely</em>  isn’t due to their accuracy. <br/><br/> Don’t take that as me being mean or defensive. Even Zillow says they’re not accurate. Just look at their disclaimers…if you can find them. (They’re buried kinda deep on their site.) <br/><br/> Better yet, I’ll save you some time… <br/><br/> Basically, Zillow says that their Zestimates are within 5% of a home’s actual value half of the time in most areas. But most of the time, they are only within 20% of a home’s actual value! And that could be <em>20%</em> high, or 20% low! That is a HUGE spread, especially when you consider real estate values. <br/><br/> Here’s my “guesstimate”… <br/><br/> You wouldn’t trust me or hire me if I hid disclaimers that said the same thing about the accuracy of <em>my</em>  home valuations! <br/><br/> Don’t worry, I have nothing to disclaim about my analysis, other than I try to be as accurate as possible!",
        },
      ],
    },
    {
      title: "They want open houses... you don’t",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a seller wants open houses, but you don’t do them / believe in them.",
      notes:
        "<strong>NOTES:</strong> Let’s not even get into the agent-to-agent debate about whether open houses work or not… <br/><br/> Some agents love ‘em and swear by ‘em. Others <em>don’t</em> —for any number of reasons. <br/><br/> If you’re one of the latter, then this one’s for you! <br/><br/> Because even if you don’t believe in ‘em or want to do ‘em…there’s a good chance your seller clients think you should and want you to.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I completely understand why you’d expect me to hold open houses. It seems like <em>everyone</em>  has them when selling a house! <br/><br/> And if everyone’s having them, they must be useful and work… <br /><br/> The reality is, there’s probably as many agents who <em>don’t hold open houses</em>   for their listings, as there are ones who do. It just seems like they do. <br/><br/> The problem is, the agents who do hold them make it sound like you <em>should</em>  hold open houses, and imply that they “work”. <br/><br/> Well, they  <em>do</em>  “work”. Just not in the way you would probably hope or expect. <br/><br/> They work in the sense that they’re a great way for a real estate agent to meet new prospective clients. And it’s an opportunity for an agent to market themselves. <br /><br/> But the reality is, very few houses are actually ever sold as a direct result of having an open house. <br/><br/> Which is why I don’t recommend them or do them. <br/><br/> I’d rather my clients not have to leave their house for a bunch of hours, while I sit there waiting to meet some new potential clients. All for the 1% or so chance that one of the people coming in will actually buy the house. <br/><br/> So, let’s just stick with you having to leave the house when and if a truly motivated and qualified buyer makes an appointment to see the house, rather than you letting in a bunch of stragglers off the street who may not even be able to afford your house, or even be in the market.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Would you have hired me if I promised you there was a 1% chance I could get your home sold!? <br/><br/> I hope not! <br/><br/> Which is why I don’t hold open houses. There’s very little chance that your house will be sold as a result of one. <br/><br/> Now, I’m sure you could find a lot of agents who swear by them and <em>say</em> they “work”… <br/><br/> But, it’s probably more because: (Choose the one that probably <em><u>isn’t</u></em> true.) <br/><br/> They like holding them so they can meet some new potential clients.<br/>They like hangin’ around their clients’ house alone on a Sunday afternoon. <br/> Just to make their client happy. <br/> It’s a good way for an agent to market and brand themselves. <br/><br/> Anyhow, my professional opinion is that they don’t work, and won’t do you a bit of good. And even if you insist upon giving me the opportunity to meet new clients, and market myself by using your home for free all day…I won’t do it. Not even to just make you happy. <br/><br/> ‘Cause at the end of the day, you won’t be happy if it was a waste of your time, and there’s almost a 100% chance of that.",
        },
      ],
    },
    {
      title: "Asking them for listing extension",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When your listing is expiring and you need to ask for a listing extension.",
      notes:
        "<strong>NOTES:</strong> Obviously, you know (and worry) that if your listing hasn’t sold before your listing agreement expires, your client may choose to list with someone else. <br/><br/> They may or may not be thinking about doing that. So, you don’t want to put that thought into their head, in case they aren’t considering it. We blow right past that in this script and presume they’re moving forward with you. <br/><br/> While you certainly need to bring up that the listing is expiring, you don’t need to make it seem like a big deal, or that this is all that uncommon. <br/><br/> Instead, use it as a moment to get them to assess what the real problem is… ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Our current listing agreement needs to be renewed by <span style='color: #ff422d;'>[insert date]</span> , or the MLS will no longer allow your house to appear as active. <br/><br/> We have a couple of choices… <br /><br/> We could just extend the listing—doing that takes a little less paperwork. <br/><br/> Or, we could use this as an opportunity to relist it and have it come up as “new” on the MLS. <br/><br/> I tend to recommend <span style='color: #ff422d;'>[insert which you recommend]</span>.<br/><br/> Which would you prefer? <br/><br/> Of course, we can chat about it more if you have any questions. <br /><br/> Plus, it’s the perfect time for us to discuss what’s been working well so far, and assess why your house hasn’t been selling. <br/><br/> So let’s plan on getting together on <span style='color: #ff422d;'>[insert date]</span> or <span style='color: #ff422d;'>[insert date]</span>  to chat and do the paperwork.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Your house is about to turn into a pumpkin! <br/><br/> Well, not really. But just like Cinderella’s coach disappeared and turned into a pumpkin at midnight, your house will disappear from the MLS on <span style='color: #ff422d;'>[insert date]</span>  at midnight! <br/><br/> Luckily, we still have time! All you have to do is sign and date a few pieces of paper, and we can get on with finding the best fit buyer for your house…like Cinderella’s foot in a glass slipper. <br/><br/> So, mirror, mirror, on the wall, what’s the best time for y’all… ( <em>Sorry, couldn’t resist the rhyme.</em> ) <br/><br/> …to get together and do the paperwork? <br/><br/> And when we do, we can talk about what’s been working good so far, and what we can do to make sure this fairytale has a happy ending…and how we can skip ahead <em>to</em>  that ending!",
        },
      ],
    },
    {
      title: "They doubt your advice on value",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a seller client doubts your advice on the value of their home.",
      notes:
        "<strong>NOTES:</strong>  This has got to be the most common objection an agent deals with in real estate. Is there a seller in history who didn’t think their house was worth more than an agent recommended? <br/><br/> The best way to handle this is to begin by agreeing, and showing you’re willing to help them get more, and then gradually turn it around so they see it might be possible, but probably doesn’t make the best sense. <br/><br/> Either way they choose, you’re showing them you’re on board with them, but you just want them to make the best decision. And if they do want a higher sales price, you’re not mincing words in saying they’ll need to do some things in order for it to happen. ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "No problem! It’s totally your decision on how much to list your house for. <br/><br/> My responsibility is just to give you a clear picture of what your house is worth, as it is, within the current market. <br /><br/> But if you would like to get more than <span style='color: #ff422d;'>[insert your recommended value]</span> , we can certainly give it a try. <br/><br/> But if you’re going to try…you should try and do a few things to your house to make it competitive in that range. <br/><br/> In order for us to get from <span style='color: #ff422d;'>[insert your value]</span> up to <span style='color: #ff422d;'>[insert the value they want to hear]</span> , you should consider <span style='color: #ff422d;'>[insert a list of things they’d need to do… i.e. renovating the kitchen and bathrooms / replacing the carpeting with hardwood floors / paint the exterior of the house]</span> .<br/><br/> Doing that should increase the value. The question becomes…is it worth it? <br/><br/> We should analyze whether the cost of doing all that work will be worth the higher sales price, and whether the time and effort involved is something you even want to take on. <br /><br/> The real trick is <em>netting</em> as much as possible…not <em>getting</em> as much as possible. There’s a difference, and many homeowners and agents don’t focus on the “net” enough. <br/><br/> I’m certainly happy to help you get the amount you want to get, but I’d prefer helping you net the most you can. My best advice is to list it for <span style='color: #ff422d;'>[insert your recommended as-is value]</span> , rather than put all the time, effort, and money it’ll take to get you <span style='color: #ff422d;'>[insert the sellers’ desired value]</span>. In the end, it’ll probably cost you more than it’ll increase the value. <br/><br/> So, are you a “netter”, or a “getter”?",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "OK, you think your house is worth more than <span style='color: #ff422d;'>[insert your recommended value]</span> . <br/><br/> The good news is, I can do magic…I can make that happen. <br/><br/> But, I’m going to need an assistant up on stage with me… <br/><br/> So, step on up. Don’t worry, I’m not going to put you in a box and cut you in half. <br/><br/> It’s going to be quite the trick to get your house worth as much as you think it’s worth. <br/><br/> And in order for this trick to work, you’ll need to do a few things… <br/><br/> In order for us to get from <span style='color: #ff422d;'>[insert your value]</span>  all the way to <span style='color: #ff422d;'>[insert the value they want to hear]</span> , you’re going to need to <span style='color: #ff422d;'>[insert a list of things they’d need to do… i.e. renovate the kitchen and bathrooms / put an addition on the house / paint the exterior of the house]</span> .<br/><br/> Then I can make that number appear, like a rabbit out of a hat. <br/><br/> The number I came up with is just based upon the house <em>as it is</em> . However, if you’re willing to help make some magic, just say “Abra Cadabra”. <br/><br/> But before we just start waving wands, we should analyze whether the cost of doing all that work will be worth the higher sales price. The real trick is <em>netting</em>  as much as possible…not <em>getting</em>  as much as possible. <br/><br/> And I just don’t want to help you make your money disappear. That’s not a trick either one of us wants to perform.",
        },
      ],
    },
    {
      title: "Lowered your rate, they want even more",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When you gave someone a break on your commission rate, and now they’re taking advantage of you.",
      notes:
        "<strong>NOTES:</strong> So… You give someone a break on your commission, and not only do they not appreciate it, they end up being more work, and tougher to deal with than someone who paid you every cent you expect and deserve. <br/><br/> Most of the time you probably just say nothing, suck it up, and just deal…but, man, sometimes you want to tell ‘em exactly where to go. <br/><br/> Problem is, it probably wouldn’t come out right. <br/><br/> Hopefully this script will help you say just the right words, just the right way, so they straighten out and appreciate everything you’ve done, and are doing for them from here on out.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "When we first began working together, I agreed to work with you for a lower commission than I normally charge. <br/><br/> I did it gladly, because I knew you’d appreciate it. <br /><br/> So I’m bringing this up so you can appreciate where I’m coming from… <br/><br/> Even though I gave you a better rate than normal, I have no intention of cutting corners, or doing any <em>less</em>  of a job than I do for all of my clients who are paying me my full rate. <br/><br/>  The problem is, at this point, I’m actually doing <em>more</em>  than I do for my clients paying my full fee. <br/><br/> I’m sure you just weren’t aware of it. And I don’t have any problem going above and beyond what I typically do… <br/><br/> But, if I’m going to continue to do that…perhaps we can revisit my fee and amend it to be what I normally charge. Not <em>more</em> …just <em>as much</em> as I normally do. <br /><br/> Otherwise, if you don’t mind, we can just keep my lower fee in place, but I’ll have to stop <span>[insert the problem]</span>  as we move forward. <br/><br/> I’m certainly happy to help you get the amount you want to get, but I’d prefer helping you net the most you can. My best advice is to list it for <span style='color: #ff422d;'>[insert your recommended as-is value]</span> , rather than put all the time, effort, and money it’ll take to get you <span style='color: #ff422d;'>[insert the sellers’ desired value]</span>. In the end, it’ll probably cost you more than it’ll increase the value. <br/><br/> So, are you a “netter”, or a “getter”?",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Remember wayyyy back in the beginning when you were able to sweet talk me into working for a lower commission than I normally do? . <br/><br/> Actually, it wasn’t your sweet talkin’ that made me agree to it. I really wanted to do that for you because I knew you’d appreciate it. <br/><br/> Not sayin’ you <em>don’t</em>  appreciate it… <br/><br/> But, you probably don’t <em>realize</em> that I’m actually throwing in the kitchen sink for you at this point by <span style='color: #ff422d;'>[insert what’s been driving you a bit crazy]</span>. <br/><br/> I don’t even do that for my clients paying me my <em>full</em>  fee.<br/><br/> I know you didn’t expect me to cut corners and do <em>less</em>  of a job for you when I charged you a lower fee…so I’m sure you also don’t expect me to do <em>more</em>  than I would for my typical fee either. <br/><br/> So…just giving you the heads up. You probably weren’t even thinking it was above and beyond .<br/><br/> Hey, look, I’m still happy to go above and beyond… <br/><br/> But if I’m gonna, maybe we can revise that lower fee to at least pay me what I normally charge.",
        },
      ],
    },
    {
      title: "You need a break",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When you need to disappear for a while when you’re in the middle of working with a seller who’s house is on the <em>market</em>.",
      notes:
        "<strong>NOTES:</strong> Ideally, you’ve always got a listing or two (or tons) on the market. Luckily, listings don’t take as much physical presence as working with buyers. <br/><br/> But it still makes sense to let your clients know that you’re going to be away, and that there’s nothing to worry about because you’ve got it covered. (And, in the case of the second response type…use a little levity to gain some love from your clients.) <br/><br/> Problem is, it probably wouldn’t come out right. <br/><br/> Hopefully this script will help you say just the right words, just the right way, so they straighten out and appreciate everything you’ve done, and are doing for them from here on out.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Just wanted to let you know that I’ll be away between <span style='color: #ff422d;'>[insert dates]</span>. <br/><br/> This shouldn’t affect you at all, since I’ll still be able to handle <em>almost</em>  everything remotely. <br /><br/> But, in the off chance that something comes up and needs to be handled locally, or in person, I’ve arranged for my colleague <span style='color: #ff422d;'>[insert name]</span> to be available. <br/><br/> Of course, if you have any questions or concerns, just let me know, and we can certainly discuss them before I leave.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "You’re in luck! I’m whipping out a real estate marketing tactic to get your home sold, that few agents are ever able to pull off for their clients! <br/><br/> I’m going away between <span style='color: #ff422d;'>[insert dates]</span>… <br/><br/> Yup. It’s not an easy feat to pull off. Few agents are able to <em>ever</em>  get away, but I am! <br/><br/> And, I’m doing it for you! (OK, and a little bit for me…) <br/><br/> Because, as legend has it, if a real estate agent goes away, inevitably, (or allegedly), the houses they have listed get offers. So, hopefully this will be legendary. <br/><br/> Don’t worry! If you do get an offer, I’ll still be ready and able to handle almost anything remotely. But, if something needs to be handled locally, or in person, I’ve lined up my colleague <span style='color: #ff422d;'>[insert name]</span>  to lend a hand…since <span style='color: #ff422d;'>[he / she]</span>  isn’t as dedicated to such creative marketing tactics as I am, and will be in town that whole time. <br/><br/> Anyhow, let’s hope these legends are true! I’d love a solid proof that I should get away more often, as much as I’m sure you’d like to see your house go under contract! ",
        },
      ],
    },
  ],
  "Dealing with buyer clients": [
    {
      title: "Zestimates",
      name: " Dealing with buyer clients",
      id: 4,
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a buyer client or prospect wants to use a low Zillow Zestimate as justification for a low offer.",
      notes:
        "<strong>NOTES:</strong> The only thing worse than dealing with a seller who wants to list their home too high because of a Zillow Zestimate, is a buyer who wants to come in with a low offer based upon a low Zestimate. <br/><br/> Here are a couple of scripts to help you overcome it when it happens.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Obviously you want to get a house for as low as possible, or at least not overpay. <br/><br/> So when buyers see a Zillow Zestimate that’s lower than what a seller is asking, they want to use it as justification for coming in with a lower offer. <br /><br/>Unfortunately, that often backfires on them… <br/><br/> It isn’t their fault. They probably just never read the disclaimers Zillow has about the accuracy of their Zestimates. And not surprisingly, the disclaimers are pretty hard to find. <br/><br/> Rather than you having to go hunt them down, I’ll give you a quick summary… <br/><br/> They actually have disclaimers on their site which state they’re off on value oftentimes. They state that they’re within 5% of the value of a home only about half the time in most areas. Most of the time, they claim to be within <em>20%</em>  of the value! In real estate, that’s a huge discrepancy. <br /><br/> Most agents know this, and a lot of sellers do too. So if we come in with a low offer based upon the Zestimate, it’s more likely to hurt your cause than to help it. <br/><br/> You’re better off coming in with an offer based upon the data in the local MLS. That’s more accurate.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Do you know how Zillow comes up with their Zestimates? <br/><br/> Would you believe me if I told you former Vice President of the United States, Al Gore, spouts them off the top of his head as he’s playing guitar at night? <br/><br/> Probably not, right… <br/><br/> But it’s true. Al Gore rhythms play a huge role in Zestimates! <br/><br/> OK, OK, it’s not Al Gore rhythms…it’s algorithms. Horrible joke, I know. You were right not to believe me. <br/><br/> But you also shouldn’t believe a Zillow Zestimate either! Those are as off as my joke was! <br/><br/>Algorithms sound super technical, because they are. But that doesn’t mean they’re accurate. And they’re not. Zillow even says so in their disclaimers. <br/><br/> So, if we use the Zestimate as a basis for coming in with a low offer, there’s a good chance the seller and their agent are going to think your offer is a joke, and that probably won’t get you any laughs—OR the house.",
        },
      ],
    },
    {
      title: "Prepping them for home inspection",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> With a buyer client before their home inspection.",
      notes:
        "<strong>NOTES:</strong> Doesn’t matter which side of the equation you’re on—representing a seller or a buyer—you probably cringe thinking about how a buyer will react to their home inspection. <br/><br/> There are certainly things, and times, when it makes sense for a buyer to ask for repairs, things to be replaced, or a credit. But wayyyyy too often, buyers get too scared and want to back out of a deal, or end up asking for lots of ridiculous things based upon their home inspection report. <br/><br/> These should help you set the right expectations and tone when dealing with a buyer <em>before</em> their home inspection, so you don’t have to deal with a bigger mess down the road.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Home inspections are meant to protect you from buying a house with major issues. Because sometimes, a house can look fine, but a home inspector may find something we may never have noticed. <br/><br/> The good news is that if we do find that there is any major structural or operational problem with the house, we can ask for it to be repaired, replaced, or perhaps credited. So, even if there is a problem, don’t sweat it. Better to find it now, than once you own it. <br /><br/>But the key word is <em>“major”…</em> <br/><br/> A home inspector will note quite a few things “wrong” with almost any house you could be buying (yes, even brand spankin’ new homes). Inspectors tend to list every little thing they find because they don’t want anyone coming back to them saying they missed a problem. <br/><br/> But just because they put it in the report, doesn’t mean it’s an issue you should be asking the seller to repair, replace, or credit <br/><br/> In fact, asking for too much, or asking for the wrong things, can actually work against you, and cause the seller to say no to some things you really should be getting them to address. Or worse, they may even decide to kill the deal altogether if they feel you’re being too unreasonable or even greedy. <br /><br/> So, once we get the inspection report, let’s discuss it, and decide on what you should go after, and what would be better to let slide.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I’m looking in my crystal ball, and I see something I feel I should make you aware of before you see the home inspection report… <br/><br/> There’s problems! Lots of ‘em. <br/><br/> But wait…I see more… <br/><br/> Phew. The problems are all <em>minor!</em> Nothing you should be all that worried about. You must be relieved!<br/><br/> Ok, ok…I don’t have a crystal ball. That’s just how most inspections go. Been there, done that enough times, so I can pretty safely guess that’s how yours will go. <br/><br/> But, <em>if</em> there’s anything “major”, don’t even sweat that! That’s the best time to catch it, not once you own the house and live in it. And we can ask the seller to address any major problems <br/><br/>What you shouldn’t do, though, is ask for the owner to repair, replace, or credit Every. Single. Little. Problem that the home inspector notes in the home inspection report. Because, I’ve seen that happen (also in person and not in a crystal ball!) and it ain’t pretty! It backfires on the buyer and they get less than they could’ve or should’ve from the seller. <br/><br/> Sometimes it makes sense to ask for things; sometimes it doesn’t. Hard for me to say until we see your inspection report. Once we have it, we’ll chat and decide what’s worth asking for and what’s not.",
        },
      ],
    },
    {
      title: "Opinionated family / friends",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When you’re dealing with multiple people (and their multiple opinions)…like with 5 siblings on an estate sale.",
      notes:
        "<strong>NOTES:</strong>  Nothing’s worse than dealing with a couple who can’t agree on anything when you’re helping them sell their home… <br/><br/> Oh wait, yes there is! When you’re dealing with an entire family on the sale of a home. <br/><br/> Ever find yourself dealing with the sale of an estate, and you have one really motivated sibling? <br/><br/> Then there’s the brother all the way across the country who wants to hold out for beyond top dollar. And then there’s the sister-in-law who always wants to chime in because she used to be an agent and sold two homes in her career before calling it quits…but still, she knows what you should be doing differently. <br/><br/> You get the drift <br/><br/> Sometimes you need to deal with a whole lot of people, who have a whole lot of opinions, and a whole lot to say. <br/><br/> Instead of being in constant limbo with no firm decision or progress is being made, and dealing with everyone on some level, change the tone with this script. Put it on them to come to consensus, without you having to be the the “bad guy” on any particular decision that you need them to make..",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "What you’re going through is totally normal. You have a lot of people involved in a big decision, and everyone has their own opinions and situations to consider. <br/><br/> And everyone <em>should</em> have their say. <br /><br/> The problem is, it can often lead to no decisions, and no forward progress being made. <br/><br/> So, here’s an idea… <br/><br/> What I’ve seen work well in the past is for everyone to have their say, but then have everyone take a vote. <br/><br/> So, <span style='color: #ff422d;'>[sit down together / have a conference call]</span> and share your opinions. Then take a vote. And please let me know by <span style='color: #ff422d;'>[insert day or time you want their answer]</span>. <br /><br/> By the way, before you place your votes… <br/><br/> Since you hired me for my professional opinion, here’s my two cents on <span style='color: #ff422d;'>[insert what it’s about: i.e. lowering the price / accepting the offer / what you should counter-offer]</span>, because <span style='color: #ff422d;'>[insert your reasoning or back up for your recommendation]</span>",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I’m sure you’ve all heard the term, “too many cooks in the kitchen”. <br/><br/> So I’m gonna step on outta the kitchen. <br/><br/> I think the best way to help you all make the best choice is to leave you with my “recipe”. (I’m a pretty good “cook” when it comes to the real estate food group.) <br/><br/> My “recipe” is this — <span style='color: #ff422d;'>[insert what you recommend they do in the particular decision they are making]</span>.<br/><br/> Of course, some people don’t like to use recipes, so feel free to come back to me with a different “dish”-ision. (Couldn’t resist!) <br/><br/> So, throw all your individual thoughts on the table like ingredients, mix ‘em all up, and get back to me with the “dish” you all come up with together. <br/><br/>I’d love it if “dinner” was served by <span style='color: #ff422d;'>[insert date and time you want to know by] </span>!",
        },
      ],
    },
    {
      title: "Parents of first-time buyers butting in",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client’s parents keep butting in, and getting in the way of things, when you’re working with a first-time buyer.",
      notes:
        "<strong>NOTES:</strong> Your clients have found the perfect house. They want it soooo bad. The search is over! They want to make an offer! But first, they “just need to show their parents the house”. <br/><br/> The minute you hear that, you just know that the home search far from over… <br/><br/> Because every time you get to this point, the parents have shot down the house for any number of reasons. <br/><br/> It’s going to sound like you’re volunteering for a lot more work with this one, but you’re actually saving yourself a lot of work in the long run. <br/><br/> Your client probably doesn’t want to go back to square one anyway. They’ll probably be concerned about losing the house if they don’t act quickly enough. And their parents probably don’t want to go see every house they don’t want to buy…and <em>don’t</em> want to see their kid lose a house because they took too long to act. <br/><br/> So, your client will be more inclined to stand their ground with their parents and move forward… <br/><br/> And both your clients and their parents will feel like you’re more than glad to have them involved…in fact, more involved than they probably want to be.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I love that your parents want to make sure you’re making a good decision. <br/><br/> But I don’t think we should show them this house… <br /><br/> …without showing them <em>all of the houses</em> we’ve looked at. <br/><br/> As long as they can spare the time soon, and we can get through them all quickly, we should still be able to make an offer before anyone else puts it under contract. <br/><br/> So, check with them and see if they can be available on <span style='color: #ff422d;'>[insert a date a few days away]</span>. That’s the next day I can set aside enough time to show that many houses in a row to you and your parents. <br/><br/> If that works for all of you, let me know, and I’ll schedule all of the showings. If not, the next best day for me to do it is <span style='color: #ff422d;'>[insert a day beyond that date]</span>.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I think your parents secretly want you to live at home until <em>you have kids looking to buy a house!</em> LOL <br/><br/> OK, let’s do <em>this…</em> <br/><br/> If we just show ‘em <em>this</em> house, it’ll be like showing them what looks like a little ice cube floating in the ocean. But the perfect little ice cube isn’t floating on the surface… It’s attached to the rest of the iceberg <em>under the surface</em>. <br/><br/> Same thing with this house. The reason this one rose to the top and looks perfect is because of everything else we’ve seen that <em>wasn’t</em> perfect.<br/><br/> Your folks need to see all of those, in order to appreciate how perfect this one is. <br/><br/> So, let’s show <em>‘em all of the houses</em> you’ve seen! <br/><br/>Don’t worry, as long as they can get out with us soon, and we can see them all quickly, you shouldn’t miss the boat on this house. (That’d be a Titanic disaster!) <br/><br/> So, see if they can take a “three hour tour” (or maybe a bit more), on <span style='color: #ff422d;'>[insert a date a few days away]</span>, ‘cause that’s the next day I can show that many houses in a row. <br/><br/> If that doesn’t work for them, let’s shoot for <span style='color: #ff422d;'>[insert a date beyond that date]</span>.",
        },
      ],
    },
    {
      title: "Wanting to look over approved amount",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client wants to look at houses over budget or approved amount.",
      notes:
        "<strong>NOTES:</strong> You wanna scream, “No! You’re wasting my time! Believe me, if you could afford higher, I’d be right there with ya looking at them, because I’d love a bigger commission.” <br/><br/> As true as that might be, it won’t go over well. You realize that. <br/><br/> The trick is to focus on how you’re looking out for their best interests, and you aren’t living up to the (inaccurate) reputation agents have for showing people houses above their budget.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I know you’re probably hoping you can find the perfect home, if we just expand the search a little higher. <br/><br/> And you might <em>find</em> the “perfect” home by doing that. But what won’t be perfect is that you won’t be able to <em>buy</em> it <br/><br/> While I appreciate your apparent confidence in my negotiating abilities, I can’t see being able to get that home down below what you’re approved for. <br/><br/> If we come across one that I’m confident I could negotiate down enough, or is simply priced too high, we can look at those. <br/><br/> But otherwise, let’s stick to looking at the homes you can definitely afford.<br/><br/> I’d hate to see you fall in love with a home and not be able to buy it. Or, fall in love with one, figure out a way to buy it, and then struggle to make ends meet <br/><br/> Not trying to be a stick in the mud, just looking out for your best interests. ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "OK, before we go see that house, I’m going to need you to sign a bunch of affidavits in front of a witness, while we record it, and then post it on Youtube, Facebook, Instagram… <br/><br/> Just kidding… <br/><br/> The funny thing is, lots of people accuse agents of trying to show buyers more expensive homes than they want to see, or can afford. <br/><br/> Maybe some agents do… <em>but I don’t</em>. And I’d want to make sure you—and everyone else—knows that I’m not pushing you to look at higher priced homes against your will. <br/><br/> Quite the opposite… <br/><br/> I want to push you to not look at homes above what you’re approved for. <br/><br/> I just don’t want to see you fall in love with a home and not be able to buy it. That sorta heartache should be left way back in your junior high school memories. <br/><br/> Or worse, watch you figure out a way to stretch your budget like Silly Putty, and then struggle to afford it. <br/><br/> How about this… <br/><br/> If there’s a home above your price range I just know I can negotiate the heck out of, or it’s priced higher than it should be, I promise you we’ll look at it. <br/><br/> So, let’s just stick to looking at ones in your price range, and find the best one in the bunch. <br/><br/> Otherwise, let’s just focus on the ones you can buy easy-breezy, and get you the best one possible. <br/><br/> Deal?",
        },
      ],
    },
    {
      title: "Doubts your opinion on offer amount",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a buyer client doubts your advice on the value of a home, or questions your advice on how much to offer.",
      notes:
        "<strong>NOTES:</strong> Let’s face it, if your opinion is that the house is worth less, and you’re advising going in with a lower offer, your client probably isn’t going to give you any grief. They’ll probably be more than happy and agreeable. <br/><br/> Let’s face it, if your opinion is that the house is worth less, and you’re advising going in with a lower offer, your client probably isn’t going to give you any grief. They’ll probably be more than happy and agreeable. <br/><br/> The key here is to empathize with them. Acknowledge they want to get the house for as low as possible. Show them you’re willing to write as low an offer as they want. They don’t want to be pushed or convinced by the person they want to know is advocating for them. They need to feel you’re on their side. <br/><br/> But, as you’re agreeing to do that, make sure they understand and think through that they may risk not getting the house at all. Because, by making them think that through, you’re truly being on their side.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Obviously we want to try and get the house for the lowest price possible. That’s the name of the game, right? <br/><br/> So I totally understand why you want to come in that low. <br/><br/> So I totally understand why you want to come in that low. <br/><br/>One thing that’s going to be tough is that I don’t have any data to back up an offer that low.<br/> I’ve looked for anything I can use to justify <span style='color: #ff422d;'>[insert the amount they want to offer]</span>, but it’s not even in the ballpark of what similar houses have sold for. And of course the sellers (and their agent) know that. <br/><br/> So, we probably should brace ourselves for the owner to feel like you’re just “low-balling” and don’t really love their house… <br/><br/> So, we probably should brace ourselves for the owner to feel like you’re just “low-balling” and don’t really love their house… <br/><br/> So, I guess I should ask… <br/><br/> So, I guess I should ask… <br/><br/> So, I guess I should ask… <br/><br/> Do you even <em>want</em> this house?",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Holy <em>smokes!</em> You must be phenomenal at doing the Limbo! I can just see the emcee now… <br/><br/> “How lowwwww can you gooooo?!??!”<br/><br/> And you’re like,<span style='color: #ff422d;'> [“Watch me!” / “Here…hold my beer!”]</span> <br/><br/> And everyone, including the emcee is in awe, hootin’ and hollerin’ and clappin’ up a storm when you get your back to an inch of the floor. <br/><br/> Hahaha… <br/><br/> Hopefully the sellers and their agent will react as well to this low of an offer, because you’re really trying to show ‘em how low you can go! <br/><br/> But I get it. That’s the name of the game, right? Buy real estate for as low as possible. So it’s worth the try. <br/><br/> One thing that might get in the way is that I don’t have any data to back up an offer that low. <br/> I’ve looked for anything I can use to justify <span style='color: #ff422d;'>[insert the amount they want to offer]</span>, but it’s not even in the ballpark (or Limbo line) of what similar houses have sold for. <br/><br/> So, just brace yourself for the owner to feel like you’re just “low-balling” and don’t really love their house… <br/><br/> If they do, they might not counter-offer at all, or budge as much on the price as they would have just out of pride. Heck, some owners have turned down full price offers from buyers who came in too low at first. <br/><br/> So, I guess I should ask… <br/><br/>Are you ok with losing the house entirely if they’re that annoyed or upset? If so, let’s get ready to Limbo! <br/><br/> But if that’s the case… <br/><br/> Do you even <em>want</em> this house?",
        },
      ],
    },
    {
      title: "You need a break",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When you need to disappear for a while when you’re in the middle of working with a buyer looking for a house. Use this when you can still help them remotely, but would just need to arrange for someone to get them in to see the house, since you can’t physically be there.",
      notes:
        "<strong>NOTES:</strong> It’s inevitable, you almost always have at least a buyer or two looking for houses. You can’t plan life events around the rare (and undesirable) moments when you don’t have any buyers looking for houses. <br/><br/> Whether it’s a vacation, an out-of-area wedding, or any other planned or unplanned reason you need to be away, you worry about your buyers finding a house with another agent. <br/><br/> Rather than swearing off ever going away, use these scripts to let your clients know you’ll be away, how you plan to accommodate them to ensure they’ll be sticking around when you return.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Just wanted to let you know that I’ll be away between <span style='color: #ff422d;'>[insert dates]</span>. <br/><br/> Don’t worry! If there are any houses you want to see during that time, I’ve arranged for my colleague <span style='color: #ff422d;'> [insert name of colleague] </span>to get you in to see them. <span style='color: #ff422d;'>[Insert how appointments will be set up…give them the agent’s contact info, or ask them to reach out to you and you will set it up.]</span> <br/><br/> But, if any of those houses are your dream home, and you want to make an offer, I’ll still handle any discussions about your offer, writing up the offer, and negotiating it. <br/><br/> You’ll be in good hands with <span style='color: #ff422d;'>[insert name again]</span>, but if you have any questions or concerns about this arrangement, just let me know!",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I’m about to do the real estate agent equivalent of a rain dance! <br/><br/> Basically, I’m <em>assuring</em> you that you’re going to find the house of your dreams sometime between <span style='color: #ff422d;'>[insert dates you will be away]!<span> <br/><br/> How can I be so sure? <br/><br/> Because I’ll be away. And every time I go away, that’s when my buyer clients’ dream homes come on the market. <br/><br/> Not to worry—I’ve got you covered! <br/><br/> I’ve lined up an associate who’ll be sitting by the phone, and getting you into any house you want. <span style='color: #ff422d;'>[Insert how appointments will be set up…give them the agent’s contact info, or ask them to reach out to you and you will set it up.]</span> <br/><br/> And if you do find the “the one”, I’ll take it from there! I’ll be available to discuss your offer, write it up, and negotiate it.",
        },
      ],
    },
    {
      title: "No luck finding the right home",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> Keeping a buyer who’s having a hard time finding a house—or getting offers accepted—to stick with you.",
      notes:
        "<strong>NOTES:</strong> Sometimes a buyer is super picky, or just wants to keep looking and looking and looking at house after house, and never makes a decision. <br/><br/> But other times, a buyer is truly motivated, and really wants to find the perfect house and get their offer accepted. If you have a buyer who’s super motivated, but there just isn’t the perfect house on the market, or they keep losing out in multiple offers, it can get frustrating for them. (You too of course!) <br/><br/> Use this to let them know you feel their pain, and to keep them loyal to you.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "People think finding the perfect house is quick and easy. <br/><br/> Sometimes it is…<em>if</em> there’s lots of houses to choose from, and not a lot of competition. <br/><br/> But in your price range and the areas you’re focusing on, there just hasn’t been a whole lot of houses to even see, let alone go after. And the ones that are worth going after have lots of interest. <br/><br/> Don’t get discouraged. We’re as on top of the market as we can be! Look at it this way…so far, none of the houses we’ve seen were “meant to be”. <br/><br/> You will find (and get) the house that’s meant to be…for <em>you</em>. <br/><br/> I know real estate agents have a reputation for being pushy, and out to make the quickest sale possible. To me, that’s just wrong. I’m in it for the long haul with my buyers. <br/><br/> Especially when it isn’t going quickly or easily! Because that’s when many buyers make rash, and less-than-the-best decisions. And that’s one thing I make sure my buyers do not do. <br/><br/> So be patient! I assure you, I won’t be pushing you to make a bad choice. Quite the opposite, I’m going to be making sure you make the best choice! Even if it does take longer than we want it to.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "If <em>only</em> buying a home were as easy as they make it look on HGTV! Right?! <br/><br/> Look at three houses, go have a relaxing cocktail and some lunch, eliminate a couple of houses, choose one, make an offer, and voila… half an hour later you’re in your new home! <br/><br/> Meanwhile, in real life, we’re having trouble even finding enough good ones to look at, and the ones you do like have tons of competition. <br/><br/> The good news is this…<br/><br/> That’s why I have a job. (OK, really that’s more like good news for me…)<br/><br/> The good news for you is this… <br/><br/> I don’t (and won’t) give up! We’ll find the best house possible and get you into it! All it takes is being patient (which we’ve certainly been doing), staying on top of the market (which we’ve also been doing), and jumping the minute a good one hits the market (also been doing that)! <br/><br/> So, the key thing is to just be patient. I promise you, I pride myself on being patient, so my clients end up getting the best home possible.<br/><br/> And you know what I always end up hearing?<br/><br/> That patience paid off. ‘Cause the home they ended up getting was one they liked more than any they’d seen before. <br/><br/> So, it may be taking us longer than a half hour to easily find your dream home, but I’m sure you’re gonna be saying it paid to be patient not too long from now!",
        },
      ],
    },
    {
      title: "Make an offer already!",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a buyer wants to keep looking…and looking…annnnnd loooooking..",
      notes:
        "<strong>NOTES:</strong> Some buyers seem to want to see <em>every single house on the market</em> before making a decision. And some of them <em>still</em> want to keep looking, after seeing everything on the market. <br/><br/> They’re probably just hoping to find “the” house. The one that “speaks” to them. The one they “just knew was the one” the minute they walked in. <br/><br/> But what they’re really waiting for is a minor miracle. <br/><br/> Most buyers who keep on looking and looking will never find “the” one, at least without a little push and some perspective. So, give it to them… <br/><br/> By pushing them to assess and consider which ones they’ve seen were the best, and at what price they’d be happy to get it, you’re pushing them toward the next step of making offers.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "At this point, we’ve seen everything there is to see on the market that fits your wants, needs, and budget. <br/><br/> Now we’re at a point where we’re just waiting for, and looking at, anything new that comes on the market.<br/><br/> In my experience, anything new that comes on probably won’t be much different than what we’ve been seeing.<br/><br/> While we’re waiting for something new to pop on, it makes sense for us to go back through everything we’ve looked at.<br/><br/> So, look back through all of the listings we’ve seen, and choose your top <span style='color: #ff422d;'>[insert a number, i.e. 3 / 5 / 10]</span> houses. Then put those in order of which one you like best, then next best… <br/><br/> I have some in mind that I think are great choices for you, in my experience as an agent, and specifically as your agent. <br/><br/> We’ll compare and see which ones we both chose as top picks—those mean something. You liked them as a buyer, and I liked them from an agent’s point of view. That’s a good sign! <br/><br/> We’ll use that as our list of houses to make offers on. <br/><br/> <span style='color: #ff422d;'>(NOTE: If they resist doing this, you should probably have a frank chat about their expectations.)</span>",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "You probably rock at Easter egg hunts! Not a chance there’s an egg that goes unfound if you’re around. <br/><br/> At this point, we’ve seen everything there is to see on the market that fits your wants, needs, and budget.<br/><br/> We can keep looking, but it’d be like looking for one last hidden Easter egg, after they’re all in the basket and accounted for.<br/><br/> And the chances of anything better coming on between now and two Easters from now, are as good as the Easter Bunny being real.<br/><br/> So now we need to look through the eggs in the basket and pick our favorites.<br/><br/> How ‘bout this… <br/><br/> You look back through all of the listings we’ve seen, and choose your top <span style='color: #ff422d;'>[insert a number, i.e. 3 / 5 / 10]</span> houses. Then put those in order of which one you like best, then next best… <br/><br/> I have some in mind that I think are great choices for you. So we’ll compare and see if we think like twins… <br/><br/> Then we’ll have a solid list of houses we can make offers on.<br/><br/> <span style='color: #ff422d;'>(NOTE: If they resist doing this, you should probably have a frank chat about their expectations.)</span>",
        },
      ],
    },
  ],
  "Dealing with clients (buyer or seller)": [
    {
      title: "Dual Agency (For It)",
      name: "Dealing with clients (buyer or seller) ",
      id: 5,
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client or prospect wants or expects you to handle both sides…or whenever the topic comes up at a cocktail party, etc.",
      notes:
        "<strong>NOTES:</strong> Different agents have different perspectives on dual agency. This script is for those of you who are against it. (We’ve got another script for those of you who are for it.) <br/><br/> The reality is, most consumers don’t have a firm belief one way or the other about dual agency. Sure, they probably have some perspective and opinion, but they’re likely to be influenced by what they hear from people within the industry. <br/><br/> So use these scripts to establish how you feel about dual agency, either during the course of dealing with someone who seems to think dual agency will give them an advantage, or just in the course of life when a good chance to discuss it comes up. ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I can totally understand why you’d wonder if I can represent both you and the <span style='color: #ff422d;'>[seller / buyer]</span>. <br/><br/> It’s good to question because not all real estate agents <em>can</em> do it well, and probably shouldn’t do it at all. It takes a good amount of knowledge and skill to do it well. <br/><br/> But when an agent does have the knowledge and skill to perform dual agency, it can work out well for both you and the <span style='color: #ff422d;'>[seller / buyer]</span>. <br/><br/> Fortunately, I’m completely comfortable and capable of handling both sides of the deal. Of course, both you and the <span style='color: #ff422d;'>[seller / buyer]</span> have to also be aware (and comfortable with the fact) that I’m doing so. <br/><br/> If you’re not comfortable with it, just let me know and I’ll only represent <span style='color: #ff422d;'>[you / the seller / the buyer]</span>. <br/><br/> But I’m sure I can help you both come to a fair deal that you’ll all be happy with!",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Now, I’m not a marriage counselor (although it kinda feels like it sometimes when you’re a real estate agent! LOL)… <br/><br/> …but if I were a marriage counselor, my job would be to make sure both people had their point of view heard. I’d be trying to make the marriage better and keep the couple together (and hopefully happy!). <br/><br/> I certainly wouldn’t be taking sides! <br/><br/> That’s what it’s like for me when I’m practicing dual agency in real estate… <br/><br/> I help both sides have their say, see the other side’s point of view, and ultimately be happy with the outcome.<br/><br/> I won’t take sides. <br/><br/> In fact, I kinda see having two agents involved as more like a divorce waiting to happen! Both agents are trying to “win” for <em>their</em> client, instead of one agent being able to truly help both clients find the win-<em>win</em>!",
        },
      ],
    },
    {
      title: "Dual Agency (Against It)",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client or prospect wants or expects you to handle both sides…or whenever the topic comes up at a cocktail party, etc.",
      notes:
        "<strong>NOTES:</strong> Different agents have different perspectives on dual agency. This script is for those of you who are against it. (We’ve got another script for those of you who are for it.) <br/><br/> The reality is, most consumers don’t have a firm belief one way or the other about dual agency. Sure, they probably have some perspective and opinion, but they’re likely to be influenced by what they hear from people within the industry. <br/><br/> So use these scripts to establish how you feel about dual agency, either during the course of dealing with someone who seems to think dual agency will give them an advantage, or just in the course of life when a good chance to discuss it comes up.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Lots of people feel like it’s no big deal for an agent to represent both the buyer and the seller. <br/><br/> Some even think it’s a <em>good</em> thing. I don’t… <br/><br/> While it’s legally OK for an agent to do it—as long as both parties are aware of it and are OK with it—I don’t think it’s possible to do the best job possible for either side, let alone <em>both</em> sides. <br/><br/> My job as a <em>buyer’s</em> agent is to get my client the house they want for the <em>lowest</em> possible price and the best terms. <br/><br/>My job as a <em>seller’s</em> agent is to get the <em>highest</em> possible price for my client’s house and the best terms. <br/><br/> I can’t possibly do both of those things for both parties. Nor can any other agent. It’s an impossibility and also a conflict of interest in my opinion. <br/><br/> Which is why I just want to focus on representing <span style='color: #ff422d;'>[you / the seller / the buyer / my original client / my client / one party in a transaction]</span>. Makes more sense that way, right?",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Everybody jokes about how every kid gets a trophy nowadays. <em>Everyone’s</em> a winner! <br/><br/> It <em>sounds</em> nice and good, and I guess it kinda is in certain ways. I mean, we’re talking about young kids and their feelings after all. <br/><br/> That’s how a lot of real estate agents treat what we call dual agency, or handling both the buyer and the seller in a deal. They’re promising both clients that they’ll both “win”…a “win-win” as they say. <br/><br/> I guess that’s fine if all you want is a win-win. <br/><br/>But we’re not talking about kids and youth sports! We’re talking about the largest sale or purchase most people make in their lives! <br/><br/> To me it’d be like saying, “I could’ve gotten you <span style='color: #ff422d;'>[a better deal on the house you’re buying / more money for the house you’re selling]</span>, but I also had to make sure I got a good deal for my other client. You’re both winners!” <br/><br/> So why would an agent be OK doing that? Might be because they’re the ultimate winner getting both sides of the commission! LOL <br/><br/> I wonder if they’d be so “for” it, if all they got was a participation trophy!!! LOL <br/><br/> Anyhow, that’s my take on it. Hopefully you’ll pick me to be on your team like they used to do for kickball in gym back in the day. I’m pretty good at kickin’ home runs.",
        },
      ],
    },
    {
      title: "Unhappy camper",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client is not happy with how things are going, your work, what you’re doing, or what you’re <em>not</em> doing.",
      notes:
        "<strong>NOTES:</strong> Buying or selling a house can be super frustrating, and an anxiety-ridden time for your clients. And, as we know all too well, their frustrations and anxiety can be taken out on you. <br/><br/> There’s a good chance that whatever you’re doing (or not doing) isn’t the reason they’re not successfully buying a house, or getting theirs sold. And you probably want to defend yourself. <br/><br/> There’s a good chance that whatever you’re doing (or not doing) isn’t the reason they’re not successfully buying a house, or getting theirs sold. And you probably want to defend yourself. <br/><br/> After all, you know what you’re doing. Heck, half the time the problem is because a client won’t listen to your advice, or trust how you do things. <br/><br/> But it might not make the most sense to lash out at your client. Rather than get dismissive, or defensive, be open to their suggestion(s). At least they’ll feel you’re listening to them and trying to make sure they’re happy. <br/><br/> And who knows, maybe, just <em>maybe</em>, whatever they suggest will be an idea you never thought of.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I’m glad you spoke up about how you feel! <br/><br/> I prefer that to a client who doesn’t say anything. <br/><br/> I want every client to be absolutely happy with my services. And I can’t make an unhappy client happy, if I don’t know what’s on their mind. <br/><br/> Let’s just make sure we’re on the same page… <br/><br/> <span style='color: #ff422d;'>[Insert / repeat back what it is the client is unhappy about.]</span> <br/><br/> This is what <span style='color: #ff422d;'>[I’m doing / I usually do / I propose we do]</span> to solve that… <br/><br/> <span style='color: #ff422d;'>[Insert what you’re doing / planning on doing / suggest doing to resolve it.]</span> <br/><br/> But I want to take your suggestions into consideration as well! Sometimes it helps to have an outside opinion. You get used to doing things a certain way in this business, and I’m always open to new ways of doing things. <br/><br/> So, do you have any suggestions beyond what I suggest?",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I’m glad you spoke up about how you feel! So many clients don’t speak up. <br/><br/> I can’t fix what I don’t know someone has a problem with! <br/><br/> I wanna make sure we’re not just on the same page, or even paragraph… <br/><br/> I want to drill down and make sure we’re on the same <em>sentence</em>! <br/><br/> <span style='color: #ff422d;'>[Insert / repeat back what it is the client is unhappy about.]</span> <br/><br/> This is what <span style='color: #ff422d;'>[I’m doing / I usually do / I propose we do]</span> to solve that… <br/><br/> <span style='color: #ff422d;'>[Insert what you’re doing / planning on doing / suggest doing to resolve it.]</span> <br/><br/> That work for you? <br/><br/> Or, do you have any ideas we might give a try?",
        },
      ],
    },
    {
      title: "They want you all to themselves",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When you have a prospect / customer / client, who will only work with you if you push all of your other clients to the side…or “they’ll just find another agent instead”.",
      notes:
        "<strong>NOTES:</strong>  You never want to make your clients feel like they’re just a number. Heck, you probably do everything you can to make them feel like they’re your only client. But the reality is, in order to make a living, you’re probably juggling a bunch of clients at a time.<br/><br/> Picture this scenario… <br/><br/> You meet a prospective buyer, or have been working with one, and they want to go see homes over the weekend. Problem is, this client wants you to push everyone else off, and show them around all day. (Probably the same client that’ll call you fifteen minutes after the appointment and say they had a late night, so they’re just gonna hang at home today.) <br/><br/> Here’s some words to keep them in line…and hopefully sticking with you.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I’m sure there’s another agent out there you could find to take you out at that exact time. That doesn’t make them the best agent to work with…just the most <em>available</em>. <br/><br/> I’d hate to see you jump ship with another agent just because I can’t accommodate this one time. <br/><br/> I always try to be available when clients need me. But sometimes it’s tough to accommodate everyone with only so many hours in a week. <br/><br/> How about this… <br/><br/> So we don’t run into this again, let’s set a specific day and time each week that will be yours until we find your new home. <br/><br/> And in the meantime, instead of <span style='color: #ff422d;'>[insert the time they want]</span>, can you do <span style='color: #ff422d;'>[insert your next available moment to show houses]</span>?",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Ha! This is crazy, ‘cause I was just talking to Frank, another client, and he was telling me to just push <span style='color: #ff422d;'>[insert name of client you’re speaking with]</span> off until <span style='color: #ff422d;'>[next weekend / next week / next month / later in the day]</span>! <br/><br/> Kiddddddding! I’m kidding. Frankly, there is no “Frank”. <br/><br/> But there are other clients I do have appointments with. And if any one of them ever asked me to shift an appointment you and I had, I assure you, I wouldn’t let them mess up your plans or schedule. <br/><br/> How ‘bout this… <br/><br/> I can definitely do <span style='color: #ff422d;'>[insert the next available time you can give the client]</span>. That work for you? <br /><br/> Or, do you still feel like you need to go find some agent out there who’s soooo good at this stuff, that the only thing getting in the way of showing you houses this weekend is some thumb-twiddling?",
        },
      ],
    },
    {
      title: "They want you to do something shady",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client asks you to do something shady or grey area, or outright wrong!",
      notes:
        "<strong>NOTES:</strong> You know that old saying… “The customer (or in this case, the client) is always right!” <br/><br/> You also know that’s not really true. Customers and clients are wrong sometimes. But in many cases, it just makes sense to go with the flow, do as they wish, and not cause a big fuss. <br/><br/> But there are times when what your client asks you to do may cross a line. It could be as simple as fibbing about something they want to hide as a seller or buyer, or as illegal as discriminating against someone. <br/><br/> You know you have to draw the line. And you will. But sometimes it’s hard to put it into words that won’t cause your client to feel upset or angry. <br/><br/> Here’s a script to soften the blow when you say no.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "A big part of my job is to look out for your best interests. <br/><br/> And unless your interests include <span style='color: #ff422d;'>[insert consequence: being fined / being sued / etc.]</span>, I have to recommend that you don’t <span style='color: #ff422d;'>[insert what they want to do]</span>. <br/><br/> There’s actually <span style='color: #ff422d;'>[choose one: rules / regulations / laws]</span> against doing that. Lots of people don’t even realize that, or that you can actually get in trouble for doing it. <br/><br/> So I’m not surprised that you weren’t aware of it either. <br/><br/> Just trying to keep you out of trouble! ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Before I let you <span style='color: #ff422d;'>[insert what they want to do]</span>, I’m warning you… <br/><br/> I won’t be sneaking a file in a cake to you when you end up in jail. LOL <br/><br/> OK, the consequences wouldn’t be that bad! <br/><br/> But if you did <span style='color: #ff422d;'>[insert what they want to do]</span>, you could definitely pay a price. (And not a Walmart price.) <br/><br/> <span style='color: #ff422d;'>[Insert the type of “price”: You could be sued. / You could be fined. / etc.]</span> It’s not worth it. <br/><br/> Hey, you know I’ve always got your back. Just looking out for you…so you don’t need someone else to have your back on the inside of a prison, or at least a courtroom.",
        },
      ],
    },
    {
      title: "They’ve gone MIA",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When you’re simply not hearing back from clients…radio silence…crickets.",
      notes:
        "<strong>NOTES:</strong> Agents fear the clients who call, text, and email 100 times a day—and at all hours! But then there’s the opposite kind of client… <br/><br/> …the ones who just disappear, and don’t ever respond. <br/><br/> This script gives you just the right words to check in and nudge them to give you a quick response. (And, without making them feel guilty or bad!)",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Hey, <span style='color: #ff422d;'>[insert name]</span>! Hope everything’s OK on your end. <br/><br/> I just realized we haven’t been in touch since <span style='color: #ff422d;'>[insert date]<s/pan>, when I <span style='color: #ff422d;'>[sent you an email / texted you / called you] </span>, and I wanted to make sure I wasn’t missing your response. You know how technology can be… <br/><br/> So, do me a favor and shoot me a quick <span style='color: #ff422d;'>[email / text / phone call]</span>, just so I know I haven’t missed anything you sent my way since then. I don’t want you thinking I disappeared or forgot about you!",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Just wanted to send you a quick thank you! <br/><br/> One of the biggest issues real estate agents have is when a client is constantly calling, texting, and emailing at all hours of the day. And, expecting the agent to pick up or respond, no matter what time of day it is! <br/><br/> So, I just wanted to say that I totally appreciate that you’re that considerate of my time and attention! Heck, I haven’t even heard from you in <span style='color: #ff422d;'>[days / weeks]</span>! <br/><br/> You don’t have to be that considerate! <br/><br/> Do me a favor, I need to check and see that my <span style='color: #ff422d;'>[email / phone / text messaging]</span> isn’t on the fritz… <br/><br/> I’m sure you’re probably just busy as heck, so just <span style='color: #ff422d;'>[send me a quick email / give me a quick call / send me a quick text]</span>!",
        },
      ],
    },
    {
      title: "Persistent texting, emailing, or calling you",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When you have a client who won’t stop texting, emailing, or calling.",
      notes:
        "<strong>NOTES:</strong> Sometimes there’s a need to be on the phone, or texting, or emailing a particular client seemingly all day long.<br/><br/> But there’s also those clients who just can’t get enough of your time and attention. They may even be nice enough, and great to chat with. But if it gets in the way of you doing more productive stuff…it’s a problem. <br/><br/> You don’t want to make the client feel bad, or be rude, but you’ve gotta put an end to it. <br/><br/> You want them to feel like they’re the only client, but also subtly let them know they’re not.<br/><br/> These scripts should make your client feel warm and fuzzy, but also give you the break you need.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I wish I could make my entire living just working with you! <br/><br/> It’s crazy how quickly time flies when we’re talking. I could literally talk to you all day. It’s like we can talk about <em>anything</em>. <br/><br/> Anyway, I’ve gotta get <em>some</em> work done today. <br/><br/> Oh, and just a heads up, this whole week is going to be super busy, so if we don’t connect in the next few days, just know I got tied up with work.<br/><br/> Of course, if <span style='color: #ff422d;'>[anything comes up about your house / a house you should see comes on the market]</span>, we’ll definitely be in touch. Otherwise, let’s touch base <span style='color: #ff422d;'>[insert day]</span> just to catch up and shoot the breeze a bit.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Hey, I gotta go…but before I do, I need to tell you a secret. <br/><br/> Gotta promise not to tell anyone… <br/><br/> I wish you were my only client! <br/><br/> It’s insane how well we get along! <br/><br/> I almost feel guilty, because here I am working, and I literally feel like I’m just hanging out chatting with a friend. <br/><br/> Anyway…I better go clear my conscience and actually do some work. But let’s pick this up on <span style='color: #ff422d;'>[insert day]</span>, because my schedule is pretty light that day. <br/><br/> Hopefully <span style='color: #ff422d;'>[something will come up about your house / a house will come on the market]</span> so we don’t end up having to wait ‘til then!",
        },
      ],
    },
    {
      title: "Setting expectations on your response time",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When you need to set expectations on when you get back to people.",
      notes:
        "<strong>NOTES:</strong> You probably pride yourself on being responsive, and getting back to your clients promptly. <br/><br/> But sometimes, no matter how on top of things you are, there’s a client who’s so impatient if you don’t respond immediately…and lets you know just how upset they are that you didn’t. <br/><br/> Take the moment, and this script, to respond politely, yet firmly, and establish how it’s gonna be from here on out.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Hi <span style='color: #ff422d;'>[insert name]</span>! <br/><br/> Just wanted to shoot you a quick reply to let you know I <span style='color: #ff422d;'>[heard your voicemail / saw your email / saw your text]</span>. <br/><br/> The reason I hadn’t responded right away was because I was in the middle of something, and didn’t want to give you a distracted answer. I always like to make sure I’m giving my full thought and attention to whatever I’m in the middle of. <br/><br/> So, always know that I’m on top of my <span style='color: #ff422d;'>[email / voicemail / texts]</span> and will get back to you the absolute first minute I can give you my full attention. <br/><br/> But please, call, email, or text <em>whenever</em> you want, or whenever you have a thought or question, when it’s fresh on your mind! <br/><br/> If it comes in after I’m done for the day, I’ll get back to you first thing the next morning!",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I truly don’t know what people did before <span style='color: #ff422d;'>[email / voicemail / cell phones]</span>! <br/><br/> It could literally take days before people were able to communicate. I’m not even talking Pony Express, or smoke signals… <br/><br/> Literally just talking about when we relied on landlines, answering machines, faxes, and mail. You’d be totally out of luck if someone had left the office for the day! <br/><br/>Anyhow, I’m never outta the “office” anymore! You can always reach me! So, send me an email, a text, or leave a voicemail whenever you’ve got a question, or just the urge. <br/><br/> Whether or not I can <span style='color: #ff422d;'>[speak to you / reply]</span> right that <em>minute</em> is the tough part. <br/><br/> But, I <em>promise</em> you…I’ll always get back to you quicker than Pony Express (or at least every other real estate agent)! <br/><br/> Always within the day. Probably within minutes or a few hours. Unless of course you call me when I’m sleeping. I haven’t figured out how to function without sleeping yet. <br/><br/> (But, if you’ve got some time on your hands, it’s nothing urgent, and you wanna have some fun… we could try sending smoke signals!)",
        },
      ],
    },
    {
      title: "Blaming you / not your fault",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client is angry, and blaming you for something that wasn’t your fault.",
      notes:
        "<strong>NOTES:</strong> There’s a lot of people involved in a real estate sale or purchase, and a lot of things that can go wrong. <br/><br/> No matter how meticulous and careful you are, you can’t guarantee nothing will go wrong somewhere along the way. <br/><br/> Someone could drop the ball, or make a mistake with the mortgage process, the appraisal, etc. It could be the fault of the buyer on the other side, the seller on the other side, the other agent, the appraiser, the underwriter, the loan officer…and the list goes on. <br/><br/> But you could be the whipping post. You shouldn’t have to apologize, or take the blame. But to turn this all around, you should start by apologizing…for others. <br/> <br/> Just don’t take the blame for them!<br/><br/> This’ll help you calm your client down, show them you didn’t have anything to do with whatever happened or didn’t happen, and that you’re on top of doing what you can to remedy the problem.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I’m sorry you have to even deal with this. <br/><br/> And I assure you, if it was something I did wrong, I’d totally take the blame. <br/><br/> But this is outside of my control. <br/><br/> This is something <span style='color: #ff422d;'>[insert who is responsible]</span> should never have let happen. <br/><br/>There’s a lot of people involved in the process, and a lot of things that need to get done when you’re <span style='color: #ff422d;'>[buying a home / selling a house]</span>, so, unfortunately, there’s a lot of opportunity for things to go wrong. <br/><br/> The upshot is that this isn’t the end of the world. Don’t get me wrong, it’s aggravating… But we can get this back on track. <br/><br/> I’ll do what I can on my end to make sure that <span style='color: #ff422d;'>[insert who actually needs to fix the issue]</span> is on top of the situation, until it’s resolved.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Somebody oughta get a good spankin’! <br/><br/> Not me…but <em>somebody</em>! LOL <br/><br/> And, believe me, if I deserved the spankin’, I’d hand you the paddle. <br/><br/> But this time, it’d be like taking one for something my <span style='color: #ff422d;'>[brother / sister]</span> did. And that’s not fair… <br/><br/> This really falls in the lap of <span style='color: #ff422d;'>[insert whose responsibility it really is]</span>. <br/><br/> I can’t promise you I’ll actually spank <span style='color: #ff422d;'>[him / her]</span>, but I’ll certainly give <span style='color: #ff422d;'>[him / her]</span> a good, firm talkin’ to. And I won’t let up ‘til <span style='color: #ff422d;'>[he / she]</span> gets this resolved.",
        },
      ],
    },
    {
      title: "Owning up to your mistake (you caught it)",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> Owning up to a mistake you made.",
      notes:
        "<strong>NOTES:</strong> Obviously, you don’t <em>intentionally</em> make mistakes. Who wants the hassle of dealing with that!? <br/><br/> But things happen, and sometimes they’re your fault, or were your responsibility.<br/><br/> It’s kinda human nature to want to shift the blame, or make excuses. But the best policy is, as they say, honesty. <br/><br/> So honestly own your mistake. Assure your client you’re on top of it, and fixing the problem, rather than hope they never realize that you made a mistake. Because if they do, it’s a lot tougher to save your reputation, than if you just admit you were wrong upfront. ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I owe you an apology. <br/><br/> I just realized that I <span style='color: #ff422d;'>[insert your mistake]</span>. <br/><br/> I’m on top of it, and <span style='color: #ff422d;'>[insert what you’re doing to remedy the situation]</span>. <br/><br/> I assure you I try my best to never make mistakes, but when I do, I own up to them. Honesty is the best policy. <br/><br/> Hopefully you can forgive me this time around. The silver lining is that I can assure you I’ll be even more careful than I normally am, to ensure I don’t make another mistake moving forward. <br/><br/>If you have any questions or concerns about this, please just let me know. I want to make sure you’re comfortable and confident that I’m on top of the situation!",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "You know that saying, “To err is human, to forgive is divine.”? <br/><br/> Let’s just say that I hope you are divine… <br/><br/> Because there’s a mistake that was all mine… <br/><br/> I just realized that I <span style='color: #ff422d;'>[insert the mistake you made]</span>. <br/><br/> I’ll take the blame and the shame…and make things right. <br/><br/> All you have to do is forgive me…and know that I’ve caught the mistake and I’m on top of it. <br/><br/> I just wanted to be open and upfront about it.",
        },
      ],
    },
    {
      title: "Owning up to your mistake (they caught it)",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client catches an error you made (like in MLS entry, on a contract, etc.) and calls you out on it.",
      notes:
        "<strong>NOTES:</strong> It’s one thing when you realize you’ve made a mistake, own up to it, apologize, and are already on top of fixing it… <br/><br/> But when a client catches your mistake, it can catch you totally off guard. <br/><br/> Of course you might feel like making an excuse, justifying yourself, or rationalizing the error. That’s natural. But…not the best approach. <br/><br/> The best approach is to admit you were wrong, thank them for catching it, and assure them you are on top of it and won’t let that happen again.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "Oh my goodness! Thank you so much for catching that! <br/><br/> I’m always as careful as possible, so I’m not sure how that happened! <br/><br/> I’ll fix that right away. <br/><br/> And you can bet that I’ll be even more careful than usual as we move forward.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "This is a test, this is only a test…(read that in your best emergency broadcast system voice!). <br/><br/> I just wanted to see if you were paying attention! LOL <br/><br/> Just kidding, of course. So glad that you caught that! Let me get on that right away. <br/><br/> And I promise, no more tests! You passed with flying colors. I’m always so careful, but I can assure you I’ll be even more careful from here on out!",
        },
      ],
    },
    {
      title: "Client asking too much, you decline",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client is roping you into doing more than you should be doing, or at least want to do, as their agent.",
      notes:
        "<strong>NOTES:</strong> Some people have a tough time knowing where a real estate agent’s duties begin and end. <br/><br/> By the same token, some agents have a hard time establishing where their role begins and ends. <br/><br/> It can be a gradual, slippery slope. You don’t even see it happening. Then, all of a sudden, you’re going way beyond the call of duty, and not only are you on a slippery slope, but you also seem to have roller skates on. <br/><br/> Here’s some instances this might be useful:<br/><br/> <ul><li>Giving marriage advice</li><li>Giving legal advice</li><li>Giving advice on particular issues like construction / inspection issues</li><li>Cleaning their house, or maintaining their lawn</li><li>Driving them around to do errands while showing houses</li></ul> <br/><br/> Those are just a few. You can probably name many more, or will at least know one when you see one. Before, you probably just bit your tongue and swore you’d never allow it to happen again. But not anymore… <br/><br/> Here’s how to put the brakes on. Instead of saying outright no, you deflect them.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I really wish I could help you there. But <span style='color: #ff422d;'>[insert topic – i.e. marriage counseling, landscaping, cleaning houses, etc.]</span> is just not one of my strong suits. <br/><br/> Don’t get me wrong, I’m flattered that you’d trust me with doing that as well as I do the whole real estate agent thing… <br/><br/> But I leave that for others to do well. <br/><br/> <span style='color: #ff422d;'>(NOTE: If you have someone you can refer them to or recommend, add the line below…)</span> <br/><br/> You know who is good at that type thing…<span style='color: #ff422d;'>[insert name of who you recommend]</span>. <br/><br/> You want me to get <span style='color: #ff422d;'>[him / her]</span> in touch with you? ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Oh man, this happens <em>All. The. Time.</em> <br/><br/> People always think I’m a <span style='color: #ff422d;'>[insert topic – i.e. marriage counseling, landscaping, cleaning houses, etc.] </span>… <br/><br/> But I’m not actually a <span style='color: #ff422d;'>[insert thing again]</span>…I just play one on TV. That’s probably how everyone gets that idea…LOL <br/><br/> Totally flattered that you think I’ve got those kinda skills. But, the truth is that’s better left to other professionals. I’ll stick to the whole <span style='color: #ff422d;'>[selling your house / helping you buy a house]</span> thing! <br/><br/> <span style='color: #ff422d;'>(NOTE: If you have someone you can refer them to or recommend, add the line below…)</span> <br/><br/> You know who is good at that type thing…<span style='color: #ff422d;'>[insert name of who you recommend]</span>. <br/><br/>You want me to get (him / her] in touch with you?",
        },
      ],
    },
    {
      title: "Client asking too much, you accept",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a client is roping you into doing more than you should be doing, or at least want to do, as their agent.",
      notes:
        "<strong>NOTES:</strong> Sometimes you want to go above and beyond for a client. Other times you feel you have to… <br/><br/> That doesn’t matter. What matters is that when you agree to go above and beyond what you’d normally do, you don’t look like a pushover, and you get some love and appreciation for doing it.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I’m glad to <span style='color: #ff422d;'>[insert what they asked you to do]</span> for you. <br/><br/> But the key word is “you”. I really appreciate you as a client, and I want to make an exception and do it. But it isn’t something I normally do. <br/><br/> Don’t worry, I won’t charge you anything additional…as long as we can keep it to just this, and just this time. That work for you? <br/><br/> Oh, and this really is something I want to do for you. Again…for you. So please don’t spread the word that this is something I do for everyone when you refer me to people in the future! <br/><br/> The only thing I’d love for you to do is <span style='color: #ff422d;'>[insert something you’d like for them to do…i.e. Write me a testimonial]</span>! You mind doing that?          ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "That was impressive!!!! <br/><br/> How in the world did you twist my arm without me noticing? <br/><br/> You must’ve! ‘Cause I’m gonna cave in and <span style='color: #ff422d;'>[insert what they asked you to do]</span> for you. <br/><br/> And without even asking you to buy me coffee, lunch, a stiff drink, or raise my fee. <br/><br/> At least as long as this is just this one thing, this one time. Happens again, you may be buying me coffee, lunch, a drink, and paying me a higher fee! LOL <br/><br/> In return, here’s what you can do for me… <br/><br/> Keep this hush hush. <br/><br/> Last thing I need is everyone you refer in the future to be expecting me to do this. And don’t get sneaky and tell ‘em to just twist my arm. It won’t work for anyone else. <br/><br/> Actually, there’s one more teeny tiny thing you could do for me… <br/><br/> <span style='color: #ff422d;'>[Insert something you’d like for them to do…i.e. Write me a testimonial]</span>! You mind doing that?",
        },
      ],
    },
  ],
  "Dealing with friends, family, acquaintances": [
    {
      title: "They want to pick your brain",
      name: "Dealing with friends, family, acquaintances ",
      id: 6,
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When friends or family want to pick your brain for free or take your time (when they’re working with another agent).",
      notes:
        "<strong>NOTES:</strong>  This is infuriating. It’s rude and disrespectful. And it hurts. But rather than simply be emotional about it, give them a solid response that’ll make them understand why you can’t and won’t chime in, and even question why they aren’t working with you. ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I know this sounds kinda harsh, but I just can’t <span style='color: #ff422d;'>[insert their request – i.e. show you that house / give you advice] </span> . <br/><br/>It stinks, because obviously you value my <span style='color: #ff422d;'> [help / opinion]</span> , and I totally want to give it to you. <br /><br/>It probably doesn’t seem like a big deal…but you have an agent you’re working with, and it wouldn’t be fair or right for me to intrude on that agency relationship. <br/><br/> <span style='color: #ff422d;'>[He / she]</span>  is representing your interests. We need to respect that. <br/><br/> I know a lot of agents are loose about that. But I take the ethics in this business seriously…even if another agent isn’t doing as good a job as I would, or giving the advice I would give. <br/><br/> <span style='color: #ff422d;'>( <strong>NOTE: </strong> You are NOT specifically saying their agent is not doing a good job, or advising them poorly. This is specifically chosen wording.) </span> <br /><br/> But I do appreciate the fact that you want my two cents. Wish I could give it to you. Maybe on your next deal?! ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "I know you just want me to <span style='color: #ff422d;'>[give you my two cents on the issue / show you a house] </span>, and that doesn’t seem like a big deal. <br/><br/> Believe me, <em>part</em> of me wants to just help you out, if it weren’t a huge ethics violation to do so (because you’re represented by another agent). So, we’ve gotta respect that… <br/><br/> But the <em>other</em>  part of me wants to give you my two cents and say…. <br/><br/> <span style='color: #ff422d;'>( <strong>NOTE:</strong>  Now pause briefly, like you’re about to deliver a punchline. And then say the following sentence in as light-hearted a way as possible. Ideally with a smile.)</span> <br/><br/> YOU SHOULDA HIRED <em>ME</em>  IN THE FIRST PLACE! ",
        },
      ],
    },
    {
      title: "They want free services",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When friends or family want you to give them your services for free.",
      notes:
        "<strong>NOTES:</strong>  Sometimes it’s more difficult to deal with friends and family than with complete strangers, since they seem to feel like you should be able to reduce or waive your commission, or give it back to them entirely after closing. <br/><br/> You don’t necessarily want to lose the business, or turn it down…but what’s the point if they only value your services if they’re free. <br/><br/> Use these to turn it around on them and make them want you to earn the money…not someone else’s friend or relative. ",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "You have <em>no</em>  idea how much I’d like to be able to help you for free… <br/><br/>‘Cause if I could swing that, it’d mean I’d already made my millions, and <em>could</em>  work for free. <br /><br/> Unfortunately, I’m not there quite yet. <br/><br/> Heck, I’m not sure I’ll <em>ever</em>  get to that point! <span style='color: #ff422d;'>( <strong>NOTE:</strong>  Give a little laugh.)</span> <br/><br/> Everyone thinks real estate agents make so much money. Don’t get me wrong, I make a decent living. But the amount that lands in my pocket is nowhere near what people think it is. <br/><br/> As much as I’d like to hook friends and family up with a freebie, I can’t do that and <em>eventually</em>  hook up the people I love. <br /><br/> With that said, I’d love to stay in this business, and eventually retire, so I’d really appreciate it if you’ll still hire me for a fee, instead of for free. ",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "You know that saying… “If you love somebody, let them go”? <br/><br/> I love you so much, I’ve gotta let you go. <br/><br/> I want you to hire another agent. I won’t make you feel guilty. You have my blessing. I won’t feel betrayed. <br/><br/> Truth is, I won’t be able to devote the proper time and attention to you if I’m giving you my time and services for free. I need to give priority to my clients who are paying me. And that wouldn’t be fair to you. <br/><br/> Don’t get me wrong, I  <em>want</em>  to work with you, and it kills me to do this. Because the reality is, you’re gonna end up paying another agent as much, or maybe more than you’d be paying me at my rate…and I can’t imagine another agent caring about you as much as I will. <br/><br/> I <em>wish</em> that saying was “If you love a real estate agent, just hire <span style='color: #ff422d;'> [him / her]</span> .",
        },
      ],
    },
    {
      title: "They aren’t supportive of you",
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When friends or family aren’t supportive of you getting into / being in real estate.",
      notes:
        "<strong>NOTES:</strong>  It’s hard enough to stay focused and positive enough to succeed in real estate. The last thing you need are people around you telling you to get a “real job”, telling you how tough the business is, predicting you’ll fail, or otherwise harshing your mellow. <br/><br/> Perhaps they don’t realize how insensitive they’re being. Maybe they even mean well…they’re just looking out for you. That doesn’t make it right. And you should have a response ready for them so <em>they</em> know you’ve got this…and so do you. Otherwise, <em>their</em>  words could affect <em>your</em> success.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "You’re definitely not the only person who thinks I might be a little crazy for getting into real estate. <br/><br/>Not that I’m questioning it myself, but is there a specific job or career you have in mind for me? <br /><br/> <span style='color: #ff422d;'>( <strong>NOTE:</strong> If they have something interesting and you want to consider it, go right ahead. But, most likely they don’t. Just asking them turns their suggestion around on them and gains you some control so you can proceed with this response…) </span>. <br/><br/> Oh, OK. Nothing specific. Just a general suggestion. Totally appreciate you just don’t want to see me struggle or fail. Fact is, real estate <em>is</em> a tough business. Lots of people get their license, but not that many succeed. <br/><br/> Tell you what, you know what’d be a huge help? Keep your ears open and try and hook me up with anyone you hear about who’s looking to buy or sell a house. <br/><br/> And if you hear about a cool job or position you can hook me up with in another field, let me know… <br /><br/> But I’d rather you just help me find a bunch of jobs helping people buy and sell houses, rather than an entirely different career! Same thing…just different.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Ha! You owe me a dollar!!! <br/><br/> <span style='color: #ff422d;'>( <strong>NOTE:</strong> If in person, put your hand out. Let them ask why, or look curious…) </span> <br/><br/> Oh, you didn’t know…? <br/><br/> Whenever someone tells a real estate agent to get a real job, or asks what their back up plan is, the person has to give the agent a dollar. <br/><br/> <em>That’s</em>  how we make enough money to survive in this business, ‘cause you’re right… <br/><br/> This business  <em>is</em>  super tough. And there’s probably easier, safer ways for me to make a living. <br/><br/> Kidding, of course! <br/><br/> Look, I totally appreciate you wanting to look out for me and not see me fail. <br/><br/> But instead of telling me to find another career, how ‘bout telling everyone you know <em> about</em>  me? That way, I won’t need to find another career…and I won’t make you give me that dollar you owe me.",
        },
      ],
    },
  ],
  "Dealing with anyone who steps out of line": [
    {
      title: "They’re being rude / sarcastic",
      name: "Dealing with <em>anyone</em> who steps out of line",
      id: 7,
      useThis:
        "<strong>WHEN TO USE THIS:</strong>  When a client or prospect says something rude, sarcastic, or snide to you.",
      notes:
        "<strong>NOTES:</strong>  Ideally, you only work with clients who are kind, considerate, appreciative and, above all, respectful. <br /><br />But once in a while, you find yourself dealing with someone rude. <br /><br /> When that happens, you probably play out what you’d like to say, or what you should’ve said, in your head. <br /><br /> Even if you had the best zinger to fling back at him or her, it’s probably best you don’t. <br/><br/> That doesn’t mean you should just let his or her remarks slide! <br/><br/> Call ‘em out firmly, but diplomatically. Maybe it was just a misunderstanding…maybe not. EIther way, at least your client will know to watch what and how they say things in the future. <br /><br/> Or, if they persist on being a bully, maybe that’s your cue to say “Bye Felicia!”",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "It’s probably just me… <br/><br/>But when you said <span style='color: #ff422d;'> [insert what they said]</span>, it came across as <span style='color: #ff422d;'> [sarcastic / rude / mean]</span>. <br /><br/> Am I just reading into it? <br/><br/> If not, let me know where you’re coming from. I’m glad to address whatever it is that’s really bugging you.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "You ever play the “telephone game” where you’re supposed to whisper something to the person next to you, and then it gets whispered to the next? By the time it gets to the last person it’s usually all sorts of messed up. Someone, somewhere along the way misheard what the other person said. Always good for a laugh! <br/><br/> Anyhow, hopefully this’ll be good for a laugh… <br/><br/> But this is what I think you just said… <span style='color: #ff422d;'> [insert what they said]</span>. Which would come across as kinda <span 'color:#ff422d;'>[rude / mean / sarcastic / insensitive] </span> . So, I had to have misheard you, right? . So, I had to have misheard you, right? ",
        },
      ],
    },
    {
      title: "They’re sexually inappropriate",
      useThis:
        "<strong>WHEN TO USE THIS:</strong> When a prospect or client is sexually inappropriate with you.",
      notes:
        "<strong>NOTES:</strong> You could always ignore it, just to keep the peace. After all, maybe you misheard. Maybe it was just a joke. Maybe it wasn’t even meant that way at all. At least those are all things you might think… <br /><br />But you know how you felt when it was said…so, listen to your gut! <br /><br /> Not saying something about it might be taken as a sign that it’s OK by you. In the least, it won’t set a better tone moving forward. <br /><br /> So, send ‘em a shot across the bow. Make sure they know you aren’t cool with that sorta stuff now, or in the future…no matter if they meant it, were “just joking”, or were oblivious to how it came across.",
      responseText: [
        {
          name: "Response Type 1",
          header: "Prim, Proper & Professional",
          content:
            "I totally love to joke around. <br/><br/>But everybody’s got their own personal boundaries. I’m sure you didn’t mean to offend me, but I have to let you know, what you just said isn’t something I’m comfortable joking around about. <br /><br/>So, please don’t be offended, but let’s just keep our conversations professional moving forward.",
        },
        {
          name: "Response Type 2",
          header: "A Punch of Personality",
          content:
            "Oh no! Did you not see the line? <br/><br/> I do realize it’s invisible…but you’re not supposed to cross it! And you just crossed the line with that one! <br/><br/> I’ll forgive you this time, since it’s an invisible line and all… <br/><br/> But now that you know where the line is, let’s keep things professional. <br/> <br/>Anyhoo…about <span style='color: #ff422d;'> [your house / the house you want to see / that last house we saw]</span> … <br/><br/> <span style='color: #ff422d;'>( <strong>NOTE:</strong>  Basically just change the subject back to real estate at this point.)</span>",
        },
      ],
    },
  ],
};
