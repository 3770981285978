import React, { useState } from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import center from '../../../Assets/Images/Icons/cs_icon_tutorial.png';
import booklet from '../../../Assets/Images/Icons/cs_icon_booklet.png';
import ContactModal from '../../Common/PopupComponents/ContactModal'

const ContactPills = (props) => {
    // Get Api data from Admin layout as props
    const { handleClick, open, dealsList } = props;
    const [openContact, setopenContact] = useState(false);
    const [count, setCount] = useState(0)
    const [subcount, setSubcount] = useState(0)

    // Redirect to Support center 
    const buttonSupport = (url) => {
        setSubcount(subcount + 1)
        if (subcount === 1) {
            window.location.href = url
            setSubcount(0)
        }
        // setTimeout(() => {
        //     setSubcount(0)
        // }, 200)
    }

    const buttonContact = () => {
        setCount(count + 1)
        if (count === 1) {
            setopenContact(true)
            setCount(0)
        }
        // setTimeout(() => {
        //     setCount(0)
        // }, 500)
    }

    const handleClose = () => {
        setopenContact(false)
    }
    return (
        <div>
            {/* imported the contact us popup form  */}
            <ContactModal isOpen={openContact} handleClose={handleClose} data={dealsList} />
            {/* Support Query design starts */}
            <div className='Contact-pill mt-2 curs-point' onClick={handleClick}>
                {open ? <CloseIcon className='Social_pills' /> : <QuestionMarkIcon className='Social_pills' />}
            </div>

            <div className={open ? "Contact-pills pill1 mt-2 curs-point" : "d-none"} onClick={() => { buttonContact() }}>
                <img alt='' src={center} />
                <span className='ml-2'>Contact Us</span>
            </div>

            <div className={open ? "Contact-pills pill2 mt-2 curs-point" : "d-none"} onClick={() => { buttonSupport(dealsList.ic_support_center_link) }}>
                <img src={booklet} alt='' />
                <span className='ml-2'>Support Center</span>
            </div>
            {/* Support Query  design ends */}
        </div>
    );
};

export default ContactPills;