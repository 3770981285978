import React from "react";
import { Markup } from "interweave";
import {noteMessage} from '../../../_LocalData/TemplateData'
import AccodianTemplate from './AccodianTemplate'
function TemplateContent() {
  return (
    <div className="templateContent-wrapper">
      <div className="note-message">
        <Markup content={noteMessage.note}/>
      </div>
      {/*  */}
      <AccodianTemplate />
    </div>
  );
}

export default TemplateContent;
