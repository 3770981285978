import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Container, Row, Col, Collapse } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import EmailNotification from '../../Pages/EmailNotification/EmailNotification';
import activeArrow from '../../../Assets/Images/Icons/active_arrow.png'


const drawerWidth = 240;

const LeftSidebar = (props) => {
  const [activeTab, setActiveTab] = useState(0);
  const Emaildata=props.data
  const menus = props.menus;
  const location = useLocation();
  const navigate = useNavigate();
  const [collapse, setcollapse] = useState({})

  useEffect(() => {
    HeaderView();
  }, [])

  const handleOpen = (data) => {
    handleClick(data.title)
    toggleOpen(data.id)
  }

  const HeaderView = () => {
    setActiveTab(location.pathname.slice(1,).replace("-", " "));
  }

  const handleClick = (text) => {
    setActiveTab(text.toLowerCase());
  }
  const toggleOpen = (id) => {
    setcollapse({ ["open" + id]: !collapse["open" + id] })
  }
  return (
    // Left side bar design starts 
    <>
    {/* {emailData && */}
    <Box sx={{ display: 'flex' }} className='content-page leftSidebar'>
      <Drawer className='left-bar'
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <List>
          {props.menus.map(x => {
            return (
              <>
                {x.children?.length ? <>
                  <ListItem key={x.id} disablePadding className={activeTab === x.title.toLowerCase() ? 'icside-active' : ''} style={{ backgroundColor: activeTab === x.title.toLowerCase() ? "#43a1f9" : "#e5f4ff" }}>
                    <ListItemButton className='p-3' onClick={() => { handleOpen(x) }}>
                      <ListItemText className='let' primary={x.title} />
                    </ListItemButton>
                  </ListItem>
                  <Collapse className='curs-point' isOpen={collapse["open" + x.id]}>
                    {x.children.map(y => {
                      return (
                        <>
                        <a href={y.link} >
                          <ListItem key={y.id} disablePadding className='icside-active child' style={{ backgroundColor: "#43a1f9" }}>
                            <ListItemButton className='fw-light' onClick={() => handleClick(y.title)} >
                              {activeTab === y.title.toLowerCase() ?
                                <img src={activeArrow} alt="arrow" className='Iconarrow' />
                                : null}
                              <ListItemText className='pl-5' primary={y.title} />
                            </ListItemButton>
                          </ListItem>
                          </a>
                        </>
                      )
                    })}

                  </Collapse>
                  <hr className='m-0 ic-side-bottom' />
                </> :<>
                <a href={x.link} >
                  <ListItem key={x.id} disablePadding className={activeTab === x.title.toLowerCase() ? 'icside-active' : ''} style={{ backgroundColor: activeTab === x.title.toLowerCase() ? "#43a1f9" : "#e5f4ff" }}>
                    <ListItemButton className='p-3' onClick={() => handleClick(x.title)} >
                      <ListItemText className='let' primary={x.title} />
                    </ListItemButton>
                  </ListItem>
                  </a>
                  <hr className='m-0 ic-side-bottom' />
                  </>
                  }
              </>
            )
          })}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
        <Container>
          <Row>
            <Col lg="2"></Col>
            <Col lg="8">
             
            <EmailNotification data={Emaildata} />
            </Col>
            <Col lg="2"></Col>
          </Row>
        </Container>
      </Box>
    </Box>
      {/* } */}
      </>
    // Left side bar design ends
  );
}
export default LeftSidebar;
