import React, { useState } from 'react';
import { Button, Modal, CardBody } from 'reactstrap';
import './DeleteConfirm.scss';

const DeletenowModal = (props) => {

    const { openModal, handleClose ,handleDeleteNow, selectedData} = props


    return (
        <div className='contactForm1'>
            <Modal className="modal-dialog-centered" isOpen={openModal} size="md">
                <div className="modal-body  Delete d-flex justify-content-center p-0">
                    <CardBody className='p-0'>
                        <div>
                            <h4 className='m-0 align-self-center d-mp-title resdelete text-center mb-4'>Please confirm</h4>
                            <p className='delete-content'>Are you sure you want to completely remove this post from our scheduling system?</p>
                            <div className='Delete-btn text-center'>
                                <Button onClick={()=>{handleDeleteNow(selectedData)}}>Remove post</Button>
                            </div>
                            <div className='delete-ref text-center pt-2'>                                
                            <a onClick={handleClose} href>Do not remove</a>
                            </div>
                        </div>
                    </CardBody>
                </div>
            </Modal>
        </div>
    )
}


export default DeletenowModal;

