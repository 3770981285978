import { useState } from 'react';
import IframeModal from './IframeModal'
import './DeleteConfirm.scss';
import { Button, Modal, CardBody, Row, Col } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';

const AddEventModal = ({ openModal, handleClose, handleDeleteNow, selectedData }) => {
    const [open, setOpen] = useState(false);
    const [postType, setPostType] = useState(false);
    const [iframeData, setIframeData] = useState('')

    // console.log("selectedData", selectedData)
    window.addEventListener('message', receiveMessage, false);
    function receiveMessage(evt) {
        setIframeData(evt.data)
    }

    const openEventIframe = (e) => {
        console.log("event",e)
        setPostType(e)
        setOpen(true)
    }
    const handleEventClose = () => {
        setOpen(false);
        if (iframeData !== 'add_post_popup') {
            handleClose()
        }
        if (iframeData === 'save_success') {
            handleClose();
        }
    }
    return (
        <div>
            <IframeModal
                isOpen={open}
                handleClose={handleEventClose}
                cc_Mobile={selectedData}
                postType={postType}
                iframeData={iframeData}
            />
            <Modal className="modal-dialog-centered Deals PostAdd" isOpen={openModal}  size="md">
                <div className="modal-body Addeven d-flex justify-content-center p-0">
                    <CardBody className='p-0'>
                        <div className='d-flex justify-content-between align-self-center'>
                            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                                <span aria-hidden={true}><CloseIcon className="m-close" /></span>
                            </button>
                        </div>
                        <div>
                            <h4 className='m-0 align-self-center d-mp-title resdelete text-center mb-4'>Add a new post</h4>
                            <hr className='mb-0' />
                            <Row>
                                <Col xs='6' className='text-center Meme_Button p-0' onClick={(e) => { openEventIframe("meme") }}><div className='pl-3 pt-5 pb-5 pr-4'><span className='meme'>Meme</span></div></Col>
                                <Col xs='6' className='text-center p-0' onClick={(e) => { openEventIframe("post") }}><div className='pl-3 pt-5 pb-5 pr-4'><span className='art'>Article</span></div></Col>
                            </Row>
                        </div>
                    </CardBody>
                </div>
            </Modal>
        </div>
    );
};

export default AddEventModal;