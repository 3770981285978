import React from 'react';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardImg, CardBody, CardSubtitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { vendors } from '../../../_LocalData/DealsVendorsList';
import { Markup } from 'interweave';
import DealsUser from '../../Common/PopupComponents/DealsUser';

export default class Deals extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.checkMembership = this.checkMembership.bind(this);

        this.state = {
            activeTab: '1',
            ic_user_membership_level: this.props.data.ic_user_membership_level,
            ic_site_url: this.props.data.ic_site_url,
            ic_breb_reactivate_link: this.props.data.ic_breb_reactivate_link,
            isOpen: false
        };
    }

    componentDidMount() {
        this.checkMembership(this.state.ic_user_membership_level)
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    checkMembership(level) {
        if (level == 7 || level == 8 || level == 9 || level == 11) {
            this.setState({ isOpen: true })
        } else
            this.setState({ isOpen: false })
    }

    handleClose = () => {
        if (this.state.ic_user_membership_level == 9) {
            window.location.href = this.state.ic_site_url + "/manage-subscription#billing-and-usage"
        }
        else {
            window.location.href = this.state.ic_site_url
        }
    }

    render() {
        return (
            <div>
                {/* Deals page design starts here */}

                {/* Imported Deals user popup */}
                <DealsUser mm_Id={this.state.ic_user_membership_level} isOpen={this.state.isOpen} handleClose={this.handleClose} data={this.props.data} />

                <Container className='Deal_Container'>
                    <div className='dealsPage'>
                        <Nav tabs className='my-4 deals-tab justify-content-between'>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                    All
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                    Apparel
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                    Closing Gifts
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                    Mailers
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                                    Promotional Items
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggle('6'); }}>
                                    CRMs
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '7' })} onClick={() => { this.toggle('7'); }}>
                                    Personal Safety
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '8' })} onClick={() => { this.toggle('8'); }}>
                                    Marketing/Sales Tools
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab} className="cardContentDiv">
                            <TabPane tabId="1">
                                <div id="channelsGuest">
                                    <div className="card-columns h-auto">
                                        {vendors.map(x => {
                                            if (x.id.includes(this.state.activeTab))
                                                return (
                                                    <div className='pt-2 pb-2 pl-1 pr-1' >
                                                        <Card className='deals-card'>
                                                            <CardImg top width="100%" src={x.Img_url} alt="Card image cap" />
                                                            <CardBody className='pl-0 pr-0 pb-0'>
                                                                <CardTitle><h4>{x.Title}</h4></CardTitle>
                                                                <CardText>
                                                                    <p>{x.Describe}</p>
                                                                    <a href='https://innercircle.lightersideofrealestate.com//dashboard' target="_blank"><Markup content={x.Email} /></a>


                                                                    {x.Point0 ?
                                                                        <ul className='deal-c-backgroud py-2'>
                                                                            <li>{x.Point0}</li>
                                                                            <li>{x.Point1}</li>
                                                                            <li>{x.Point2}</li>
                                                                            <li>{x.Point3}</li>
                                                                            <li>{x.Point4}</li>
                                                                            <li>{x.Point5}</li>
                                                                        </ul>
                                                                        : ''
                                                                    }
                                                                    {x.Discount ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Discount:</b>{x.Discount}</p>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                    {x.Promo ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Promo Code: </b>{x.Promo}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Promo1 ?
                                                                        <div>
                                                                            <b>Promo Code: </b>
                                                                            <div className='deal-c-promobackgroud py-2 mb-3'>
                                                                                <p><Markup content={x.Promo1} /></p>
                                                                                <p><Markup content={x.Promo2} /></p>
                                                                                <p><Markup content={x.promo3} /></p>
                                                                                <p><Markup content={x.promo4} /></p>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Code ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Code: </b>{x.Code}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Note ?
                                                                        <div className='deal-c-note py-2'>
                                                                            {x.Note}
                                                                        </div>
                                                                        : ''}
                                                                    {x.Instruct ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            {/* <p>{x.Instruct}</p> */}
                                                                            <p><Markup content={x.Instruct} /></p>
                                                                        </div>
                                                                        : ''}

                                                                </CardText>
                                                                <Button>{x.Btn}</Button>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="2">
                                <div id="channelsGuest">
                                    <div className="card-columns h-auto">
                                        {vendors.map(x => {
                                            if (x.id.includes(this.state.activeTab))
                                                return (
                                                    <div className='pt-2 pb-2 pl-1 pr-1' >
                                                        <Card className='deals-card'>
                                                            <CardImg top width="100%" src={x.Img_url} alt="Card image cap" />
                                                            <CardBody className='pl-0 pr-0 pb-0'>
                                                                <CardTitle><h4>{x.Title}</h4></CardTitle>
                                                                <CardText>
                                                                    <p>{x.Describe}</p>
                                                                    {x.Email ? <a>{x.Email}</a> : ''}

                                                                    {x.Point0 ?
                                                                        <ul className='deal-c-backgroud py-2'>
                                                                            <li>{x.Point0}</li>
                                                                            <li>{x.Point1}</li>
                                                                            <li>{x.Point2}</li>
                                                                            <li>{x.Point3}</li>
                                                                            <li>{x.Point4}</li>
                                                                            <li>{x.Point5}</li>
                                                                        </ul>
                                                                        : ''
                                                                    }
                                                                    {x.Discount ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Discount:</b>{x.Discount}</p>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                    {x.Promo ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Promo Code: </b>{x.Promo}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Promo1 ?
                                                                        <div>
                                                                            <b>Promo Code: </b>
                                                                            <div className='deal-c-promobackgroud py-2 mb-3'>
                                                                                <p>{x.Promo1}</p>
                                                                                <p>{x.Promo2}</p>
                                                                                <p>{x.promo3}</p>
                                                                                <p>{x.promo4}</p>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Code ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Code: </b>{x.Code}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Note ?
                                                                        <div className='deal-c-note py-2'>
                                                                            {x.Note}
                                                                        </div>
                                                                        : ''}
                                                                    {x.Instruct ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p>{x.Instruct}</p>
                                                                        </div>
                                                                        : ''}

                                                                </CardText>
                                                                <Button>{x.Btn}</Button>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="3">
                                <div id="channelsGuest">
                                    <div className="card-columns h-auto">
                                        {vendors.map(x => {
                                            if (x.id.includes(this.state.activeTab))
                                                return (
                                                    <div className='pt-2 pb-2 pl-1 pr-1' >
                                                        <Card className='deals-card'>
                                                            <CardImg top width="100%" src={x.Img_url} alt="Card image cap" />
                                                            <CardBody className='pl-0 pr-0 pb-0'>
                                                                <CardTitle><h4>{x.Title}</h4></CardTitle>
                                                                <CardText>
                                                                    <p>{x.Describe}</p>
                                                                    {x.Email ? <a>{x.Email}</a> : ''}

                                                                    {x.Point0 ?
                                                                        <ul className='deal-c-backgroud py-2'>
                                                                            <li>{x.Point0}</li>
                                                                            <li>{x.Point1}</li>
                                                                            <li>{x.Point2}</li>
                                                                            <li>{x.Point3}</li>
                                                                            <li>{x.Point4}</li>
                                                                            <li>{x.Point5}</li>
                                                                        </ul>
                                                                        : ''
                                                                    }
                                                                    {x.Discount ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Discount:</b>{x.Discount}</p>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                    {x.Promo ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Promo Code: </b>{x.Promo}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Promo1 ?
                                                                        <div>
                                                                            <b>Promo Code: </b>
                                                                            <div className='deal-c-promobackgroud py-2 mb-3'>
                                                                                <p>{x.Promo1}</p>
                                                                                <p>{x.Promo2}</p>
                                                                                <p>{x.promo3}</p>
                                                                                <p>{x.promo4}</p>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Code ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Code: </b>{x.Code}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Note ?
                                                                        <div className='deal-c-note py-2'>
                                                                            {x.Note}
                                                                        </div>
                                                                        : ''}
                                                                    {x.Instruct ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p>{x.Instruct}</p>
                                                                        </div>
                                                                        : ''}

                                                                </CardText>
                                                                <Button>{x.Btn}</Button>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="4">
                                <div id="channelsGuest">
                                    <div className="card-columns h-auto">
                                        {vendors.map(x => {
                                            if (x.id.includes(this.state.activeTab))
                                                return (
                                                    <div className='pt-2 pb-2 pl-1 pr-1' >
                                                        <Card className='deals-card'>
                                                            <CardImg top width="100%" src={x.Img_url} alt="Card image cap" />
                                                            <CardBody className='pl-0 pr-0 pb-0'>
                                                                <CardTitle><h4>{x.Title}</h4></CardTitle>
                                                                <CardText>
                                                                    <p>{x.Describe}</p>
                                                                    {x.Email ? <a>{x.Email}</a> : ''}

                                                                    {x.Point0 ?
                                                                        <ul className='deal-c-backgroud py-2'>
                                                                            <li>{x.Point0}</li>
                                                                            <li>{x.Point1}</li>
                                                                            <li>{x.Point2}</li>
                                                                            <li>{x.Point3}</li>
                                                                            <li>{x.Point4}</li>
                                                                            <li>{x.Point5}</li>
                                                                        </ul>
                                                                        : ''
                                                                    }
                                                                    {x.Discount ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Discount:</b>{x.Discount}</p>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                    {x.Promo ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Promo Code: </b>{x.Promo}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Promo1 ?
                                                                        <div>
                                                                            <b>Promo Code: </b>
                                                                            <div className='deal-c-promobackgroud py-2 mb-3'>
                                                                                <p>{x.Promo1}</p>
                                                                                <p>{x.Promo2}</p>
                                                                                <p>{x.promo3}</p>
                                                                                <p>{x.promo4}</p>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Code ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Code: </b>{x.Code}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Note ?
                                                                        <div className='deal-c-note py-2'>
                                                                            {x.Note}
                                                                        </div>
                                                                        : ''}
                                                                    {x.Instruct ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p>{x.Instruct}</p>
                                                                        </div>
                                                                        : ''}

                                                                </CardText>
                                                                <Button>{x.Btn}</Button>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="5">
                                <div id="channelsGuest">
                                    <div className="card-columns h-auto">
                                        {vendors.map(x => {
                                            if (x.id.includes(this.state.activeTab))
                                                return (
                                                    <div className='pt-2 pb-2 pl-1 pr-1' >
                                                        <Card className='deals-card'>
                                                            <CardImg top width="100%" src={x.Img_url} alt="Card image cap" />
                                                            <CardBody className='pl-0 pr-0 pb-0'>
                                                                <CardTitle><h4>{x.Title}</h4></CardTitle>
                                                                <CardText>
                                                                    <p>{x.Describe}</p>
                                                                    {x.Email ? <a>{x.Email}</a> : ''}

                                                                    {x.Point0 ?
                                                                        <ul className='deal-c-backgroud py-2'>
                                                                            <li>{x.Point0}</li>
                                                                            <li>{x.Point1}</li>
                                                                            <li>{x.Point2}</li>
                                                                            <li>{x.Point3}</li>
                                                                            <li>{x.Point4}</li>
                                                                            <li>{x.Point5}</li>
                                                                        </ul>
                                                                        : ''
                                                                    }
                                                                    {x.Discount ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Discount:</b>{x.Discount}</p>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                    {x.Promo ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Promo Code: </b>{x.Promo}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Promo1 ?
                                                                        <div>
                                                                            <b>Promo Code: </b>
                                                                            <div className='deal-c-promobackgroud py-2 mb-3'>
                                                                                <p>{x.Promo1}</p>
                                                                                <p>{x.Promo2}</p>
                                                                                <p>{x.promo3}</p>
                                                                                <p>{x.promo4}</p>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Code ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Code: </b>{x.Code}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Note ?
                                                                        <div className='deal-c-note py-2'>
                                                                            {x.Note}
                                                                        </div>
                                                                        : ''}
                                                                    {x.Instruct ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p>{x.Instruct}</p>
                                                                        </div>
                                                                        : ''}

                                                                </CardText>
                                                                <Button>{x.Btn}</Button>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="6">
                                <div id="channelsGuest">
                                    <div className="card-columns h-auto">
                                        {vendors.map(x => {
                                            if (x.id.includes(this.state.activeTab))
                                                return (
                                                    <div className='pt-2 pb-2 pl-1 pr-1' >
                                                        <Card className='deals-card'>
                                                            <CardImg top width="100%" src={x.Img_url} alt="Card image cap" />
                                                            <CardBody className='pl-0 pr-0 pb-0'>
                                                                <CardTitle><h4>{x.Title}</h4></CardTitle>
                                                                <CardText>
                                                                    <p>{x.Describe}</p>
                                                                    {x.Email ? <a>{x.Email}</a> : ''}

                                                                    {x.Point0 ?
                                                                        <ul className='deal-c-backgroud py-2'>
                                                                            <li>{x.Point0}</li>
                                                                            <li>{x.Point1}</li>
                                                                            <li>{x.Point2}</li>
                                                                            <li>{x.Point3}</li>
                                                                            <li>{x.Point4}</li>
                                                                            <li>{x.Point5}</li>
                                                                        </ul>
                                                                        : ''
                                                                    }
                                                                    {x.Discount ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Discount:</b>{x.Discount}</p>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                    {x.Promo ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Promo Code: </b>{x.Promo}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Promo1 ?
                                                                        <div>
                                                                            <b>Promo Code: </b>
                                                                            <div className='deal-c-promobackgroud py-2 mb-3'>
                                                                                <p>{x.Promo1}</p>
                                                                                <p>{x.Promo2}</p>
                                                                                <p>{x.promo3}</p>
                                                                                <p>{x.promo4}</p>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Code ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Code: </b>{x.Code}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Note ?
                                                                        <div className='deal-c-note py-2'>
                                                                            {x.Note}
                                                                        </div>
                                                                        : ''}
                                                                    {x.Instruct ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p>{x.Instruct}</p>
                                                                        </div>
                                                                        : ''}

                                                                </CardText>
                                                                <Button>{x.Btn}</Button>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="7">
                                <div id="channelsGuest">
                                    <div className="card-columns h-auto">
                                        {vendors.map(x => {
                                            if (x.id.includes(this.state.activeTab))
                                                return (
                                                    <div className='pt-2 pb-2 pl-1 pr-1' >
                                                        <Card className='deals-card'>
                                                            <CardImg top width="100%" src={x.Img_url} alt="Card image cap" />
                                                            <CardBody className='pl-0 pr-0 pb-0'>
                                                                <CardTitle><h4>{x.Title}</h4></CardTitle>
                                                                <CardText>
                                                                    <p>{x.Describe}</p>
                                                                    {x.Email ? <a>{x.Email}</a> : ''}

                                                                    {x.Point0 ?
                                                                        <ul className='deal-c-backgroud py-2'>
                                                                            <li>{x.Point0}</li>
                                                                            <li>{x.Point1}</li>
                                                                            <li>{x.Point2}</li>
                                                                            <li>{x.Point3}</li>
                                                                            <li>{x.Point4}</li>
                                                                            <li>{x.Point5}</li>
                                                                        </ul>
                                                                        : ''
                                                                    }
                                                                    {x.Discount ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Discount:</b>{x.Discount}</p>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                    {x.Promo ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Promo Code: </b>{x.Promo}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Promo1 ?
                                                                        <div>
                                                                            <b>Promo Code: </b>
                                                                            <div className='deal-c-promobackgroud py-2 mb-3'>
                                                                                <p>{x.Promo1}</p>
                                                                                <p>{x.Promo2}</p>
                                                                                <p>{x.promo3}</p>
                                                                                <p>{x.promo4}</p>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Code ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Code: </b>{x.Code}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Note ?
                                                                        <div className='deal-c-note py-2'>
                                                                            {x.Note}
                                                                        </div>
                                                                        : ''}
                                                                    {x.Instruct ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p>{x.Instruct}</p>
                                                                        </div>
                                                                        : ''}

                                                                </CardText>
                                                                <Button>{x.Btn}</Button>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId="8">
                                <div id="channelsGuest">
                                    <div className="card-columns h-auto">
                                        {vendors.map(x => {
                                            if (x.id.includes(this.state.activeTab))
                                                return (
                                                    <div className='pt-2 pb-2 pl-1 pr-1' >
                                                        <Card className='deals-card'>
                                                            <CardImg top width="100%" src={x.Img_url} alt="Card image cap" />
                                                            <CardBody className='pl-0 pr-0 pb-0'>
                                                                <CardTitle><h4>{x.Title}</h4></CardTitle>
                                                                <CardText>
                                                                    <p>{x.Describe}</p>
                                                                    {x.Email ? <a>{x.Email}</a> : ''}

                                                                    {x.Point0 ?
                                                                        <ul className='deal-c-backgroud py-2'>
                                                                            <li>{x.Point0}</li>
                                                                            <li>{x.Point1}</li>
                                                                            <li>{x.Point2}</li>
                                                                            <li>{x.Point3}</li>
                                                                            <li>{x.Point4}</li>
                                                                            <li>{x.Point5}</li>
                                                                        </ul>
                                                                        : ''
                                                                    }
                                                                    {x.Discount ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Discount:</b>{x.Discount}</p>
                                                                        </div>
                                                                        : ''
                                                                    }
                                                                    {x.Promo ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Promo Code: </b>{x.Promo}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Promo1 ?
                                                                        <div>
                                                                            <b>Promo Code: </b>
                                                                            <div className='deal-c-promobackgroud py-2 mb-3'>
                                                                                <p>{x.Promo1}</p>
                                                                                <p>{x.Promo2}</p>
                                                                                <p>{x.promo3}</p>
                                                                                <p>{x.promo4}</p>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Code ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p><b>Code: </b>{x.Code}</p>
                                                                        </div>
                                                                        : ''}
                                                                    {x.Note ?
                                                                        <div className='deal-c-note py-2'>
                                                                            {x.Note}
                                                                        </div>
                                                                        : ''}
                                                                    {x.Instruct ?
                                                                        <div className='deal-c-backgroud py-2'>
                                                                            <p>{x.Instruct}</p>
                                                                        </div>
                                                                        : ''}

                                                                </CardText>
                                                                <Button>{x.Btn}</Button>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                        })}
                                    </div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </Container>

                {/* Deals page design starts here */}
            </div>
        );
    }
}