import React, { Component } from "react";
import { Sections_Values } from "../../../_LocalData/AgentScriptConstantValues";
import { Markup } from "interweave";

export class AgentScriptSections extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div className="agentSectionWrapper">
        <div className="section_wrapper">
          <h3>
            <b>
              <p className="section_header">Jump to section</p>
            </b>
          </h3>
          <div>
            {Sections_Values.map((items, index) => (
              <div key={index}>
                <p className="section_values">
                  <a href={`#${index}`}>
                    <Markup content={items.name} />
                  </a>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default AgentScriptSections;
