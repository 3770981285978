import { useEffect, useState } from 'react';
import './App.css';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import AdminLayout from './Layouts/AdminLayout';
import { useCookies } from "react-cookie";
import { bindActionCreators } from 'redux';
import * as dealsAction from './_Config/Actions/DealsAction';
import { connect } from 'react-redux';
import AdminLayout_local from './Layouts/AdminLayout_local';
import Tosater from './Components/Common/Toaster/Tosater';


function App(props) {
  let [auth, setAuth] = useState(false);
  let [loader, setLoder] = useState(false);
  const [token, setToken] = useState('');
  const [cookies, setCookie] = useCookies();


  useEffect(() => {
    getAuth(cookies.sub_domain_login_ic_auth)
  }, [])



  const getAuth = async (authToken) => {
    console.log("getAuth")
    setLoder(true);
    setToken(authToken)

    let tokenData = {
      // user_token: 'LLHDVEdxiLonljZRStyI',
      user_token: authToken,
      slug: 'content-calendar'
    }
    let res = await props.dealsAction.getDeals(tokenData)
      .then(res => {
        return res.data
      }
      )
      .catch(error => { console.log(error) });

    if (res) {
      setLoder(false);
      let ic_user_token = res.ic_user_token;
      auth = ic_user_token ? true : false
      setAuth(auth)
    }
    if (!auth) {
      console.log("auth", auth)
      setLoder(true);
      window.location.href = res.parent_site_url
    }
  }



  return (
    <div id='main-content' className="main-content">
      <div style={{ display: loader === true ? "block" : "none" }}>
        <div className='loader'> <CircularProgress /> </div>
      </div>
      <Tosater />
      {console.log("auth11", auth)}
      {auth &&
        <AdminLayout />
        // <AdminLayout_local />
      }
    </div>
  );
}


function mapStateToProps(state, ownProps) {
  return {
    meta: state.blogs
  };
}
function mapDispatchToProps(dispatch) {
  return {
    dealsAction: bindActionCreators(dealsAction, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
