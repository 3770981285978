import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as dealsAction from '../../../_Config/Actions/DealsAction';
import { Markup } from 'interweave';


class Footer extends React.Component {
    render() {
        return (
            <div className='pt-2 pb-2 footer_contents' id='Footer'>
                {/* Footer design starts */}
                <div id="bottom-bar" className="logo-left" role="contentinfo">
                    <div className="wf-wrap">
                        <div className="wf-container-bottom">

                            <div id="branding-bottom"><a className="" href={this.props.siteUrl}><img className=" preload-me" src={this.props.dealsList?.footer_logo} width="106" height="21" sizes="106px" alt="The Inner Circle" /></a></div>
                            <div className="wf-float-left">
                                <Markup content={this.props.dealsList?.footer_copy_right} /><a className='ml-1' href={this.props.dealsList?.footer_privacy_link}>{this.props.dealsList?.footer_privacy_text} </a> - <a href={this.props.dealsList?.footer_terms_link}>{this.props.dealsList?.footer_terms_text} </a>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer design ends */}
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    return {
        // dealsList: state.blogs.footer
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dealsAction: bindActionCreators(dealsAction, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);




