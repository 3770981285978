import React,{ useState} from "react";
import { Markup } from "interweave";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { month, TemplateMonthData } from "../../../_LocalData/TemplateData";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function AccodianTemplate(props) {
  const [expended, setExpended] = useState(false);

  const handleChange = (isExpanded, panel, index1) => {
    setExpended(isExpanded ? panel : false);
  };

  return (
    <div className="Accodian-wrapper-template">
      {month.map((items, index1) => (
        <>
          <div className="Accodian-box" id={items.url}>
            {TemplateMonthData[items.name].map((item, index) => (
              <div className="">
                {item?.name && (
                  <>
                    <h3 className="accodian-h3 alignText">
                      {item?.name}
                      &nbsp;
                      <span className="AccodianSpan">
                        (
                        <a
                          className="AccodianSpan"
                          href="#return_to_top"
                          onClick={() =>
                            window.scrollTo({ top: 100, behavior: "smooth" })
                          }
                        >
                          return to top
                        </a>
                        )
                      </span>
                    </h3>
                    {/*  */}
                    {item?.info ? (
                      <p className="description-text">
                        <Markup content={item?.info} />
                      </p>
                    ) : null}
                  </>
                )}
                {item?.subName ? (
                  <h4 className="accodian-h3"><br/>{item?.subName}</h4>
                ) : null}
                <Accordion
                  elevation={0}
                  className="accordion-head"
                  expanded={expended === item?.title}
                  onChange={(event, isExpanded) =>
                    handleChange(isExpanded, item?.title)
                  }
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={
                      <ArrowDropDownIcon style={{ color: "#2d4250" }} />
                    }
                  >
                    <Typography>{item?.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      {item?.note ? (
                        <div className="accordion-notes">
                          <Markup content={item?.note} />
                        </div>
                      ) : null}
                      <Markup content={item?.content} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
        </>
      ))}
    </div>
  );
}

export default AccodianTemplate;
