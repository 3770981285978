import React, { Component } from 'react'
import Wrapper from './Wrapper'

export class AgentScript extends Component {
  render() {
    return (
      <div>
        <Wrapper />
      </div>
    )
  }
}

export default AgentScript