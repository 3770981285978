import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Modal, CardBody } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import JpgImage from '../../../Assets/Images/placeholder/jpgIcon.png'
import { bindActionCreators } from 'redux';
import * as dealsAction from '../../../_Config/Actions/DealsAction';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ContactValidationSchema from '../Validation/Validation'



const ContactModal = (props) => {
    const { isOpen, handleClose, data } = props;
    const [memberEmail, setMemberEmail] = useState(data?.ic_user_email)
    const [memberName, setMemberName] = useState(data?.ic_user_first_name + data?.ic_user_last_name)
    const [fileObj, setFileObj] = useState('')
    const [fileBase64, setFileBase64] = useState('')
    const [filename, setFileName] = useState('')
    const [errormsg, setErrormsg] = useState('')
    const [aboutmsg, setAboutMsg] = useState('General Suggestion')
    const [loader, setLoader] = useState(false)

    const { register, handleSubmit,reset, formState: { errors } } = useForm({
        resolver: yupResolver(ContactValidationSchema),
    });

    const upload = (e) => {
        e.preventDefault();
        document.getElementById("selectImage").click()
    }

    const getBase64 = file => {
        return new Promise(resolve => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();
            // Convert the file to base64 text
            reader.readAsDataURL(file);
            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                baseURL = reader.result;
                resolve(baseURL);
            };
        });
    };

    const handleFileChange = event => {
        const file = event.target.files && event.target.files[0];
        if (!file) {
            return;
        }
        setFileObj(file)
        setFileName(file.name)
        event.target.value = null;

        getBase64(file)
            .then(result => {
                file["base64"] = result;
                setFileBase64(result)
            })
            .catch(err => {
                console.log(err);
            });
    };

    const removeFile = (e) => {
        setFileName('')
    }

    const selectMessage = (e) => {
        setAboutMsg(e.target.value)
    }


    // send email function
    const submitForm = (data) => {
        if (data) {
            let formData = new FormData();
            formData.append("mail", memberEmail ? memberEmail : data.email);
            formData.append("name", memberName ? memberName : data.fullname);
            formData.append("message", data.message);
            formData.append("aboutMessage", aboutmsg);
            formData.append("file", fileBase64);
            setLoader(true)
            props.dealsAction.getContactForm(formData)
                .then(res => {
                    setLoader(false)
                    if (res) {
                        let response = res;
                        if (response.status == "success") {
                            setErrormsg('success')
                            reset()
                            setTimeout(() => {
                                handleClose()
                                setErrormsg('')
                            }, 1000)
                        }
                        else {
                            setErrormsg('fail')
                            reset()
                            setTimeout(() => {
                                handleClose()
                                setErrormsg('')
                            }, 1000)
                        }


                    }
                })
                .catch(error => { console.log(error) });
        }



    };

    return (
        // Contact form design starts
        <div className='contactForm'>
            <Modal className="modal-dialog-centered Deals contactForm" isOpen={isOpen} size="md">
                <div className="modal-body  d-flex justify-content-center p-0">
                    <CardBody className='p-0'>
                        <div>
                            <div className='d-flex justify-content-between align-self-center'>
                                <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                                    <span aria-hidden={true}><CloseIcon className="m-close" /></span>
                                </button>
                            </div>
                            <h4 className='m-0 align-self-center d-mp-title text-center mb-4'>Contact Us</h4>
                            <div>
                                <form id='contactForm' onSubmit={handleSubmit(submitForm)}>
                                    <FormGroup>
                                        <Label>Your member email*</Label>
                                        <input type="email" className='form-control' defaultValue={memberEmail} {...register('email', { required: true })} />
                                        <p className='error-msg'> {errors?.email?.message} </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Your full name*</Label>
                                        <input type="text" className='form-control' defaultValue={memberName} {...register('fullname', { required: true })} />
                                        <p className='error-msg'> {errors?.fullname?.message} </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>What is your message about?</Label>
                                        <select className='form-control' onChange={selectMessage} value={aboutmsg}>
                                            <option value="General Suggestion" selected="selected">General Suggestion</option>
                                            <option value="Account/Billing">Account/Billing</option>
                                            <option value="Sharing content">Sharing content</option>
                                            <option value="Branded Booklets">Branded Booklets</option>
                                            <option value="Facebook group">Facebook group</option>
                                            <option value="Inner Circle University">Inner Circle University</option>
                                            <option value="Inner Circle Deals">Inner Circle Deals</option>
                                            <option value="Reporting a bug">Reporting a bug</option>
                                            <option value="Something else...">Something else...</option>
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Your message*</Label>
                                        <textarea className='form-control' rows="6" {...register('message', { required: true })} />
                                        <p className='error-msg'> {errors?.message?.message} </p>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Attach file if applicable</Label>
                                        <div className='cf-input p-4'>
                                            {
                                                filename ?
                                                    <div>
                                                        <img src={JpgImage} alt="image" />
                                                        <span className="cp-f-name mx-1"> {filename} </span> is attached -
                                                        <span className="cp-f-remove" onClick={removeFile}>remove</span>
                                                    </div> :
                                                    <div>
                                                        <button className='cf-file-div' onClick={upload}>Attach File</button>
                                                        <input id='selectImage' hidden type="file" onChange={handleFileChange} />
                                                        <span className='ml-4'> {filename ? '' : 'No file Choosen'}</span>
                                                    </div>
                                            }

                                        </div>
                                    </FormGroup>
                                    <div className='text-center'>
                                        <Button color="primary" type="submit">Send Message</Button>
                                        {loader ? <span className='buttonLoder'></span> : null}
                                    </div>
                                </form>
                                <div className='text-center'>
                                    {errormsg != '' &&
                                        errormsg === 'success' ?
                                        <p className='text-success mt-3'>Thank you for your message. It has been sent.</p> : ''}
                                    {errormsg != '' && errormsg === 'fail' ?
                                        <p className='text-danger mt-3'>There was an error trying to send your message. Please try again later.</p>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>

                    </CardBody>
                </div>
            </Modal>
        </div>
        // Contact form design ends
    )
}

function mapStateToProps(state, ownProps) {
    return {
        // meta: state.blogs
    };
}
function mapDispatchToProps(dispatch) {
    return {
        dealsAction: bindActionCreators(dealsAction, dispatch),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);

