export const vendors = [
{   "id":["1","3","5"],
    "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/09/vendor-pop-by-kit.jpg",
    "Title":"The Pop By Kit",
    "Describe":"We find cool pop by 3 with broad appeal that can be given to both men and women and real estate clients of all ages! Our pop by 3 are long-lasting and useful so you’ll be remembered by your clients each time they use your gift!",
    "Discount":"20% off first three months for new subscribers",
    "Promo":"INNERCIRCLE20" ,
    "Btn":"Claim your Discount"
  },
  { "id":["1","5"],
    "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/01/candycarton-deal.jpg",
    "Title":"Candy Cartons",
    "Describe":"Candy Cartons are fantastic for pop by’s, leave behinds, addition to your gift baskets, door knocking, etc. Put candy in them, doggie treats, Open House treats, bring to your listing appointments, closings, and so much more!",
    "Point0":"BOXES ONLY – You provide the treats",
    "Point1":"2.5″ x 2.5″ x 4″ (soda can size)",
    "Point2":"12pt food safe paperboard",
    "Point3":"No harmful overcoats or lamination",
    "Point4":"Professionally scored and die-cut",
    "Point5":"Holds 12 oz. (about 40 Jolly Ranchers)",
    "Discount":"15% off your order",
    "Promo": "INNERCIRCLE15",
    "Btn":"Visit Website"
  },
  { "id":["1","8"],
    "Img_url":"https://icdevcdn.lightersideofrealestate.com/uploads/2018/08/project-mapit-deal.jpg",
    "Title":"Project Map It",
    "Describe":"Make yourself stand out as a realtor and show your potential clients why they should chose you over your competition. Project Map It is an all-in-one software platform that allows you to pin all of your past clients to a map, attach unlimited pictures of your happy customers and the houses you helped them buy/sell, and conduct in-person reviews once the house is sold/bought! We are a cloud based system, so when you snap that pic, or conduct that review, it will post to your map in real time!",
    "Discount":"First month free trial, and 25% discount for life",
    "Promo": "INNERCIRCLE25",
    "Btn":"Claim Your Free Trail"
  },
  { "id":["1","5"],
    "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/05/half-price-banners-fi.jpg",
    "Title":"Custom Real Estate Signs",
    "Describe":"Differentiate your brand, win more listings and sell more homes. HalfPriceBanners.com’s real estate signage is unique, innovative and high quality. Our professional designers proof every order and help you design the perfect real estate sign. Interested in a custom yard sign message? Just contact us at (866) 527-1363 or",
    "Email":"<a hef='www.google.com'>realestate@halfpricebanners.com<a>",
    "Discount":"15% sitewide",
    "Promo":"INNERCIRCLE15",
    "Btn":"Visit Website"
  },
  { "id":["1","5"],
    "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2016/04/padstyler-deal1.jpg",
    "Title":"PadStyler Virtual Staging",
    "Describe":"We place digital home furnishings into property pictures. It helps potential buyers visualize their future home and is proven to significantly boost property appeal. Our staged homes sell faster and for more money!",
    "Discount":"15% off all orders",
    "Promo":"INNERCIRCLE15",
    "Btn":"Visit Website"
  },
  { "id":["1","3"],
    "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/01/qualtry-deal.jpg",
    "Title":"Qualtry",
    "Describe":"We are passionate about creating items your clients will love! Every item from Qualtry is uniquely 7ized to not only be a memorable gift, but a marketing piece for you. Our items are made-to-order letting you choose what names, quotes, or logos will help make our products work for you! We work with thousands of real estate professionals providing them with quality closing 3 that are effective and affordable. Never miss out on a referral again!",
    "Discount":"Up to 75% off your order",
    "Btn":"Visit Website"
  },
  { "id":["1","6"],
    "Img_url":"https://icdevcdn.lightersideofrealestate.com/uploads/2020/05/liondesk-logo.png",
    "Title":"LionDesk",
    "Describe":"Lion Desk Real Estate Agent 6 is a full fledged 8 and Marketing Automation Solution for Real Estate Agents and Real Estate Brokers to manage Email Marketing, 6, Lead Generation, and more.",
    "Discount":" $25 off of the annual fee for the Pro and Pro Plus subscriptions. This brings the Pro subscription to $275/year, and the Pro Plus subscription to $563/year.",
    "Code":"ICM2019",
    "Btn":"Visit Website"
  },
  { "id":["1","6"],
    "Img_url":"https://icdevcdn.lightersideofrealestate.com/uploads/2020/05/realty-juggler-logo.png",
    "Title":"RealtyJuggler",
    "Describe":"RealtyJuggler Real Estate 6 – low cost, full featured tracking of deals with the communication options (print, email, text chat) you need to stay in touch with your leads and clients. 90 day free trial. Additional free 2 months added for Lighter Side of Real Estate subscribers when you pay for your first year.",
    "Discount":" Sign up anytime during your 90 day free trial to get 2 additional free months on top of your first year of service (14 months for the price of 12). Any remaining time in your trial period will still apply.",
    "Instruct":"Click the button below. Once the next page loads, click the yellow button “Click here for 90-day FREE trial”. Fill out your information but be sure to select <b>Friend / Colleague </b> in the “How did you hear about us?” section, and the field for “Referral Email Address” should say <b>innercircle@lightersideofrealestate.com</b>.",
    "Btn":"Visit Website"  
},
{ "id":["1","5"],
  "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2016/04/vendor-all-things.jpg",
  "Title":"All Things Real Estate",
  "Describe":"We’re NOT your typical Real Estate supply store! Get Homebuyer Journals, COOL supplies, stickers, signs, clients 3, open house supplies. We also do logo design, print collateral and publish All Things Real Estate Magazine!",
  "Discount":" 15% off all orders",
  "Promo":"INNERCIRCLE15",
  "Btn":"Redeem Deal"
},
{ "id":["1","3"],
  "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2016/04/vender-new-nest.jpg",
  "Title":"New Nest Pillows",
  "Describe":"Designed by a Realtor for Realtors. No longer search for the perfect settlement gift. Show you put thought into their gift and brand the tag so your info is always handy. By doing this, you create a promotional item and can reap the tax benefits!",
  "Discount":"15% off",
  "Promo":"INNERCIRCLE15",
  "Btn":"Redeem Deal"
},
{ "id":["1","4"],
    "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2016/04/vendor-prospectsplus.jpg",
    "Title":"ProspectsPLUS!",
    "Describe":"ProspectsPLUS! is a direct response marketing company specializing in design, print, and publishing for Real Estate Agents nationwide.",
    "Describe1":"With an advanced web-to-print platform agents have the ability to create a promotional piece, choose a target market, and ship with 100% tracking to verified home-owners.",
    "Promo1":"<b>INNERCIRCLE17 </b> – 17% OFF all Standard and Jumbo Sized Postcards shipped to you. *Excluding postage" ,
    "Promo2":"<b>LSFSC </b> – 17% OFF Standard Postcards Mailed First-Class",
    "promo3":"<b>LSJFC </b> – 17% OFF Jumbo Postcards Mailed First-Class",
    "promo4":"<b>LSJSC </b> – 17% OFF Jumbo Postcards Mailed Standard-Class",
    "Btn":"Visit Website"

  },
  { "id":["1","7"],
    "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2018/01/dene-adams-deal.jpg",
    "Title":"Dene Adams",
    "Describe":"The Dene Adams legacy recognizes, “We the People” as our nation’s most fundamental asset in preserving our great American heritage. We are dedicated to providing private citizens and service members with innovative, dependable and relevant carrying solutions. Part of our profits support a charitable organization that defends those unable to defend themselves.",
    "Discount":" 10% off all orders",
    "Promo": "REAL10",
    "Note":"(Does not apply to new arrivals, clearance items, or gift cards)",
    "Btn":"Visit Website"
  },
  { "id":["1","3"],
    "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2016/04/original-wine-caddy.jpg",
    "Title":"Original Wine Caddy",
    "Describe":"Now you can use your closing gift to keep your name in front of your ideal referral source by giving a gift they’re sure to appreciate and use. Let us do the hard work for you by providing a 7ized Wine Caddy you can combine with a local bottle of wine, and 4 glasses. A fast, easy closing gift that will have your happy clients remembering your name each time they use it.",
    "Discount":"20% off",
    "Promo":"INNERCIRCLE20",
    "Btn":"Redeem Deal"
  },
  { "id":["1","3"],
    "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2016/10/vendor-water-colored-homes.jpg",
    "Title":"Water Colored Homes",
    "Describe":"WaterColored Homes was founded because real estate agents are always looking for a personable and unique closing gift for their clients. We specialize in creating custom water color paintings of exterior home photographs. They’re the perfect closing gift.",
    "Discount":"25% off all orders",
    "Promo":"INNERCIRCLE",
    "Btn":"Redeem Deal"
  },
  { "id":["1","2"],
    "Img_url":"https://ic-dev.lightersideofrealestate.com/wp-content/uploads/2016/04/vender-apparel.jpg",
    "Title":"Lighter Side Apparel Store",
    "Describe":"These “walking billboards” are the perfect way to communicate that you’re a fun and personable agent. Buy one and join the 13,000+ agents who agree. Plus, we’ve dropped our retail prices for Inner Circle members only.",
    "Btn":"Shop Now"
  }
]