import delay from './Delay';
import axios from 'axios';
import Base from './Base.json';
const url = Base.baseUrl2 + Base.version + Base.user;

class ContentCalendarApi {

    static getContentCalendar(data) {
        const config = { headers: { 'content-type': 'multipart/form-data' } }
        return new Promise((resolve, reject) => {
            axios.post(url + `ic_react_ajax_functions.php`, data, config)
                .then(res => {
                    setTimeout(() => {
                        resolve(Object.assign([], res));
                    }, delay)
                }).catch(error => {
                    resolve(error);
                })
        });
    }


}
export default ContentCalendarApi;