import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Markup } from "interweave";
import {
  helpData,
  report,
  contentTitle,
  usingContent,
} from "../../../_LocalData/HelpCenterData";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import FacebookIMG from "../../../Assets/Images/facebook.png";
import PestControlIcon from "@mui/icons-material/PestControl";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function AccodianHelpCenter(props) {
  const [expended, setExpended] = useState(false);
  const [expended_1, setExpended_1] = useState(false);

  const handleChange = (isExpanded, panel, index1) => {
    setExpended(isExpanded ? panel : false);
  };
  const handleChange_1 = (isExpanded, panel, index1) => {
    if (isExpanded) {
      setExpended_1(isExpanded ? panel : false);
    }
    // setExpended_1(isExpanded ? panel : false);
  };
  return (
    <div className="help-accodian-wrapper">
      {props?.tabs !== "Report a Bug" ? (
        helpData[props.tabs].map((item, index) => (
          <Accordion
            elevation={0}
            expanded={expended === item?.name}
            onChange={(event, isExpanded) =>
              handleChange(isExpanded, item?.name)
            }
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<ArrowDropDownIcon style={{ color: "#2d4250" }} />}
            >
              <Typography>
                <span className="help-accodian-header">{item.name}</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {/* General FAQ */}
                {props?.tabs !== "Report a Bug" ? (
                  <p className="help-accodian-paraTag">
                    <Markup content={item.content} />{" "}
                    {item?.video && (
                      <div
                        className="videoTab"
                        dangerouslySetInnerHTML={{ __html: item?.video }}
                      ></div>
                    )}
                    {item?.videoPlayer && (
                      <video width="550" height="300" controls>
                        <source src={item?.videoPlayer} type="video/mp4" />
                      </video>
                    )}
                    {/* {item?.videoPlayer && <iframe src={item?.videoPlayer} allowfullscreen="allowfullscreen"></iframe>} */}
                    {item?.links && <Markup content={item?.links} />}
                  </p>
                ) : null}
                {props.tabs !== "Report a Bug" && item?.buttom ? (
                  item.buttom === "Read the Guide" ? (
                    <div className="read-the-guide">
                      <TurnedInIcon className="icon-noter" />
                      <a href="/support/email-whitelisting" className="ml-2">
                        {item?.buttom}
                      </a>
                    </div>
                  ) : item.buttom === "Go to to the Group" ? (
                    <div className="go-to-group">
                      <img
                        src={FacebookIMG}
                        alt="facebook"
                        className="facebook-icon"
                      />
                      <a href="https://www.facebook.com/groups/lightersideinnercircle/" className="ml-2">
                        {" "}
                        Go to to the Group
                      </a>
                    </div>
                  ) : null
                ) : null}
                {/*  */}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <div className="help-reportBug">
          <h3 className="help-topicReport">{report?.name}</h3>
          <div className="help-contectReport">
            <Markup content={report?.content} />
          </div>
          <div className="report">
            <PestControlIcon className="icon-noter" />
            <a href="/contact" className="">
              Report a Bug
            </a>
          </div>
        </div>
      )}
      <br />
      <br />
      {/* using Content */}
      {props.tabs === "Using Content" ? (
        <div className="accodian-helpCenter">
          {contentTitle.map((items, index) => (
            <div className="accodian-help-content" id={index}>
              {usingContent[items].map((item, index1) => (
                <div>
                  <div className="">
                    {item?.title ? (
                      <h3 className="accodian-h3 accodian-header">
                        {item?.title}
                      </h3>
                    ) : null}
                    <Accordion
                      elevation={0}
                      expanded={expended_1 === item?.name}
                      onChange={(event, isExpanded) =>
                        handleChange_1(isExpanded, item?.name)
                      }
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={
                          expended_1 === item?.name ? (
                            <RemoveIcon
                              style={{ color: "#2d4250" }}
                              className="accodian-icon"
                            />
                          ) : (
                            <AddIcon
                              style={{ color: "#2d4250" }}
                              className="accodian-icon"
                            />
                          )
                        }
                      >
                        <Typography>&nbsp;&nbsp;{item?.name}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <p className="help-accodian-paraTag">
                            <Markup content={item.content} />
                          </p>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
