import React from 'react';
import { Button, Modal, CardBody } from "reactstrap";
import CloseIcon from '@mui/icons-material/Close';
import { Markup } from 'interweave';

const DealsUser = (props) => {
  const { mm_Id, isOpen, handleClose, data } = props;


  // Redirect to url
  const buttonClose = (url) => {
    window.location.href = url
  }


  return (
    <div>
      {/* deals user popup design starts here */}
      <Modal className="modal-dialog-centered Deals" isOpen={isOpen} size="md">
        <div className="modal-body  d-flex justify-content-center p-0">
          <CardBody className='p-0'>
            {/* Paused User Start */}
            {mm_Id == 9 &&
              <div>
                <div className='d-flex justify-content-between align-self-center'>
                  <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                    <span aria-hidden={true}><CloseIcon className="m-close" /></span>
                  </button>
                </div>
                <h4 className='m-0 align-self-center d-mp-title text-center mb-3'>Sorry, this content is locked.</h4>
                <Markup content={data.ic_paused_popup_content} />

                <div className='text-center'>
                  <Button className='Primary' onClick={() => { buttonClose(data.ic_user_reactivate_link) }}>Reactivate</Button>
                </div>
              </div>}
            {/* Paused User End */}

            {/* Plus User Start */}
            {(mm_Id == 11 || mm_Id == 7 || mm_Id == 8) &&
              <div>
                <div className='d-flex justify-content-between align-self-center'>
                 
                  <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
                    <span aria-hidden={true}><CloseIcon className="m-close" /></span>
                  </button>
                </div>
                <h4 className='m-0 align-self-center d-mp-title text-center mb-3'>Sorry, this content is locked.</h4>
                <Markup content={data.ic_plus_basic_popup_content} />
                <div className='text-center'>
                  <Button className='Primary' onClick={() => { buttonClose(data.ic_user_popup_link) }}>View other plans</Button>
                </div>
              </div>}
            {/* Plus User End */}
          </CardBody>
        </div>
      </Modal>
      {/* deals user popup dessign ends here */}
    </div>
  );
};

export default DealsUser;