import React, { useEffect, useState } from 'react';
import LeftSidebar from '../../Common/LeftSideBar/LeftSidebar'
import Breadcrumb from '../../Common/Breadcrump/Breadcrumb';
import { postFormdataMethod } from '../../../_Config/Api'

const Wrapper = ( props ) => {
  const [emaildata, setEmaildata] =useState({})
  const Emaildata = props.dealsList.ic_id
  const  menus= props?.dealsList?.left_menus

useEffect(()=>{
  getEmailData();
},[])
const getEmailData= async()=>{
  const data = {
    url: "ic_react_api_page_info.php",
    body: {
      user_id: Emaildata,
      slug: 'email-subscription'
    },
  };
  let newData = await postFormdataMethod(data)
  let res = newData.data
  setEmaildata(res)
}

  const breadcrumbData = {
    page_title: 'Manage Subscription',
    page_sub_title: ''
  }
  return (
    <div>
      <Breadcrumb dealsList={breadcrumbData} />
      {emaildata &&
      <LeftSidebar  menus={menus} data={props.dealsList.ic_id}/>
    }
    </div>
  )
}

export default Wrapper
